const axios = require('./axios');
const {
    isBrowser,
    getGoogleAuthInstance,
    getFacebookAccessToken
} = require('./helper');

const LOCALSTORAGE_IDENTIFIER = 'gatekeeper_token';

let ACCOUNT_ID = null;
let OPTIONS = {};

const Gatekeeper = {};
Gatekeeper.configure = (accountID, options) => {
    ACCOUNT_ID = accountID;
    OPTIONS = options;
    axios.defaults.headers.common.AccountID = ACCOUNT_ID;
};

Gatekeeper.loginByAuth = async (email, password) => {
    const {data} = await axios.post('/v1/auth/login', {
        email,
        password
    });
    const {token} = data;
    if (isBrowser) {
        localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    }
    return token;
};

Gatekeeper.registerByAuth = async (email, password) => {
    const {data} = await axios.post('/v1/auth/register', {
        email,
        password
    });
    const {token} = data;
    if (isBrowser) {
        localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    }
    return token;
};

Gatekeeper.loginByGoogle = async () => {
    const instance = await getGoogleAuthInstance(OPTIONS.googleClientID);
    const res = await instance.signIn();

    const {data} = await axios.post('/v1/google/login', {
        idToken: res.getAuthResponse().id_token
    });
    const {token} = data;

    localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    return token;
};

Gatekeeper.registerByGoogle = async () => {
    const instance = await getGoogleAuthInstance(OPTIONS.googleClientID);
    const res = await instance.signIn();

    const {data} = await axios.post('/v1/google/register', {
        idToken: res.getAuthResponse().id_token
    });

    const {token} = data;

    localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    return token;
};

Gatekeeper.loginByFacebook = async () => {
    const accessToken = await getFacebookAccessToken(OPTIONS.facebookAppID);

    const {data} = await axios.post('/v1/facebook/login', {
        accessToken
    });

    const {token} = data;

    localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    return token;
};

Gatekeeper.registerByFacebook = async () => {
    const accessToken = await getFacebookAccessToken(OPTIONS.facebookAppID);

    const {data} = await axios.post('/v1/facebook/register', {
        accessToken
    });

    const {token} = data;

    localStorage.setItem(LOCALSTORAGE_IDENTIFIER, token);
    return token;
};

Gatekeeper.getProfile = async () => {
    const token = localStorage.getItem(LOCALSTORAGE_IDENTIFIER);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    const {data} = await axios.get('/v1/users/profile');
    return data;
};

module.exports = Gatekeeper;
