import React, {useState, useEffect} from 'react';
import Pagination from 'react-js-pagination';
import {useSelector} from 'react-redux';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import {toast} from 'react-toastify';
import {useMutation, useQuery} from 'react-query';
import {Image} from 'react-bootstrap';
import {AsyncPaginate} from 'react-select-async-paginate';
import {DEVICE_API, BaseGatewayImage, DEPARTMENT_API} from '@app/utils/apiUrl';
import {DeleteSingle, Get} from '@app/utils/httpProvider';
import {
    createUrlWithParam,
    getInfomationAccountFromToken,
    isValidUrl
} from '../../utils/helper';
import {URI_INTERNAL} from '../../utils/pathLocations';
import {
    objectShowNumber,
    messageToast,
    objectDeviceStatus,
    deviceStatus,
    styleSelectDefault,
    role
} from '../../utils/define';
import {ConfirmModal} from '../../modules/modal/ConfirmModal';
import {BrowserView, MobileView} from 'react-device-detect';

const Device = () => {
    const history = useHistory();
    const location = useLocation();
    const valueSearch = useSelector((state) => state.search.valueSearch);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');

    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [idWantDelete, setIdWantDelete] = useState('');
    const [confirmDelMessage, setConfirmDelMessage] = useState('');
    const [departmentId, setDepartmentId] = useState('');

    let accountRoleAdmin = false;
    const inforAccount = getInfomationAccountFromToken();

    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        accountRoleAdmin =
            userRole.includes(role.Admin) || userRole.includes(role.Approver);
    }

    const deleteFunc = useMutation((value) =>
        DeleteSingle(DEVICE_API.DELETE_ITEM, value, null)
    );

    const paramUrl = new URLSearchParams(location.search.substring(1));
    if (
        paramUrl.get('page') != null &&
        parseInt(paramUrl.get('page'), 10) !== page
    ) {
        const pageResult = parseInt(paramUrl.get('page'), 10);
        setPage(pageResult);
    }
    if (
        paramUrl.get('size') != null &&
        parseInt(paramUrl.get('size'), 10) !== pageSize
    ) {
        const sizeResult = parseInt(paramUrl.get('size'), 10);
        setPageSize(sizeResult);
    }
    if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
        setSearch(paramUrl.get('search'));
    }

    const [isSortedNameAlphabet, setIsSortedNameAlphabet] = useState(false);

    const {isLoading, isError, error, data, refetch} = useQuery(
        ['deviceData', valueSearch, page, pageSize, departmentId],
        () => {
            return Get(DEVICE_API.GET_PAGING, null, {
                Keyword: valueSearch ? valueSearch : '',
                PageIndex: page,
                PageSize: pageSize,
                IsSortedNameAlphabet: isSortedNameAlphabet,
                DepartmentId:
                    departmentId &&
                    departmentId.target &&
                    departmentId.target.value
                        ? departmentId.target.value.value
                        : ''
            });
        }
    );

    useEffect(() => {
        refetch();
    }, [pageSize, page, departmentId, isSortedNameAlphabet]);

    const replaceHistory = async (Page, Size, Search) => {
        history.replace(
            createUrlWithParam(URI_INTERNAL.DEVICE_PATH, {
                page: Page,
                size: Size,
                search: Search
            })
        );
    };

    React.useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            replaceHistory(1, pageSize, valueSearch);
        }
    }, [valueSearch]);

    const changePage = (number) => {
        setPage(number);
        replaceHistory(number, pageSize, search);
    };

    const onChangeShowNumber = (e) => {
        setPageSize(e.target.value);
        replaceHistory(page, e.target.value, search);
    };

    const deleteAction = (id, name) => {
        setIdWantDelete(id);
        setConfirmDelMessage(name);
        setOpenConfirmDeleteModal(true);
    };

    const acceptDelete = async () => {
        if (idWantDelete !== '') {
            deleteFunc.mutate(idWantDelete, {
                onSuccess: (res) => {
                    if (res.status === 201 || res.status === 200) {
                        refetch();
                        toast.success(messageToast.messageToastDeleteSuccess);
                        if (
                            data != null &&
                            data.data.length === 1 &&
                            page !== 1
                        ) {
                            history.push(
                                createUrlWithParam(URI_INTERNAL.DEVICE_PATH, {
                                    page: page - 1,
                                    size: pageSize,
                                    search: search
                                })
                            );
                        }
                    } else {
                        toast.error(messageToast.messageToastDeleteError);
                    }
                },
                onError: (res) => {
                    toast.error(messageToast.messageToastTryCatch);
                }
            });

            replaceHistory(page, pageSize, search);
            setOpenConfirmDeleteModal(false);
        }
    };

    const cancelDelete = () => {
        setIdWantDelete('');
        setOpenConfirmDeleteModal(false);
    };

    const listDepartment = useQuery(['getAllDepartment'], () =>
        Get(DEPARTMENT_API.GET_ALL, null, null)
    );

    // Khoa
    const lstDepartment =
        listDepartment && listDepartment.data
            ? listDepartment.data.map((x) => ({
                  label: x.Ten,
                  value: x.ID
              }))
            : [];

    const loadOptions = async (inputValue, loadedOptions, {page}) => {
        const filteredOptions = lstDepartment
            .filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            )
            .slice(0, 6);

        // Return all filtered options, no matter how many there are
        return {
            options: filteredOptions,
            hasMore: false,
            additional: {
                page: page + 1
            }
        };
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"> Danh sách thiết bị</h3>
                        </div>
                        <div className="card-body">
                            <BrowserView>
                                <div className="d-flex justify-content-between">
                                    <form className="form-inline">
                                        <label
                                            htmlFor="exampleEmail2"
                                            className="mr-2"
                                        >
                                            Hiển thị :
                                        </label>
                                        <select
                                            className="form-control"
                                            value={pageSize}
                                            onChange={(e) =>
                                                onChangeShowNumber(e)
                                            }
                                        >
                                            {objectShowNumber.map(
                                                (item, key) => (
                                                    <option
                                                        key={Number(key)}
                                                        value={item.value}
                                                    >
                                                        {item.text}
                                                    </option>
                                                )
                                            )}
                                        </select>

                                        <span style={{marginLeft: '20px'}}>
                                            <AsyncPaginate
                                                name="departmentId"
                                                id="departmentId"
                                                placeholder="Chọn Khoa"
                                                value={departmentId.value}
                                                loadOptions={loadOptions}
                                                styles={styleSelectDefault}
                                                isClearable
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: 'departmentId',
                                                            value: selectedOption
                                                        }
                                                    };
                                                    setDepartmentId(event);
                                                }}
                                                additional={{
                                                    page: 1
                                                }}
                                            />
                                        </span>
                                    </form>
                                    {accountRoleAdmin ? (
                                        <NavLink
                                            className="nav-link-reject nav-link"
                                            to={URI_INTERNAL.DEVICE_ADD_PATH}
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                            >
                                                Thêm mới
                                            </button>
                                        </NavLink>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </BrowserView>
                            <MobileView>
                                <div className="d-flex justify-content-between">
                                    <form className="form-inline">
                                        <label
                                            htmlFor="exampleEmail2"
                                            className="mr-2"
                                        >
                                            Hiển thị :
                                        </label>
                                        <select
                                            className="form-control"
                                            value={pageSize}
                                            onChange={(e) =>
                                                onChangeShowNumber(e)
                                            }
                                        >
                                            {objectShowNumber.map(
                                                (item, key) => (
                                                    <option
                                                        key={Number(key)}
                                                        value={item.value}
                                                    >
                                                        {item.text}
                                                    </option>
                                                )
                                            )}
                                        </select>

                                        <div
                                            style={{
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <AsyncPaginate
                                                name="departmentId"
                                                id="departmentId"
                                                placeholder="Chọn Khoa"
                                                value={departmentId.value}
                                                loadOptions={loadOptions}
                                                styles={styleSelectDefault}
                                                isClearable
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: 'departmentId',
                                                            value: selectedOption
                                                        }
                                                    };
                                                    setDepartmentId(event);
                                                }}
                                                additional={{
                                                    page: 1
                                                }}
                                            />
                                        </div>

                                        {accountRoleAdmin ? (
                                            <div className="ml-auto">
                                                <NavLink
                                                    className="nav-link-reject nav-link"
                                                    to={
                                                        URI_INTERNAL.DEVICE_ADD_PATH
                                                    }
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn btn-info"
                                                    >
                                                        Thêm mới
                                                    </button>
                                                </NavLink>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </form>
                                </div>
                            </MobileView>
                            {isLoading ? (
                                <div>Đang tải...</div>
                            ) : isError ? (
                                <div>
                                    Có lỗi xảy ra: ${error.message}
                                </div>
                            ) : (
                                <div
                                    id="example2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4"
                                    style={{
                                        marginTop: accountRoleAdmin
                                            ? '0px'
                                            : '20px'
                                    }}
                                >
                                    <div className="row">
                                        <div
                                            className="col-sm-12"
                                            style={{overflow: 'auto'}}
                                        >
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th className="sorting">
                                                            Ảnh đại diện
                                                        </th>
                                                        <th className="sorting">
                                                            <i
                                                                className="nav-icon fas fa-arrow-down"
                                                                onClick={() =>
                                                                    setIsSortedNameAlphabet(
                                                                        !isSortedNameAlphabet
                                                                    )
                                                                }
                                                            />{' '}
                                                            Tên thiết bị
                                                        </th>
                                                        <th className="sorting">
                                                            Mã thiết bị
                                                        </th>
                                                        <th className="sorting">
                                                            Khoa quản lý
                                                        </th>
                                                        <th className="sorting">
                                                            Người quản lý
                                                        </th>
                                                        <th className="sorting">
                                                            Điện thoại
                                                        </th>
                                                        <th className="sorting">
                                                            Chức năng
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {data != null &&
                                                data.data != null &&
                                                data.data != null &&
                                                data.data !== undefined &&
                                                data.data.length > 0 ? (
                                                    <tbody>
                                                        {data.data.map(
                                                            (item, index) => (
                                                                <tr
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="odd"
                                                                >
                                                                    <td className="dtr-control sorting_1">
                                                                        {index +
                                                                            1 +
                                                                            (page -
                                                                                1) *
                                                                                pageSize}
                                                                    </td>
                                                                    <td>
                                                                        {item.imageThumbnail ? (
                                                                            <Image
                                                                                src={
                                                                                    isValidUrl(
                                                                                        item.imageThumbnail
                                                                                    )
                                                                                        ? item.imageThumbnail
                                                                                        : ` ${BaseGatewayImage}${item.imageThumbnail}`
                                                                                }
                                                                                thumbnail
                                                                                width="50px"
                                                                                height="50px"
                                                                            />
                                                                        ) : item.imagePath ? (
                                                                            <Image
                                                                                src={
                                                                                    isValidUrl(
                                                                                        item.imagePath
                                                                                    )
                                                                                        ? item.imagePath
                                                                                        : ` ${BaseGatewayImage}${item.imagePath}`
                                                                                }
                                                                                thumbnail
                                                                                width="50px"
                                                                                height="50px"
                                                                            />
                                                                        ) : null}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.deviceCode
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            lstDepartment && lstDepartment.find(x => x.value == item.departmentId) ? lstDepartment.find(x => x.value == item.departmentId).label : ""
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.account.fullName
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.deviceManagementPhone
                                                                        }
                                                                    </td>
                                                                    {/* <td>
                                                                        <div
                                                                            className={
                                                                                item.status ===
                                                                                deviceStatus.available
                                                                                    ? 'icheck-primary'
                                                                                    : 'icheck-danger'
                                                                            }
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                id={
                                                                                    item.id +
                                                                                    'deviceStatus'
                                                                                }
                                                                                name={
                                                                                    item.id +
                                                                                    'deviceStatus'
                                                                                }
                                                                                defaultChecked
                                                                            />
                                                                            <label
                                                                                htmlFor={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {item.status ==
                                                                                deviceStatus.available
                                                                                    ? 'Còn trong kho'
                                                                                    : 'Không còn'}
                                                                            </label>
                                                                        </div>
                                                                    </td> */}
                                                                    {/* <td>
                                                                        <div
                                                                            className={
                                                                                item.isActive
                                                                                    ? 'icheck-primary'
                                                                                    : 'icheck-danger'
                                                                            }
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                id={
                                                                                    item.id
                                                                                }
                                                                                name={
                                                                                    item.id
                                                                                }
                                                                                defaultChecked
                                                                            />
                                                                            <label
                                                                                htmlFor={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {item.isActive
                                                                                    ? 'Đã kích hoạt'
                                                                                    : 'Chưa kích hoạt'}
                                                                            </label>
                                                                        </div>
                                                                    </td> */}
                                                                    <td>
                                                                        <ul className="nav">
                                                                            <li className="nav-item">
                                                                                <NavLink
                                                                                    to={createUrlWithParam(
                                                                                        URI_INTERNAL.DEVICE_EDIT_PATH,
                                                                                        {
                                                                                            id: item.id
                                                                                        }
                                                                                    )}
                                                                                    exact
                                                                                    className="nav-link"
                                                                                >
                                                                                    <i
                                                                                        title="Sửa"
                                                                                        className="nav-icon font-icon fas fa-pen"
                                                                                    />
                                                                                </NavLink>
                                                                            </li>
                                                                            {accountRoleAdmin ? (
                                                                                <li className="nav-item">
                                                                                    <NavLink
                                                                                        onClick={() =>
                                                                                            deleteAction(
                                                                                                item.id,
                                                                                                item.name
                                                                                            )
                                                                                        }
                                                                                        to
                                                                                        exact
                                                                                        className="nav-link"
                                                                                    >
                                                                                        <i
                                                                                            title="Xóa"
                                                                                            className="nav-icon font-icon far fa-trash-alt"
                                                                                        />
                                                                                    </NavLink>
                                                                                </li>
                                                                            ) : (
                                                                                <>

                                                                                </>
                                                                            )}
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr className="txt-center">
                                                            <td colSpan="12">
                                                                Không có dữ liệu
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                                <tfoot>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th className="sorting">
                                                            Ảnh đại diện
                                                        </th>
                                                        <th className="sorting">
                                                            Tên thiết bị
                                                        </th>
                                                        <th className="sorting">
                                                            Mã thiết bị
                                                        </th>
                                                        <th className="sorting">
                                                            Khoa quản lý
                                                        </th>
                                                        <th className="sorting">
                                                            Người quản lý
                                                        </th>
                                                        <th className="sorting">
                                                            Điện thoại
                                                        </th>
                                                        <th className="sorting">
                                                            Chức năng
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div
                                                className="dataTables_paginate paging_simple_numbers"
                                                id="example2_paginate"
                                            >
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination pagination-sm no-margin pull-right"
                                                    activePage={page}
                                                    itemsCountPerPage={Number(
                                                        pageSize
                                                    )}
                                                    totalItemsCount={
                                                        data != null
                                                            ? data.totalRecords
                                                            : 0
                                                    }
                                                    pageRangeDisplayed={5}
                                                    onChange={(number) =>
                                                        changePage(number)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <ConfirmModal
                                isOpen={openConfirmDeleteModal}
                                confirmType="delete"
                                handleAccept={() => acceptDelete()}
                                handleCancel={() => cancelDelete()}
                                content={confirmDelMessage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Device;
