import React from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useLocation, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import {Get, Put} from '@app/utils/httpProvider';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import Editor from '@app/components/editor/Editor';
import Select from 'react-select';
import {NIL as NIL_UUID} from 'uuid';
import {AsyncPaginate} from 'react-select-async-paginate';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
    messageError,
    messageToast,
    acceptImage,
    objectDataTypeCategory,
    styleSelectDefault,
    styleSelectError,
    status200,
    dataTypeCategory,
    objectDataTypeVideo,
    objectTypeQA,
    objectAnswerChoice,
    answerChoice,
    objectSentenceType,
    sentenceType,
    objectDeviceStatus,
    role
} from '../../utils/define';
import {
    BaseGatewayImage,
    DEVICE_API,
    DEPARTMENT_API,
    ACCOUNT_API
} from '../../utils/apiUrl';
import {getInfomationAccountFromToken, isValidUrl} from '../../utils/helper';
import DeviceMaintenance from '@app/pages/deviceMaintenance/DeviceMaintenance';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    deviceCode: yup.string().required(messageError.emptyMessage),
    deviceManagementEmail: yup
        .string()
        .email(messageError.emailMessage)
        .required(messageError.emptyMessage),
    deviceManagementPhone: yup
        .string()
        .matches(phoneRegExp, 'Điện thoại có định dạng không đúng')
    // dataType: yup.object().required(messageError.emptyMessage)
});

const DeviceEdit = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const deviceId = paramUrl.get('id');

    const inforAccount = getInfomationAccountFromToken();
    let accountRoleManageDevice = false;

    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        accountRoleManageDevice =
            userRole.includes(role.Admin) || userRole.includes(role.Approver);
        // accountRoleBooker =
        //     userRole.includes(role.Admin) || userRole.includes(role.Booker);
    }

    // const inforAccount = getInfomationAccountFromToken();

    const listDepartment = useQuery(['getAllDepartment', deviceId], () =>
        Get(DEPARTMENT_API.GET_ALL, null, null)
    );

    const listAccount = useQuery(['getAllAccount'], () =>
        Get(ACCOUNT_API.GET_PAGING_ACCOUNT, null, {
            PageIndex: 1,
            PageSize: 5000
        })
    );

    const lstAccount =
        listAccount && listAccount.data && listAccount.data.data
            ? listAccount.data.data.map((x) => ({
                  label: x.fullName,
                  value: x.id
              }))
            : [];

    const lstDepartment =
        listDepartment && listDepartment.data
            ? listDepartment.data.map((x) => ({
                  label: x.Ten,
                  value: x.ID
              }))
            : [];

    // const listDevices = useQuery(['getAllParentQA'], () =>
    //     Get(DEVICE_API.GET_PAGING, null, {
    //         PageIndex: 1,
    //         PageSize: 10000
    //     })
    // );

    // const lstDevice =
    //     listDevices && listDevices.data && listDevices.data.data
    //         ? listDevices.data.data.$values
    //               .filter((s) => s.id != deviceId)
    //               .map((x) => ({label: x.title, value: x.id}))
    //         : [];

    const deviceItem = useQuery([deviceId], () =>
        Get(DEVICE_API.GET_ITEM, deviceId)
    );

    // const listCate = useQuery(['getAllCate'], () =>
    //     Get(CATEGORY_API.GET_ALL_CATEGORY)
    // );

    const editFunc = useMutation((value) =>
        Put(DEVICE_API.UPDATE_ITEM, deviceId, value)
    );

    const handleEdit = async (values) => {
        const model = {...values};
        // model.parentId = cateItem.data.parentId;
        // model.parentId = values.parentId === 'null' ? '' : values.parentId;
        // model.dataType = values.dataType.value;
        // model.typeVideo = values.typeVideo.value;

        model.id = deviceId;
        model.status = values.status.value;
        // model.departmentId =
        //     values.departmentId === '' ||
        //     values.departmentId === 'null' ||
        //     values.departmentId === undefined
        //         ? ''
        //         : values.departmentId.value;

        model.accountId =
            values.accountId === '' ||
            values.accountId === 'null' ||
            values.accountId === undefined
                ? ''
                : values.accountId.value;
        // model.currentUserId = inforAccount.id;
        // const modelUpdate = {
        //     data: model,
        //     id: areaId
        // };

        editFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    toast.success(messageToast.messageToastUpdateSuccess);
                    queryClient.setQueryData(deviceId, model);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error(
                    res.message || 'Đã có lỗi xảy ra, vui lòng thử lại !'
                );
            }
        });
    };

    // const loadOptions = async (inputValue, loadedOptions, {page}) => {
    //     const filteredOptions = lstDepartment
    //         .filter((option) =>
    //             option.label.toLowerCase().includes(inputValue.toLowerCase())
    //         )
    //         .slice(0, 6);

    //     // Return all filtered options, no matter how many there are
    //     return {
    //         options: filteredOptions,
    //         hasMore: false,
    //         additional: {
    //             page: page + 1
    //         }
    //     };
    // };

    // const isLoading = cateItem.isLoading || listCate.isLoading;
    // const isError = cateItem.isError || listCate.isError;
    // const error = cateItem.isError ? cateItem.error : listCate.error;

    const isLoading =
        deviceItem.isLoading || listDepartment.isLoading ? true : false;
    const isError = deviceItem.isError || listDepartment.isError ? true : false;
    const error =
        deviceItem.isError || listDepartment.isError ? deviceItem.error : null;

    // let menuData = [
    //     {
    //         name: 'Danh mục gốc',
    //         id: 'null',
    //         parentId: NIL_UUID
    //     }
    // ];
    // if (!listCate.isLoading && !listCate.isError) {
    //     menuData = menuData
    //         .concat(listCate.data.data.$values)
    //         .filter((x) => x.id !== cateId);
    // }

    const loadOptionsAccount = async (inputValue, loadedOptions, {page}) => {
        const filteredOptions = lstAccount
            .filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            )
            .slice(0, 6);

        // Return all filtered options, no matter how many there are
        return {
            options: filteredOptions,
            hasMore: false,
            additional: {
                page: page + 1
            }
        };
    };

    return (
        <Card>
            <Card.Header as="h5">Xem hoặc sửa thiết bị</Card.Header>
            <Tabs>
                <TabList>
                    <Tab>Thông tin trang thiết bị</Tab>
                    <Tab>Sổ bảo dưỡng và sự cố</Tab>
                </TabList>
                <TabPanel>
            <Card.Body>
                {isLoading ? (
                    <div>Đang tải...</div>
                ) : isError ? (
                    <div>Có lỗi xảy ra: ${error.message}</div>
                ) : deviceItem.data == null ? (
                    <div>Danh mục không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            name:
                                deviceItem.data && deviceItem.data.name
                                    ? deviceItem.data.name
                                    : '',
                            deviceCode:
                                deviceItem.data && deviceItem.data.deviceCode
                                    ? deviceItem.data.deviceCode
                                    : '',
                            deviceManagementPhone:
                                deviceItem.data &&
                                deviceItem.data.deviceManagementPhone
                                    ? deviceItem.data.deviceManagementPhone
                                    : '',
                            deviceManagementEmail:
                                deviceItem.data &&
                                deviceItem.data.deviceManagementEmail
                                    ? deviceItem.data.deviceManagementEmail
                                    : '',
                            modelDevice:
                                deviceItem.data && deviceItem.data.modelDevice
                                    ? deviceItem.data.modelDevice
                                    : '',
                            seriDevice:
                                deviceItem.data && deviceItem.data.seriDevice
                                    ? deviceItem.data.seriDevice
                                    : '',
                            firmOfDevice:
                                deviceItem.data && deviceItem.data.firmOfDevice
                                    ? deviceItem.data.firmOfDevice
                                    : '',
                            deviceOrigin:
                                deviceItem.data && deviceItem.data.deviceOrigin
                                    ? deviceItem.data.deviceOrigin
                                    : '',
                            imagePath:
                                deviceItem.data && deviceItem.data.imagePath
                                    ? deviceItem.data.imagePath
                                    : '',
                            imageThumbnail:
                                deviceItem.data &&
                                deviceItem.data.imageThumbnail
                                    ? deviceItem.data.imageThumbnail
                                    : '',
                            description:
                                deviceItem.data && deviceItem.data.description
                                    ? deviceItem.data.description
                                    : '',
                            parameter:
                                deviceItem.data && deviceItem.data.parameter
                                    ? deviceItem.data.parameter
                                    : '',
                            orderNumber:
                                deviceItem.data && deviceItem.data.orderNumber
                                    ? deviceItem.data.orderNumber
                                    : 0,
                            // area:
                            //     areaItem.data && areaItem.data.areaId === NIL_UUID
                            //         ? 'null'
                            //         : areaItem.data.areaId,
                            // departmentId:
                            //     deviceItem.data &&
                            //     deviceItem.data.departmentId != NIL_UUID
                            //         ? lstDepartment.find(
                            //               (x) =>
                            //                   x.value ===
                            //                   deviceItem.data
                            //                       .departmentId
                            //           )
                            //         : '',
                            departmentName:
                                deviceItem.data &&
                                deviceItem.data.departmentId != NIL_UUID &&
                                lstDepartment &&
                                lstDepartment.find(
                                    (x) =>
                                        x.value == deviceItem.data.departmentId
                                )
                                    ? lstDepartment.find(
                                          (x) =>
                                              x.value ==
                                              deviceItem.data.departmentId
                                      ).label
                                    : '',
                            recommendedNumberOfUse:
                                deviceItem.data &&
                                deviceItem.data.recommendedNumberOfUse
                                    ? deviceItem.data.recommendedNumberOfUse
                                    : 0,
                            currentNumberOfUse:
                                deviceItem.data &&
                                deviceItem.data.currentNumberOfUse
                                    ? deviceItem.data.currentNumberOfUse
                                    : 0,
                            accountId:
                                deviceItem.data &&
                                deviceItem.data.accountId != NIL_UUID
                                    ? lstAccount.find(
                                          (x) =>
                                              x.value ===
                                              deviceItem.data.accountId
                                      )
                                    : '',
                            accountName:
                                deviceItem.data &&
                                deviceItem.data.accountId != NIL_UUID &&
                                lstAccount.find(
                                    (x) => x.value === deviceItem.data.accountId
                                )
                                    ? lstAccount.find(
                                          (x) =>
                                              x.value ===
                                              deviceItem.data.accountId
                                      ).label
                                    : '',
                            // isActive:
                            //     deviceItem.data && deviceItem.data.isActive
                            //         ? deviceItem.data.isActive
                            //         : false,
                            status:
                                deviceItem.data &&
                                deviceItem.data.status != NIL_UUID
                                    ? objectDeviceStatus.find(
                                          (x) =>
                                              x.value === deviceItem.data.status
                                      )
                                    : ''
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tên thiết bị *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tên thiết bị"
                                            type="text"
                                            name="name"
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mã thiết bị *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Mã thiết bị"
                                            type="text"
                                            name="deviceCode"
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            value={values.deviceCode}
                                            onChange={handleChange}
                                            isInvalid={!!errors.deviceCode}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.deviceCode}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Model thiết bị
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Model thiết bị"
                                            type="text"
                                            name="modelDevice"
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            value={values.modelDevice}
                                            onChange={handleChange}
                                            isInvalid={!!errors.modelDevice}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.modelDevice}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Seri thiết bị
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Seri thiết bị"
                                            type="text"
                                            name="seriDevice"
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            value={values.seriDevice}
                                            onChange={handleChange}
                                            isInvalid={!!errors.seriDevice}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.seriDevice}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Hãng
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Hãng"
                                            type="text"
                                            name="firmOfDevice"
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            value={values.firmOfDevice}
                                            onChange={handleChange}
                                            isInvalid={!!errors.firmOfDevice}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.firmOfDevice}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Xuất xứ
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Xuất xứ"
                                            type="text"
                                            name="deviceOrigin"
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            value={values.deviceOrigin}
                                            onChange={handleChange}
                                            isInvalid={!!errors.deviceOrigin}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.deviceOrigin}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                {accountRoleManageDevice ? (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label className="col-sm-2 col-form-label">
                                            Người quản lý
                                        </Form.Label>
                                        <Col sm="10">
                                            <AsyncPaginate
                                                name="accountId"
                                                id="accountId"
                                                placeholder="Chọn người quản lý"
                                                value={values.accountId}
                                                loadOptions={loadOptionsAccount}
                                                isClearable
                                                styles={
                                                    errors.accountId !==
                                                    undefined
                                                        ? styleSelectError
                                                        : styleSelectDefault
                                                }
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: 'accountId',
                                                            value: selectedOption
                                                        }
                                                    };
                                                    handleChange(event);
                                                }}
                                                additional={{
                                                    page: 1
                                                }}
                                            />
                                            <div
                                                style={{
                                                    display: 'block',
                                                    color: '#dc3545'
                                                }}
                                                className="invalid-feedback"
                                            >
                                                {errors.accountId}
                                            </div>
                                        </Col>
                                    </Form.Group>
                                ) : (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label className="col-sm-2 col-form-label">
                                            Người quản lý
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                placeholder="Người quản lý"
                                                type="text"
                                                name="accountName"
                                                disabled={true}
                                                value={values.accountName}
                                                onChange={handleChange}
                                                isInvalid={!!errors.accountName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.accountName}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                )}

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Email người quản lý *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Email người quản lý"
                                            type="text"
                                            name="deviceManagementEmail"
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            value={values.deviceManagementEmail}
                                            onChange={handleChange}
                                            isInvalid={
                                                !!errors.deviceManagementEmail
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.deviceManagementEmail}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Số điện thoại người quản lý
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Số điện thoại người quản lý"
                                            type="text"
                                            name="deviceManagementPhone"
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            value={values.deviceManagementPhone}
                                            onChange={handleChange}
                                            isInvalid={
                                                !!errors.deviceManagementPhone
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.deviceManagementPhone}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="description"
                                            value={values.description}
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'description',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thông số
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="parameter"
                                            value={values.parameter}
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'parameter',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh đại diện
                                    </Form.Label>
                                    <Col sm="10">
                                        {accountRoleManageDevice ? (
                                            <InputFile
                                                enableReinitialize={true}
                                                // values={values.imageThumbnail ? [BaseGatewayImage + values.imageThumbnail] : []}
                                                values={
                                                    values.imageThumbnail
                                                        ? [
                                                              isValidUrl(
                                                                  values.imageThumbnail
                                                              )
                                                                  ? values.imageThumbnail
                                                                  : BaseGatewayImage +
                                                                    values.imageThumbnail
                                                          ]
                                                        : values.imagePath
                                                        ? [
                                                              isValidUrl(
                                                                  values.imagePath
                                                              )
                                                                  ? values.imagePath
                                                                  : BaseGatewayImage +
                                                                    values.imagePath
                                                          ]
                                                        : []
                                                }
                                                accept={acceptImage}
                                                onChange={(files) => {
                                                    if (files.length > 0) {
                                                        setFieldValue(
                                                            'imagePath',
                                                            files[0].filePath,
                                                            true
                                                        );
                                                        setFieldValue(
                                                            'imageThumbnail',
                                                            files[0].thumbPath,
                                                            true
                                                        );
                                                    } else {
                                                        setFieldValue(
                                                            'imagePath',
                                                            '',
                                                            true
                                                        );
                                                        setFieldValue(
                                                            'imageThumbnail',
                                                            '',
                                                            true
                                                        );
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={
                                                    values.imageThumbnail
                                                        ? isValidUrl(
                                                              values.imageThumbnail
                                                          )
                                                            ? values.imageThumbnail
                                                            : BaseGatewayImage +
                                                              values.imageThumbnail
                                                        : values.imagePath
                                                        ? isValidUrl(
                                                              values.imagePath
                                                          )
                                                            ? values.imagePath
                                                            : BaseGatewayImage +
                                                              values.imagePath
                                                        : ''
                                                }
                                            />
                                        )}

                                        {/* <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.imageThumbnail}
                                        </div> */}
                                    </Col>
                                </Form.Group>

                                {/* {accountRoleManageDevice ? (
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                            >
                                                <Form.Label className="col-sm-2 col-form-label">
                                                    Khoa
                                                </Form.Label>
                                                <Col sm="10">
                                                    <AsyncPaginate
                                                        name="departmentId"
                                                        id="departmentId"
                                                        placeholder="Chọn Khoa"
                                                        value={
                                                            values.departmentId
                                                        }
                                                        disabled={
                                                            accountRoleManageDevice
                                                                ? false
                                                                : true
                                                        }
                                                        loadOptions={
                                                            loadOptions
                                                        }
                                                        isClearable
                                                        styles={
                                                            errors.departmentId !==
                                                            undefined
                                                                ? styleSelectError
                                                                : styleSelectDefault
                                                        }
                                                        onChange={(
                                                            selectedOption
                                                        ) => {
                                                            const event = {
                                                                target: {
                                                                    name: 'departmentId',
                                                                    value: selectedOption
                                                                }
                                                            };
                                                            handleChange(event);
                                                        }}
                                                        additional={{
                                                            page: 1
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            display: 'block',
                                                            color: '#dc3545'
                                                        }}
                                                        className="invalid-feedback"
                                                    >
                                                        {errors.departmentId}
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                        ) : (
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                            >
                                                <Form.Label className="col-sm-2 col-form-label">
                                                    Khoa
                                                </Form.Label>
                                                <Col sm="10">
                                                    <Form.Control
                                                        placeholder="Khoa"
                                                        type="text"
                                                        name="departmentName"
                                                        disabled={true}
                                                        value={
                                                            values.departmentName
                                                        }
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!errors.departmentName
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.departmentName}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                        )} */}

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Khoa
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Khoa"
                                            type="text"
                                            name="departmentName"
                                            disabled={true}
                                            value={values.departmentName}
                                            onChange={handleChange}
                                            isInvalid={!!errors.departmentName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.departmentName}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="status"
                                            id="status"
                                            isDisabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            placeholder="Trạng thái"
                                            value={values.status}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'status',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            options={objectDeviceStatus}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.status}
                                        </div>
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái kích hoạt
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary2"
                                            type="checkbox"
                                            placeholder="Trạng thái kích hoạt"
                                            name="isActive"
                                            checked={values.isActive}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary2">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group> */}
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Sắp xếp
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="orderNumber"
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                            placeholder="Sắp xếp"
                                            value={values.orderNumber}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Số lần sử dụng tối đa
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="recommendedNumberOfUse"
                                            placeholder="Số lần sử dụng tối đa"
                                            value={
                                                values.recommendedNumberOfUse
                                            }
                                            onChange={handleChange}
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Số lần hiện tại sử dụng
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="currentNumberOfUse"
                                            placeholder="Số lần hiện tại sử dụng"
                                            value={values.currentNumberOfUse}
                                            onChange={handleChange}
                                            disabled={
                                                accountRoleManageDevice
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Số lần còn lại
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="remainNumberOfUse"
                                            placeholder="Số lần còn lại"
                                            value={
                                                values.recommendedNumberOfUse -
                                                values.currentNumberOfUse
                                            }
                                            disabled={true}
                                            // onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{span: 10, offset: 2}}>
                                        <ButtonGroup className="mb-2">
                                            {accountRoleManageDevice ? (
                                                <>
                                                    <Button
                                                        type="submit"
                                                        theme="primary"
                                                    >
                                                        Sửa
                                                    </Button>
                                                    &nbsp;&nbsp;&nbsp;
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <Button
                                                onClick={() => history.goBack()}
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card.Body>
            </TabPanel>
                <TabPanel>
                    <DeviceMaintenance
                        // plantItemRefetch={() => plantItem.refetch()}
                        // listGrowthProcedureRefetch={() =>
                        //     listGrowthProcedure.refetch()
                        // }
                        // lstPlantGrowProcedureId={
                        //     plantItem.data ? plantItem.data.lstGrowthId : []
                        // }
                        // bookDeviceId={""}
                        deviceId={deviceId}
                        deviceName={
                            deviceItem &&
                            deviceItem.data
                                ? deviceItem.data.name : ''
                        }
                    />
                </TabPanel>
            </Tabs>
        </Card>
    );
};

export default DeviceEdit;
