import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import { POST } from '../constants';
import { Get,Post } from '../../utils/httpProvider';
import { POST_API } from '../../utils/apiUrl';


export const postPagingAction = createAsyncThunk(
    POST.GET_PAGING,
    async (model) => {
        return Get(POST_API.GET_PAGING_POST, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);
export const postPagingReportCreateByAction = createAsyncThunk(
    POST.GET_PAGING_REPORT_POST_CREATEBY,
    async (model) => {
        return Get(POST_API.GET_ALL_PAGING_REPORT_CREATEBY, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);
export const exportExcelPostCreateByAction = createAsyncThunk(
    POST.EXPORT_EXCEL_REPORT_POST_CREATEBY,
    async (model) => {
        return Post(POST_API.EXPORT_EXCEL_REPORT_POST_CREATEBY, null, model)
            .then((response) => {
                // return response;
                if (response.ok) {
                    response.blob().then((data) => {
                      saveAs(data, "baocao_baiviet_theotacgia.xlsx");
                    })
                  } 
            })
            .catch((error) => error);
    }
);


const initialState = {
    listPost: [],
    totalPost: 0,
    listPostReportCreateBy: [],
    totalPostReportCreateBy: 0
};
export const postSlice = createSlice({
    name: POST.NAME_SLICE,
    initialState,
    reducers: {
        filterListPost: (state, action) => {
            state.listPost =
                state.listPost.length > 0
                    ? state.listPost.filter((x) => x.id !== action.payload)
                    : [];
            state.totalPost -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(postPagingAction.fulfilled, (state, action) => {
            state.listPost =
                action.payload &&
                    action.payload.data &&
                    action.payload.data
                    ? action.payload.data
                    : [];
            state.totalPost =
                action.payload && action.payload.totalRecords
                    ? action.payload.totalRecords
                    : 0;
        })
        .addCase(postPagingReportCreateByAction.fulfilled, (state, action) => {
            state.listPostReportCreateBy =
                action.payload &&
                    action.payload.data &&
                    action.payload.data
                    ? action.payload.data
                    : [];
            state.totalPostReportCreateBy =
                action.payload && action.payload.totalRecords
                    ? action.payload.totalRecords
                    : 0;
        })
        ;
    }
});

export const { filterListPost } = postSlice.actions;

export default postSlice.reducer;
