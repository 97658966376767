import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { BrowserRouter as Router, Switch , useHistory} from 'react-router-dom';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import PrivacyPolicy from '@modules/privacy-policy/PrivacyPolicy';
import jwt_decode from 'jwt-decode';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import { URI_INTERNAL } from './utils/pathLocations';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from '../src/utils/define';
import 'react-datepicker/dist/react-datepicker.css';
import {logoutUser, refreshTokenFunc} from '@store/reducers/auth';
import './App.scss';

const App = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const token = localStorage.getItem(TOKEN_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    // if (token && refreshToken) {
    //     let decodedToken = jwt_decode(token);
    //     let currentDate = new Date();
    //     // JWT exp is in seconds
    //     // if (decodedToken.exp * 1000 < currentDate.getTime()) {
    //     //     dispatch(logoutUser());
    //     //     history.push('/login');
    //     // } else {
    //     //     console.log("Token còn hạn")
    //     // }

    //     if (decodedToken.exp * 1000 < currentDate.getTime()) {
    //         // dispatch(logoutUser());
    //         var model = {
    //             token: token,
    //             refreshToken: refreshToken
    //         }
    //         dispatch(refreshTokenFunc(model));
    //         // history.push('/login');
    //     } else {
    //         console.log("Token còn hạn")
    //     }
        
    // }

    useEffect(() => {
        const checkTokenExpiration = async () => {
          if (token && refreshToken) {
            const decodedToken = jwt_decode(token);
            const currentDate = new Date();
    
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
              try {
                await dispatch(refreshTokenFunc({ token, refreshToken }));
                // await dispatch(refreshTokenFunc({ token, refreshToken })).unwrap();
              } catch (error) {
                // await dispatch(logoutUser());
                // history.push('/login');
                console.log("hết hạn")
              }
            } else {
                document.getElementById('root').classList.remove('register-page', 'login-page', 'hold-transition');
            }
          }
        };
    
        checkTokenExpiration();
      }, [dispatch, token, refreshToken, history]);

    return (
        <Router>
            <Switch>
                <PublicRoute exact path={URI_INTERNAL.LOGIN_PATH}>
                    <Login />
                </PublicRoute>
                <PublicRoute exact path={URI_INTERNAL.FORGOT_PASSWORD_PATH}>
                    <ForgetPassword />
                </PublicRoute>
                <PublicRoute exact path={URI_INTERNAL.RECOVER_PASSWORD_PATH}>
                    <RecoverPassword />
                </PublicRoute>
                <PublicRoute exact path="/privacy-policy">
                    <PrivacyPolicy />
                </PublicRoute>
                <PublicRoute exact path="/callback">
                    <h1>Callback</h1>
                </PublicRoute>
                <PrivateRoute path="/">
                    <Main />
                </PrivateRoute>
            </Switch>
        </Router>
    );
};

export default App;
