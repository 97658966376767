import React, { useState } from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { messageError, messageToast } from '../../utils/define';
import { addEmailConfigAction } from '../../store/reducers/emailConfig';
import ReactSpinner from '../../components/reactSpinner/ReactSpinner';

const schema = yup.object().shape({
    email: yup
        .string()
        .email(messageError.emailMessage)
        .required(messageError.emptyMessage),
    password: yup.string().required(messageError.emptyMessage),
    mailServer: yup.string().required(messageError.emptyMessage),
    emailTitle: yup.string().required(messageError.emptyMessage)
});

const EmailConfigAdd = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    
    const handleCreate = async (values) => {
        const model = values;
        try {
            const result = await dispatch(addEmailConfigAction(model));
            if (result.payload !== 200) {
                toast.error(messageToast.messageToastCreateError);
            } else {
                setIsLoading(true);
                toast.success(messageToast.messageToastCreateSuccess);
                history.goBack();
            }
        } catch (error) {
            toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
        }
    };

    return (
        <Card>
            <Card.Header as="h5">Thêm Email gửi thông báo</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => handleCreate(values)}
                    initialValues={{
                        email: '',
                        emailTitle: '',
                        password: '',
                        port: 0,
                        mailServer: '',
                        enableSSl: false,
                        isActive: false
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Email *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Email"
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tiêu đề Email *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tiêu đề Email"
                                        type="text"
                                        name="emailTitle"
                                        value={values.emailTitle}
                                        onChange={handleChange}
                                        isInvalid={!!errors.emailTitle}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.emailTitle}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Password *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Password"
                                        type="text"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Port
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="port"
                                        placeholder="Port"
                                        value={values.port}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Mail server *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Mail server"
                                        type="text"
                                        name="mailServer"
                                        value={values.mailServer}
                                        onChange={handleChange}
                                        isInvalid={!!errors.mailServer}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.mailServer}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Enable SSL
                                </Form.Label>
                                <Col sm="10" className="icheck-primary">
                                    <Form.Control
                                        id="checkboxPrimary2"
                                        type="checkbox"
                                        placeholder="Trạng thái"
                                        name="enableSSl"
                                        checked={values.enableSSl}
                                        onClick={handleChange}
                                    />
                                    <Form.Label htmlFor="checkboxPrimary2">
                                        {' '}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Trạng thái kích hoạt
                                </Form.Label>
                                <Col sm="10" className="icheck-primary">
                                    <Form.Control
                                        id="checkboxPrimary1"
                                        type="checkbox"
                                        placeholder="Trạng thái"
                                        name="isActive"
                                        checked={values.isActive}
                                        onClick={handleChange}
                                    />
                                    <Form.Label htmlFor="checkboxPrimary1">
                                        {' '}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col md={{ span: 10, offset: 2 }}>
                                    <ButtonGroup className="mb-2">
                                        <Button
                                            type="submit"
                                            theme="primary"
                                            disabled={isLoading}
                                        >
                                            Thêm
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            onClick={() =>
                                                history.goBack()
                                            }
                                            theme="secondary"
                                        >
                                            Hủy
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
                <ReactSpinner loadings={isLoading} />
            </Card.Body>
        </Card>
    );
};

export default EmailConfigAdd;
