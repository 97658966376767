
// export const BaseGatewayImage = 'http://localhost:5000';
// const BaseGateway = 'http://localhost:5000/api/v1';

export const BaseGatewayImage = 'http://api.quanlythietbi.cntt108.vn';
const BaseGateway = 'http://api.quanlythietbi.cntt108.vn/api/v1';

const BaseGatewayFromMainServer = 'http://108.108.108.52:10808';

export const  BaseGatewayClient  = 'http://interbgc.com.vn'

const BaseGatewayCrawler = 'http://crawlerapi.xecong.info';
// api chung lấy nguộn 
export const CRAWLER_API = {
    GETPAGING_NEWCONTENT: `${BaseGatewayCrawler}/NewsContent/GetAllPaging`,
    DELETE_NEWCONTENT: `${BaseGatewayCrawler}/NewsContent/DeleteByDate`,
    GETBYID_NEWCONTENT: `${BaseGatewayCrawler}/NewsContent/GetById`
};

export const AUTH_API = {
    LOGIN_API: `${BaseGateway}/Auth/authenticate`,
    REFRESHTOKEN_API: `${BaseGateway}/Auth/refreshToken`,
    CHANGE_PASSWORD_API: `${BaseGateway}/Auth/ChangePassword`,
    FORGOT_PASSWORD: `${BaseGateway}/Auth/ForgotPassword`,
    RESET_PASSWORD: `${BaseGateway}/Auth/ResetPassword`
};

export const ACCOUNT_API = {
    GET_PAGING_ACCOUNT: `${BaseGateway}/Account/GetAllPaging`,
    ADD_ACCOUNT: `${BaseGateway}/Account/Create`,
    EDIT_ACCOUNT: `${BaseGateway}/Account/Update`,
    DELETE_ACCOUNT: `${BaseGateway}/Account/DeleteItem`,
    GET_BY_ID_ACCOUNT: `${BaseGateway}/Account/GetItem`,
    COUNT_ALL_ACCOUNT_API: `${BaseGateway}/Account/CountAllAccount`
};

export const FILE_MANAGER_API = {
    UPLOAD: `${BaseGateway}/FileManager/Upload`,
    GET_PAGING: `${BaseGateway}/FileManager/GetAllByPaging`,
    GET_DIR_PATH: `${BaseGateway}/FileManager/GetDirectoryPath`,
    DELETE: `${BaseGateway}/FileManager`
};

export const NEWSCRAWLER_API = {
    GET_PAGING_NEWSCRAWLER: `${BaseGateway}/ApiNewsCrawler/GetAllByPaging`,
    GET_ITEM_NEWSCRAWLER: `${BaseGateway}/ApiNewsCrawler/GetItem`,
    UPDATE_ITEM_NEWSCRAWLER: `${BaseGateway}/ApiNewsCrawler/UpdateItem`,
    DELETE_NEWSCRAWLER: `${BaseGateway}/ApiNewsCrawler/DeleteByDate`
};

export const SLIDE_API = {
    GET_PAGING: `${BaseGateway}/Slide/GetAllPaging`,
    GET_ITEM: `${BaseGateway}/Slide/GetById`,
    CREATE_SLIDE: `${BaseGateway}/Slide/Create`,
    UPDATE_SLIDE: `${BaseGateway}/Slide/Update`,
    DELETE_SLIDE: `${BaseGateway}/Slide/Delete`
};
export const BANNER_API = {
    GET_PAGING_API: `${BaseGateway}/Advertising/GetAllByPaging`,
    GET_ITEM_API: `${BaseGateway}/Advertising/GetItem`,
    GET_ITEM_BY_CATE_API: `${BaseGateway}/Advertising/GetByCate`,
    CREATE_BANNER_API: `${BaseGateway}/Advertising/CreateAdvertising`,
    UPDATE_BANNER_API: `${BaseGateway}/Advertising/UpdateItem`,
    DELETE_BANNER_API: `${BaseGateway}/Advertising/DeleteItem`
};

export const EMAILCONFIG_API = {
    GET_PAGING_EMAILCONFIG: `${BaseGateway}/EmailConfig/GetAllByPaging`,
    GET_ITEM_EMAILCONFIG: `${BaseGateway}/EmailConfig/GetItem`,
    ADD_EMAILCONFIG: `${BaseGateway}/EmailConfig/CreateEmailConfig`,
    UPDATE_EMAILCONFIG: `${BaseGateway}/EmailConfig/UpdateItem`,
    DELETE_EMAILCONFIG: `${BaseGateway}/EmailConfig/DeleteItem`
};

export const PARTMER_API = {
    GET_PAGING_PARTMER: `${BaseGateway}/Partner/GetAllByPaging`,
    GET_ITEM_PARTMER: `${BaseGateway}/Partner/GetItem`,
    ADD_PARTMER: `${BaseGateway}/Partner/CreatePartner`,
    UPDATE_PARTMER: `${BaseGateway}/Partner/UpdateItem`,
    DELETE_PARTMER: `${BaseGateway}/Partner/DeleteItem`
};
export const GIAODIEN_API = {
    GET_PAGING_GIAODIEN: `${BaseGateway}/Giaodien/GetAllByPaging`,
    GET_ITEM_GIAODIEN: `${BaseGateway}/Giaodien/GetItem`,
    ADD_GIAODIEN: `${BaseGateway}/Giaodien/CreateGiaodien`,
    UPDATE_GIAODIEN: `${BaseGateway}/Giaodien/UpdateItem`,
    DELETE_GIAODIEN: `${BaseGateway}/Giaodien/DeleteItem`
};

export const CONTACT_API = {
    GET_PAGING_CONTACT: `${BaseGateway}/Contact/GetAllByPaging`,
}

export const CATEGORY_API = {
    GET_ALL_CATEGORY: `${BaseGateway}/Category/GetAll`,
    GET_PAGING_CATEGORY: `${BaseGateway}/Category/GetAllByPaging`,
    GET_ITEM_CATEGORY: `${BaseGateway}/Category/GetItem`,
    ADD_CATEGORY: `${BaseGateway}/Category/CreateCategory`,
    UPDATE_CATEGORY: `${BaseGateway}/Category/UpdateItem`,
    DELETE_CATEGORY: `${BaseGateway}/Category/DeleteItem`,
    UPDATE_TREE: `${BaseGateway}/Category/UpdateTree`
};

export const POST_API = {
    GET_PAGING_POST: `${BaseGateway}/Post/GetAllByPaging`,
    GET_ITEM_POST: `${BaseGateway}/Post/GetItem`,
    ADD_POST: `${BaseGateway}/Post/CreatePost`,
    UPDATE_POST: `${BaseGateway}/Post/UpdateItem`,
    DELETE_POST: `${BaseGateway}/Post/DeleteItem`,
    // COUNT_ALL_POST_API: ` ${BaseGateway}/Post/CountAllPost`,
    // STATISTIC_POST_BY_STATUS_API: ` ${BaseGateway}/Post/GetCountPostByStatus`,
    // STATISTIC_POST_BY_CATEGORY_API: ` ${BaseGateway}/Post/GetCountPostByCategory`,
    // STATISTIC_POST_BY_CREATE_BY_API: ` ${BaseGateway}/Post/GetCountPostByAccount`,
    // STATISTIC_POST_BY_CREATE_DATE_API: ` ${BaseGateway}/Post/GetCountPostByCreateDate`,
    // STATISTIC_POST_BY_PUBLISH_TIME_API: ` ${BaseGateway}/Post/GetCountPostByPublishTime`,
    // STATISTIC_POST_BY_DATA_TYPE_API: ` ${BaseGateway}/Post/GetCountPostByDataType`,
    STATISTIC_POST_BY_PUBLISH_TIME_AND_DATA_TYPE_API: ` ${BaseGateway}/Post/CountPostByDateAndType`,
    GENERATE_REPORT_POST: ` ${BaseGateway}/Post/CreateCSV`,
    STATISTIC_POST_SYNTHETIC_API: ` ${BaseGateway}/Post/RepostPostSynthetic`,
    COUNT_POST_BY_CONDITION_API: ` ${BaseGateway}/Post/CountPostByCondition`,
    COUNT_POST_WEBANALYSIS_BY_DATE_API: ` ${BaseGateway}/Post/GetCountPostByWebAnalysisByDate`,
    COUNT_POST_STATUS_BY_ACCOUNT: ` ${BaseGateway}/Post/CountPostStatusByAccount`,

    CHANGE_STATUS_POST_API: ` ${BaseGateway}/Post/ChangeStatus`,
    GET_ALL_PAGING_REPORT_CREATEBY: ` ${BaseGateway}/Post/GetAllPagingReportCreatBy`,
    EXPORT_EXCEL_REPORT_POST_CREATEBY: `${BaseGateway}/Post/CreateCSVPostCreateBy`
};

export const LOGIN_HISTORY_API = {
    GET_PAGING_LOGIN: `${BaseGateway}/LoginHistory/GetAllPaging`,
    STATISTIC_LOGIN: `${BaseGateway}/LoginHistory/GetStatisticsLoginByAccount`,
    STATISTIC_COUNT_LOGIN_BY_DATE: `${BaseGateway}/LoginHistory/CountAllByDate`
};

export const LOG_API = {
    GET_PAGING_LOG: `${BaseGateway}/Log/GetAllByPaging`,
    DELETE_LOG: `${BaseGateway}/Log/DeleteByDate`,
    COUNT_LOG_BY_DATE: `${BaseGateway}/Log/CountLogsByDate`,
};

export const DYNAMICDATA_API = {
    CREATE: `${BaseGateway}/DynamicData/CreateDynamicData`,
    UPDATE: `${BaseGateway}/DynamicData/Update`,
    DELETE: `${BaseGateway}/DynamicData/DeleteItem`,
    GETBYID: `${BaseGateway}/DynamicData/GetById`,
    GET_ALL_PAGING: `${BaseGateway}/DynamicData/GetAllPaging`
};

export const HTML5BANNER_API = {
    GET_PAGING_HTML5BANNER: `${BaseGateway}/Html5Banner/GetAllByPaging`,
    GET_ITEM_HTML5BANNER: `${BaseGateway}/Html5Banner/GetItem`,
    ADD_HTML5BANNER: `${BaseGateway}/Html5Banner/CreateItem`,
    UPDATE_HTML5BANNER: `${BaseGateway}/Html5Banner/UpdateItem`,
    DELETE_HTML5BANNER: `${BaseGateway}/Html5Banner/DeleteItem`
}

export const WEB_ANALYSIS = {
    // COUNT_WEB_ANALYSIS_BY_DATE: `${BaseGateway}/WebAnalysis/CountWebAnalysisByDate`,
    COUNT_VISITS: `${BaseGateway}/WebAnalysis/CountVisits`,
    COUNT_WEB_ANALYSIS_BY_DATE: `${BaseGateway}/WebAnalysis/CountVisitsByDate`,
    COUNT_VISITS_DETAIL: `${BaseGateway}/WebAnalysis/CountVisitsDetail`,
    COUNT_VISITS_DETAIL_TOTAL: `${BaseGateway}/WebAnalysis/CountTotalByCondition`,
    COUNT_VISITS_DETAIL_CATE: `${BaseGateway}/WebAnalysis/CountVisitCategoryDetail`,
    COUNT_VISITS_BY_DATE: `${BaseGateway}/WebAnalysis/CountVisitByDate`,
    GENERATE_REPORT_POST_COUNT_VISITS_DETAIL: `${BaseGateway}/WebAnalysis/CreateCSVCountVisitsDetail`,
    GENERATE_REPORT_POST_COUNT_VISITS_DETAIL_CATE: `${BaseGateway}/WebAnalysis/CreateCSVCountVisitCategoryDetail`
};
export const ADVERTISING_ANALYSIS_API = {
    COUNT_ADVERTISING_ANALYSIS: `${BaseGateway}/AdvertisingAnalysis/CountAdvertisingClickByCondition`,
    COUNT_ADVERTISING_ANALYSIS_BY_DATE: `${BaseGateway}/AdvertisingAnalysis/CountAdvertisingClickByDate`,
};

export const AREA_API = {
    GET_ALL_AREA: `${BaseGateway}/Area/GetAll`,
    GET_PAGING_AREA: `${BaseGateway}/Area/GetAllPaging`,
    GET_ITEM_AREA: `${BaseGateway}/Area/GetById`,
    ADD_AREA: `${BaseGateway}/Area/Create`,
    UPDATE_AREA: `${BaseGateway}/Area/Update`,
    DELETE_AREA: `${BaseGateway}/Area/Delete`,
};

export const ADMINISTRATIVE_AREA_API = {
    // GET_ALL: `${BaseGateway}/AdministrativeArea/GetAll`,
    GET_PAGING: `${BaseGateway}/AdministrativeArea/GetAllPaging`,
    GET_ITEM: `${BaseGateway}/AdministrativeArea/GetById`,
    ADD_ITEM: `${BaseGateway}/AdministrativeArea/Create`,
    UPDATE_ITEM: `${BaseGateway}/AdministrativeArea/Update`,
    DELETE_ITEM: `${BaseGateway}/AdministrativeArea/Delete`,
};

export const FERTILIZER_API = {
    // GET_ALL: `${BaseGateway}/AdministrativeArea/GetAll`,
    GET_PAGING: `${BaseGateway}/Fertilizer/GetAllPaging`,
    GET_FERTILIZERS_BY_INPUT: `${BaseGateway}/Fertilizer/GetFertilizersByInput`,
    GET_ITEM: `${BaseGateway}/Fertilizer/GetById`,
    ADD_ITEM: `${BaseGateway}/Fertilizer/Create`,
    UPDATE_ITEM: `${BaseGateway}/Fertilizer/Update`,
    DELETE_ITEM: `${BaseGateway}/Fertilizer/Delete`,
};

export const FERTILIZER_COMPONENT_API = {
    // GET_ALL: `${BaseGateway}/AdministrativeArea/GetAll`,
    GET_PAGING: `${BaseGateway}/FertilizerComponent/GetAllPaging`,
    GET_ITEM: `${BaseGateway}/FertilizerComponent/GetById`,
    ADD_ITEM: `${BaseGateway}/FertilizerComponent/Create`,
    UPDATE_ITEM: `${BaseGateway}/FertilizerComponent/Update`,
    DELETE_ITEM: `${BaseGateway}/FertilizerComponent/Delete`,
};

export const LAND_API = {
    // GET_ALL: `${BaseGateway}/AdministrativeArea/GetAll`,
    GET_PAGING: `${BaseGateway}/Land/GetAllPaging`,
    GET_ITEM: `${BaseGateway}/Land/GetById`,
    ADD_ITEM: `${BaseGateway}/Land/Create`,
    UPDATE_ITEM: `${BaseGateway}/Land/Update`,
    DELETE_ITEM: `${BaseGateway}/Land/Delete`,
};


export const PLANT_CATEGORY_API = {
    GET_ALL: `${BaseGateway}/PlantCategory/GetAll`,
    GET_PAGING: `${BaseGateway}/PlantCategory/GetAllPaging`,
    GET_ITEM: `${BaseGateway}/PlantCategory/GetById`,
    ADD_ITEM: `${BaseGateway}/PlantCategory/Create`,
    UPDATE_ITEM: `${BaseGateway}/PlantCategory/Update`,
    DELETE_ITEM: `${BaseGateway}/PlantCategory/Delete`,
};

export const PLANT_API = {
    GET_ALL_BY_CONDITION: `${BaseGateway}/Plant/GetAllPagingProcedureByLstGrowthId`,
    GET_PAGING: `${BaseGateway}/Plant/GetAllPaging`,
    GET_ITEM: `${BaseGateway}/Plant/GetById`,
    ADD_ITEM: `${BaseGateway}/Plant/Create`,
    UPDATE_ITEM: `${BaseGateway}/Plant/Update`,
    UPDATE_ITEM_BY_SPECIFIC: `${BaseGateway}/Plant/UpdateByLstGrowthId`,
    DELETE_ITEM: `${BaseGateway}/Plant/Delete`,
};

export const PLANT_GROWTH_PROCEDURE_API = {
    GET_PAGING: `${BaseGateway}/PlantGrowthProcedure/GetAllPaging`,
    GET_ITEM: `${BaseGateway}/PlantGrowthProcedure/GetById`,
    ADD_ITEM: `${BaseGateway}/PlantGrowthProcedure/Create`,
    UPDATE_ITEM: `${BaseGateway}/PlantGrowthProcedure/Update`,
    DELETE_ITEM: `${BaseGateway}/PlantGrowthProcedure/Delete`,
};

export const MOBILE_VERSION_API = {
    GET_PAGING: `${BaseGateway}/MobileVersion/GetAllPaging`,
    GET_ITEM: `${BaseGateway}/MobileVersion/GetById`,
    ADD_ITEM: `${BaseGateway}/MobileVersion/Create`,
    UPDATE_ITEM: `${BaseGateway}/MobileVersion/Update`,
    DELETE_ITEM: `${BaseGateway}/MobileVersion/Delete`,
};

export const CHATBOTQA_API = {
    // GET_ALL: `${BaseGateway}/AdministrativeArea/GetAll`,
    GET_PAGING: `${BaseGateway}/ChatBotQA/GetAllPaging`,
    GET_ITEM: `${BaseGateway}/ChatBotQA/GetById`,
    ADD_ITEM: `${BaseGateway}/ChatBotQA/Create`,
    UPDATE_ITEM: `${BaseGateway}/ChatBotQA/Update`,
    DELETE_ITEM: `${BaseGateway}/ChatBotQA/Delete`,
};

export const DEVICE_API = {
    GET_PAGING: `${BaseGateway}/Device/GetAllByPaging`,
    GET_ITEM: `${BaseGateway}/Device/GetItem`,
    ADD_ITEM: `${BaseGateway}/Device/Create`,
    ADD_MULTIPLE_ITEMS: `${BaseGateway}/Device/CreateMultiple`,
    UPDATE_ITEM: `${BaseGateway}/Device/UpdateItem`,
    DELETE_ITEM: `${BaseGateway}/Device/DeleteItem`
};

export const BOOKDEVICE_API = {
    GET_PAGING: `${BaseGateway}/BookDevice/GetAllByPaging`,
    GET_ITEM: `${BaseGateway}/BookDevice/GetItem`,
    ADD_ITEM: `${BaseGateway}/BookDevice/Create`,
    UPDATE_ITEM: `${BaseGateway}/BookDevice/UpdateItem`,
    UPDATE_APPROVE: `${BaseGateway}/BookDevice/UpdateApprove`,
    DELETE_ITEM: `${BaseGateway}/BookDevice/DeleteItem`
};

export const DEVICEMAINTENANCE_API = {
    GET_PAGING: `${BaseGateway}/DeviceMaintenance/GetAllByPaging`,
    GET_ITEM: `${BaseGateway}/DeviceMaintenance/GetItem`,
    ADD_ITEM: `${BaseGateway}/DeviceMaintenance/Create`,
    SEND_ITEM: `${BaseGateway}/DeviceMaintenance/SendEmailMaintenance`,
    UPDATE_ITEM: `${BaseGateway}/DeviceMaintenance/UpdateItem`,
    DELETE_ITEM: `${BaseGateway}/DeviceMaintenance/DeleteItem`
};

export const DEPARTMENT_API = {
    GET_ALL: `${BaseGateway}/Department/GetAll`,
};

export const USERNAMEANDPHANMEMID_API = {
    GET_ITEM: `${BaseGateway}/Account/SelectUserNameAndPhanMemID`,
    DELETE_ITEM: `${BaseGateway}/Account/DeleteUserNameAndPhanMemID`,
};
