import React, {useState, useEffect} from 'react';
import Pagination from 'react-js-pagination';
import {useSelector} from 'react-redux';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import {Form, Row, Col} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import {toast} from 'react-toastify';
import {useMutation, useQuery} from 'react-query';
import {WithContext as ReactTags} from 'react-tag-input';
import {AsyncPaginate} from 'react-select-async-paginate';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {Image} from 'react-bootstrap';
import {
    BOOKDEVICE_API,
    BaseGatewayImage,
    DEVICE_API,
    DEPARTMENT_API
} from '@app/utils/apiUrl';
import {DeleteSingle, Get} from '@app/utils/httpProvider';
import {
    createUrlWithParam,
    getInfomationAccountFromToken
} from '../../utils/helper';
import {URI_INTERNAL} from '../../utils/pathLocations';
import {
    objectShowNumber,
    messageToast,
    deviceStatus,
    styleSelectDefault,
    styleSelectError,
    rangeDateBooking,
    objectBookDeviceStatus,
    role,
    bookDeviceStatus
} from '../../utils/define';
import {ConfirmModal} from '../../modules/modal/ConfirmModal';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import viLocale from '@fullcalendar/core/locales/vi';
import moment from 'moment';
import Select from 'react-select';
import {BrowserView, MobileView} from 'react-device-detect';

import {Tooltip} from 'bootstrap';

const BookDevice = () => {
    let tooltipInstance = null;
    const history = useHistory();
    const location = useLocation();
    const valueSearch = useSelector((state) => state.search.valueSearch);

    let accountRoleBooker = false;
    let accountRoleAdmin = false;
    let accountRoleApprover = false;
    const inforAccount = getInfomationAccountFromToken();

    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        accountRoleAdmin = userRole.includes(role.Admin);
        accountRoleBooker =
            userRole.includes(role.Admin) || userRole.includes(role.Booker);
        accountRoleApprover =
            userRole.includes(role.Admin) || userRole.includes(role.Approver);
    }

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');
    const [bookStatus, setBookStatus] = useState('');
    const [bookerName, setBookerName] = useState('');
    const [currentView, setCurrentView] = useState('dayGridMonth');
    // const [deviceName, setDeviceName] = useState('');

    const [isAbleDeleted, setIsAbleDeleted] = useState(false);

    const [openConfirmAcceptDeleteModal, setOpenConfirmAcceptDeleteModal] =
        useState(false);
    const [idWantDeleteOrEdit, setIdWantDeleteOrEdit] = useState('');
    const [confirmDelOrEdMessage, setConfirmDelOrEdMessage] = useState('');
    const [departmentId, setDepartmentId] = useState('');

    // const timeCurentDay = moment().subtract(6, 'days');
    // timeCurentDay.set({hour: 0, minute: 0, second: 0, millisecond: 0});

    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });

    const deleteFunc = useMutation((value) =>
        DeleteSingle(BOOKDEVICE_API.DELETE_ITEM, value, null)
    );

    const paramUrl = new URLSearchParams(location.search.substring(1));
    if (
        paramUrl.get('page') != null &&
        parseInt(paramUrl.get('page'), 10) !== page
    ) {
        const pageResult = parseInt(paramUrl.get('page'), 10);
        setPage(pageResult);
    }
    if (
        paramUrl.get('size') != null &&
        parseInt(paramUrl.get('size'), 10) !== pageSize
    ) {
        const sizeResult = parseInt(paramUrl.get('size'), 10);
        setPageSize(sizeResult);
    }
    if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
        setSearch(paramUrl.get('search'));
    }

    const [tagName, setTagName] = useState('');

    const listDevice = useQuery(['getListDeviceInBookDevice'], () => {
        return Get(DEVICE_API.GET_PAGING, null, {
            PageIndex: 1,
            PageSize: 8,
            Keyword: tagName,
            DeviceStatus: deviceStatus.available,
            DepartmentId:
                departmentId && departmentId.target && departmentId.target.value
                    ? departmentId.target.value.value
                    : ''
        });
    });

    const [tags, setTags] = React.useState([]);
    const [suggestions, setSuggestions] = React.useState([]);

    const {isLoading, isError, error, data, refetch} = useQuery(
        ['bookDeviceData', valueSearch, page, pageSize],
        () => {
            let model = {
                Keyword: valueSearch ? valueSearch : '',
                PageIndex: page,
                PageSize: pageSize,
                LstDeviceId: tags ? tags.map((x) => x.id) : [],
                AccountId:
                    (valueSearch == '' || valueSearch == null) &&
                    (tags == null || tags.length === 0) &&
                    (dates == null ||
                        dates.startDate == null ||
                        dates.endDate == null) &&
                    (bookStatus ==
                        objectBookDeviceStatus.find(
                            (x) => x.value == bookDeviceStatus.all
                        ) ||
                        bookStatus == '')
                        ? inforAccount.id
                        : ''
            };

            if (dates && dates.startDate) {
                model.BookTime = dates.startDate._d.toISOString();
            }

            if (dates && dates.endDate) {
                model.EndBookTime = dates.endDate._d.toISOString();
            }

            //    if (bookStatus && bookStatus.target && bookStatus.target.value) {
            //         model.bookStatus = bookStatus.target.value.value;
            //     }

            if (bookStatus && bookStatus != bookDeviceStatus.all) {
                model.BookStatus = bookStatus;
            }

            return Get(BOOKDEVICE_API.GET_PAGING, null, model);
        }
    );

    const listDepartment = useQuery(['getAllDepartment'], () =>
        Get(DEPARTMENT_API.GET_ALL, null, null)
    );

    useEffect(() => {
        listDevice.refetch();
    }, [departmentId]);

    useEffect(() => {
        refetch();
    }, [pageSize, page, tags, dates, bookStatus]);

    const replaceHistory = async (Page, Size, Search) => {
        history.replace(
            createUrlWithParam(URI_INTERNAL.BOOKDEVICE_PATH, {
                page: Page,
                size: Size,
                search: Search
            })
        );
    };

    React.useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            replaceHistory(1, pageSize, valueSearch);
        }
    }, [valueSearch]);

    // const changePage = (number) => {
    //     setPage(number);
    //     replaceHistory(number, pageSize, search);
    // };

    const onChangeShowNumber = (e) => {
        setPageSize(e.target.value);
        replaceHistory(page, e.target.value, search);
    };

    // const deleteAction = (id, name) => {
    //     setIdWantDeleteOrEdit(id);
    //     setConfirmDelOrEdMessage(name);
    //     setOpenConfirmAcceptDeleteModal(true);
    // };

    const acceptDelete = async () => {
        if (idWantDeleteOrEdit !== '') {
            deleteFunc.mutate(idWantDeleteOrEdit, {
                onSuccess: (res) => {
                    if (res.status === 201 || res.status === 200) {
                        refetch();
                        toast.success(messageToast.messageToastDeleteSuccess);
                        // if (data.data.$values.length === 1 && page !== 1) {
                        //     history.push(
                        //         createUrlWithParam(
                        //             URI_INTERNAL.BOOKDEVICE_PATH,
                        //             {
                        //                 page: 1,
                        //                 size: pageSize,
                        //                 search: search
                        //             }
                        //         )
                        //     );
                        // }
                    } else {
                        toast.error(messageToast.messageToastDeleteError);
                    }
                },
                onError: (res) => {
                    toast.error(messageToast.messageToastTryCatch);
                }
            });

            // replaceHistory(1, pageSize, search);
            setOpenConfirmAcceptDeleteModal(false);
        }
    };

    const cancelDelete = () => {
        setIdWantDeleteOrEdit('');
        setOpenConfirmAcceptDeleteModal(false);
    };

    const mappedData = (e) => {
        return e.map((item) => {
            // Convert start and end times to Date objects if they aren't already
            const startDate = new Date(item.bookTime);
            const endDate = new Date(item.endBookTime);

            // Check if the start and end are on the same day
            const isSameDay =
                startDate.getDate() === endDate.getDate() &&
                startDate.getMonth() === endDate.getMonth() &&
                startDate.getFullYear() === endDate.getFullYear();

            const isMidnightEndDate =
                endDate.getHours() === 0 &&
                endDate.getMinutes() === 0 &&
                endDate.getSeconds() === 0;

            // Calculate the difference in milliseconds
            const difference = endDate - startDate;

            // Calculate the number of milliseconds in 24 hours
            const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

            // Check if the difference is less than or equal to 24 hours
            const isWithin24Hours = difference <= twentyFourHoursInMilliseconds;

            const isDayGridMonth = currentView === 'dayGridMonth';

            let color = '';

            switch (item.bookStatus) {
                case bookDeviceStatus.waitingForApprove:
                    color = '#feff99';
                    break;
                case bookDeviceStatus.approvedAndBooked:
                    color = '#b3c6e7';
                    break;
                case bookDeviceStatus.finishBooking:
                    color = '#a9d08f';
                    break;
                case bookDeviceStatus.notApprove:
                    color = '#adadad';
                    break;
                case bookDeviceStatus.unApprove:
                    color = '#f4af85';
                    break;
            }

            // var desPurpose = item.description;
            // var plainStringPurpose = desPurpose.replace(/<[^>]+>/g, '');

            var deviceName = item.device ? item.device.name : '';

            return {
                id: item.id,
                title: item.account.fullName,
                start: item.bookTime,
                end: item.endBookTime,
                extendedProps: {
                    description:
                        item.account.fullName +
                        ' - ' +
                        item.borrowDepartmentName +
                        ' - ' +
                        deviceName +
                        ' - ' +
                        item.description
                },
                // color: item.bookColor,
                color: color,
                allDay:
                    isDayGridMonth &&
                    (isSameDay || (isMidnightEndDate && isWithin24Hours)) // Set based on the same day check
            };
        });
    };

    const [events, setEvents] = useState([]);

    useEffect(() => {
        if (bookerName && data && data.data && data.data.length > 0) {
            setEvents(
                mappedData(
                    data.data.filter(
                        (x) =>
                            x.account && x.account.fullName.includes(bookerName)
                    )
                )
            );
        } else if (data && data.data && data.data.length > 0) {
            setEvents(mappedData(data.data));
        } else {
            setEvents([]);
        }
    }, [bookerName, data, currentView]);

    // const events =
    //     data && data.data && data.data.length > 0
    //         ? data.data.map((item) => {
    //               // Convert start and end times to Date objects if they aren't already
    //               const startDate = new Date(item.bookTime);
    //               const endDate = new Date(item.endBookTime);

    //               // Check if the start and end are on the same day
    //               const isSameDay =
    //                   startDate.getDate() === endDate.getDate() &&
    //                   startDate.getMonth() === endDate.getMonth() &&
    //                   startDate.getFullYear() === endDate.getFullYear();

    //               return {
    //                   id: item.id,
    //                   title: item.title,
    //                   start: item.bookTime,
    //                   end: item.endBookTime,
    //                   color: item.bookColor,
    //                   allDay: isSameDay // Set based on the same day check
    //               };
    //           })
    //         : [];

    // const events = [
    //     {title: 'Meeting', start: moment().toDate(), end: moment().add(6, 'days').toDate(), color: "red"},
    //     {title: 'MeetingV1', start: moment().toDate(), end: moment().add(5, 'days').toDate(), color: "blue"},
    //     {title: 'MeetingV2', start: moment().toDate(), end: moment().add(4, 'days').toDate(), color: "yellow"}
    // ];

    const renderEventContent = (eventInfo) => {
        return (
            <>
                <b>
                    {data &&
                    data.data &&
                    data.data.length > 0 &&
                    data.data.find((item) => item.id == eventInfo.event.id) &&
                    data.data.find((item) => item.id == eventInfo.event.id)
                        .bookTime
                        ? moment(
                              data.data.find(
                                  (item) => item.id == eventInfo.event.id
                              ).bookTime
                          ).format('HH:mm')
                        : ''}{' '}
                    -{' '}
                    {data &&
                    data.data &&
                    data.data.length > 0 &&
                    data.data.find((item) => item.id == eventInfo.event.id) &&
                    data.data.find((item) => item.id == eventInfo.event.id)
                        .endBookTime
                        ? moment(
                              data.data.find(
                                  (item) => item.id == eventInfo.event.id
                              ).endBookTime
                          ).format('HH:mm')
                        : ''}
                </b>
                &nbsp;&nbsp;&nbsp;
                <i>{eventInfo.event.title}</i>
                &nbsp;&nbsp;&nbsp;
            </>
        );
    };

    const handleEventClick = (e) => {
        setIdWantDeleteOrEdit(e.event.id);
        var startDateStr =
            data &&
            data.data &&
            data.data.length > 0 &&
            data.data.find((item) => item.id == e.event.id) &&
            data.data.find((item) => item.id == e.event.id).bookTime
                ? moment(
                      data.data.find((item) => item.id == e.event.id).bookTime
                  ).format('HH:mm')
                : '';
        var endDateStr =
            data &&
            data.data &&
            data.data.length > 0 &&
            data.data.find((item) => item.id == e.event.id) &&
            data.data.find((item) => item.id == e.event.id).endBookTime
                ? moment(
                      data.data.find((item) => item.id == e.event.id)
                          .endBookTime
                  ).format('HH:mm')
                : '';
        setConfirmDelOrEdMessage(
            startDateStr + ' - ' + endDateStr + ' ' + e.event.title
        );
        setOpenConfirmAcceptDeleteModal(true);

        var item =
            data &&
            data.data &&
            data.data.length > 0 &&
            data.data.find((x) => x.id == e.event.id)
                ? data.data.find((x) => x.id == e.event.id)
                : bookDeviceStatus.waitingForApprove;

        switch (item.bookStatus) {
            case bookDeviceStatus.waitingForApprove:
                if (
                    accountRoleAdmin ||
                    (item.accountId == inforAccount.id && accountRoleBooker)
                ) {
                    setIsAbleDeleted(true);
                } else {
                    setIsAbleDeleted(false);
                }
                break;
            case bookDeviceStatus.approvedAndBooked:
                if (accountRoleAdmin) {
                    setIsAbleDeleted(true);
                } else {
                    setIsAbleDeleted(false);
                }
                break;
            case bookDeviceStatus.finishBooking:
                if (accountRoleAdmin) {
                    setIsAbleDeleted(true);
                } else {
                    setIsAbleDeleted(false);
                }
                break;
            case bookDeviceStatus.unApprove:
                if (accountRoleAdmin) {
                    setIsAbleDeleted(true);
                } else {
                    setIsAbleDeleted(false);
                }
                break;
            case bookDeviceStatus.notApprove:
                if (accountRoleAdmin) {
                    setIsAbleDeleted(true);
                } else {
                    setIsAbleDeleted(false);
                }
                break;
        }
    };

    const acceptEdit = async () => {
        history.push(
            createUrlWithParam(URI_INTERNAL.BOOKDEVICE_EDIT_PATH, {
                id: idWantDeleteOrEdit
            })
        );
    };

    useEffect(() => {
        listDevice.refetch();
    }, [tagName]);

    useEffect(() => {
        if (
            listDevice.data &&
            listDevice.data.data &&
            listDevice.data.data.length > 0
        ) {
            setSuggestions(
                listDevice.data.data.map((x) => ({
                    id: x.id,
                    text: x.name + ' - ' + x.deviceCode
                }))
            );
        } else {
            setSuggestions([]);
        }
    }, [listDevice.data]);

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        setTags([...tags, tag]);
    };

    const handleInputChange = (text) => {
        setTagName(text);
    };

    // Khoa
    const lstDepartment =
        listDepartment && listDepartment.data
            ? listDepartment.data.map((x) => ({
                  label: x.Ten,
                  value: x.ID
              }))
            : [];

    const loadOptions = async (inputValue, loadedOptions, {page}) => {
        const filteredOptions = lstDepartment
            .filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            )
            .slice(0, 6);

        // Return all filtered options, no matter how many there are
        return {
            options: filteredOptions,
            hasMore: false,
            additional: {
                page: page + 1
            }
        };
    };

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        setDates({
            startDate: null,
            endDate: null
        });
        picker.element.val('');
    };

    const handleEventMouseEnter = (info) => {
        if (info.event.extendedProps.description) {
            tooltipInstance = new Tooltip(info.el, {
                title: info.event.extendedProps.description,
                html: true,
                placement: 'top',
                trigger: 'hover',
                container: 'body'
            });

            tooltipInstance.show();
        }
    };

    const handleEventMouseLeave = (info) => {
        if (tooltipInstance) {
            tooltipInstance.dispose();
            tooltipInstance = null;
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"> Danh sách đặt lịch</h3>
                        </div>
                        <div className="card-body">
                            <BrowserView>
                                <div className="d-flex justify-content-between">
                                    <form className="form-inline">
                                        <label
                                            htmlFor="exampleEmail2"
                                            className="mr-2"
                                            style={{
                                                marginBottom: accountRoleBooker
                                                    ? '0px'
                                                    : '10px'
                                            }}
                                        >
                                            Hiển thị :
                                        </label>
                                        <select
                                            className="form-control"
                                            style={{
                                                marginRight: '40px',
                                                marginBottom: accountRoleBooker
                                                    ? '0px'
                                                    : '10px'
                                            }}
                                            value={pageSize}
                                            onChange={(e) =>
                                                onChangeShowNumber(e)
                                            }
                                        >
                                            {objectShowNumber.map(
                                                (item, key) => (
                                                    <option
                                                        key={Number(key)}
                                                        value={item.value}
                                                    >
                                                        {item.text}
                                                    </option>
                                                )
                                            )}
                                        </select>

                                        <span
                                            style={{
                                                marginRight: '20px',
                                                marginBottom: accountRoleBooker
                                                    ? '0px'
                                                    : '10px'
                                            }}
                                        >
                                            <Form.Control
                                                placeholder="Tìm theo tên người đặt"
                                                type="text"
                                                name="title"
                                                value={bookerName}
                                                onChange={(x) => {
                                                    setBookerName(
                                                        x.target.value
                                                    );
                                                }}
                                            />
                                        </span>

                                        <span
                                            style={{
                                                marginRight: '40px',
                                                zIndex: '9992',
                                                minWidth: '200px',
                                                marginBottom: accountRoleBooker
                                                    ? '0px'
                                                    : '10px'
                                            }}
                                        >
                                            <AsyncPaginate
                                                name="departmentId"
                                                id="departmentId"
                                                placeholder="Chọn Khoa"
                                                value={departmentId.value}
                                                loadOptions={loadOptions}
                                                styles={styleSelectDefault}
                                                // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                isClearable
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: 'departmentId',
                                                            value: selectedOption
                                                        }
                                                    };
                                                    setDepartmentId(event);
                                                }}
                                                additional={{
                                                    page: 1
                                                }}
                                            />
                                        </span>

                                        <span
                                            style={{
                                                marginBottom: accountRoleBooker
                                                    ? '0px'
                                                    : '10px'
                                            }}
                                        >
                                            <ReactTags
                                                id="tagsForSearch"
                                                tags={tags}
                                                suggestions={suggestions}
                                                handleInputChange={
                                                    handleInputChange
                                                }
                                                handleDelete={handleDelete}
                                                handleAddition={handleAddition}
                                                // handleDrag={handleDrag}
                                                onChange
                                                editable={true}
                                                autocomplete={true}
                                                placeholder="Tìm thiết bị"
                                                // inputFieldPosition="bottom"
                                                // allowDragDrop={true}
                                            />
                                        </span>

                                        <span
                                            style={{
                                                marginBottom: accountRoleBooker
                                                    ? '0px'
                                                    : '10px'
                                            }}
                                        >
                                            <DateRangePicker
                                                onApply={handleApply}
                                                onCancel={handleCancel}
                                                initialSettings={{
                                                    // autoUpdateInput: false,
                                                    locale: {
                                                        format: 'DD/MM/YYYY hh:mm A',
                                                        cancelLabel: 'Xóa',
                                                        customRangeLabel:
                                                            'Tùy chọn',
                                                        applyLabel: 'Chọn'
                                                    },
                                                    ranges: rangeDateBooking,
                                                    timePicker: true,
                                                    // startDate: moment(
                                                    //     dates.startDate
                                                    // ).format('MM/DD/YYYY'),
                                                    // endDate: moment(
                                                    //     dates.endDate
                                                    // ).format('MM/DD/YYYY'),
                                                    autoUpdateInput: false
                                                }}
                                            >
                                                <input
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.value
                                                                .length === 0
                                                        ) {
                                                            setDates({
                                                                startDate: null,
                                                                endDate: null
                                                            });
                                                        }
                                                    }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Chọn khoảng thời gian"
                                                    style={{marginLeft: '40px'}}
                                                />
                                            </DateRangePicker>
                                        </span>

                                        <span
                                            style={{
                                                marginLeft: '40px',
                                                zIndex: '9991',
                                                marginBottom: accountRoleBooker
                                                    ? '0px'
                                                    : '10px'
                                            }}
                                        >
                                            <Select
                                                name="tus"
                                                id="bookStatus"
                                                placeholder="Trạng thái đặt thiết bị"
                                                value={objectBookDeviceStatus.find(
                                                    (x) => x.value == bookStatus
                                                )}
                                                onChange={(selectedOption) => {
                                                    // const event = {
                                                    //     target: {
                                                    //         name: 'bookStatus',
                                                    //         value: selectedOption
                                                    //     }
                                                    // };
                                                    setBookStatus(
                                                        selectedOption.value
                                                    );
                                                }}
                                                options={objectBookDeviceStatus}
                                                // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        </span>

                                        {accountRoleBooker ? (
                                            <div className="ml-auto">
                                                <NavLink
                                                    className="nav-link-reject nav-link"
                                                    to={
                                                        URI_INTERNAL.BOOKDEVICE_ADD_PATH
                                                    }
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn btn-info"
                                                    >
                                                        Thêm mới
                                                    </button>
                                                </NavLink>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </form>
                                </div>
                            </BrowserView>
                            <MobileView>
                                <div className="d-flex justify-content-between">
                                    <form className="form-inline">
                                        <label
                                            htmlFor="exampleEmail2"
                                            className="mr-2"
                                        >
                                            Hiển thị :
                                        </label>
                                        <select
                                            className="form-control"
                                            style={{marginTop: '20px'}}
                                            value={pageSize}
                                            onChange={(e) =>
                                                onChangeShowNumber(e)
                                            }
                                        >
                                            {objectShowNumber.map(
                                                (item, key) => (
                                                    <option
                                                        key={Number(key)}
                                                        value={item.value}
                                                    >
                                                        {item.text}
                                                    </option>
                                                )
                                            )}
                                        </select>

                                        <div
                                            style={{
                                                marginRight: 'auto',
                                                marginLeft: 'auto',
                                                marginTop: '20px'
                                            }}
                                        >
                                            <Form.Control
                                                placeholder="Tìm theo tên người đặt"
                                                type="text"
                                                name="title"
                                                value={bookerName}
                                                onChange={(x) => {
                                                    setBookerName(
                                                        x.target.value
                                                    );
                                                }}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                marginRight: 'auto',
                                                marginLeft: 'auto',
                                                zIndex: '9992',
                                                minWidth: '200px',
                                                marginTop: '20px'
                                            }}
                                        >
                                            <AsyncPaginate
                                                name="departmentId"
                                                id="departmentId"
                                                placeholder="Chọn Khoa"
                                                value={departmentId.value}
                                                loadOptions={loadOptions}
                                                styles={styleSelectDefault}
                                                // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                isClearable
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: 'departmentId',
                                                            value: selectedOption
                                                        }
                                                    };
                                                    setDepartmentId(event);
                                                }}
                                                additional={{
                                                    page: 1
                                                }}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                marginRight: 'auto',
                                                marginLeft: 'auto',
                                                marginTop: '20px'
                                            }}
                                        >
                                            <ReactTags
                                                id="tagsForSearch"
                                                tags={tags}
                                                suggestions={suggestions}
                                                handleInputChange={
                                                    handleInputChange
                                                }
                                                handleDelete={handleDelete}
                                                handleAddition={handleAddition}
                                                // handleDrag={handleDrag}
                                                onChange
                                                editable={true}
                                                autocomplete={true}
                                                placeholder="Tìm thiết bị"
                                                // inputFieldPosition="bottom"
                                                // allowDragDrop={true}
                                            />
                                        </div>

                                        <DateRangePicker
                                            onApply={handleApply}
                                            onCancel={handleCancel}
                                            initialSettings={{
                                                // autoUpdateInput: false,
                                                locale: {
                                                    format: 'DD/MM/YYYY hh:mm A',
                                                    cancelLabel: 'Xóa',
                                                    customRangeLabel:
                                                        'Tùy chọn',
                                                    applyLabel: 'Chọn'
                                                },
                                                ranges: rangeDateBooking,
                                                timePicker: true,
                                                // startDate: moment(
                                                //     dates.startDate
                                                // ).format('MM/DD/YYYY'),
                                                // endDate: moment(
                                                //     dates.endDate
                                                // ).format('MM/DD/YYYY'),
                                                autoUpdateInput: false
                                            }}
                                        >
                                            <input
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value
                                                            .length === 0
                                                    ) {
                                                        setDates({
                                                            startDate: null,
                                                            endDate: null
                                                        });
                                                    }
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder="Chọn khoảng thời gian"
                                                style={{
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    marginTop: '20px'
                                                }}
                                            />
                                        </DateRangePicker>

                                        <div
                                            style={{
                                                marginRight: 'auto',
                                                marginLeft: 'auto',
                                                zIndex: '9991'
                                            }}
                                        >
                                            <Select
                                                name="tus"
                                                id="bookStatus"
                                                placeholder="Trạng thái đặt thiết bị"
                                                value={objectBookDeviceStatus.find(
                                                    (x) => x.value == bookStatus
                                                )}
                                                onChange={(selectedOption) => {
                                                    setBookStatus(
                                                        selectedOption.value
                                                    );
                                                }}
                                                options={objectBookDeviceStatus}
                                                // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        </div>

                                        {accountRoleBooker ? (
                                            <div className="ml-auto">
                                                <NavLink
                                                    className="nav-link-reject nav-link"
                                                    to={
                                                        URI_INTERNAL.BOOKDEVICE_ADD_PATH
                                                    }
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn btn-info"
                                                    >
                                                        Thêm mới
                                                    </button>
                                                </NavLink>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </form>
                                </div>
                            </MobileView>
                            {isLoading ? (
                                <div>Đang tải...</div>
                            ) : isError ? (
                                <div>Có lỗi đã xảy ra: ${error.message}</div>
                            ) : (
                                <div
                                    id="example2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4"
                                >
                                    <FullCalendar
                                        plugins={[
                                            dayGridPlugin,
                                            timeGridPlugin,
                                            interactionPlugin
                                        ]}
                                        locale={viLocale}
                                        initialView={'dayGridMonth'}
                                        headerToolbar={{
                                            start: 'today prev,next', // will normally be on the left. if RTL, will be on the right
                                            center: 'title',
                                            end: 'dayGridMonth,timeGridWeek,timeGridDay' // will normally be on the right. if RTL, will be on the left
                                        }}
                                        events={events}
                                        eventClick={(e) => handleEventClick(e)}
                                        eventContent={renderEventContent}
                                        eventTextColor={'black'}
                                        height={'90vh'}
                                        viewDidMount={(info) => {
                                            setCurrentView(info.view.type);
                                        }}
                                        eventMouseEnter={handleEventMouseEnter}
                                        eventMouseLeave={handleEventMouseLeave}
                                    />
                                </div>
                            )}
                            <ConfirmModal
                                isOpen={openConfirmAcceptDeleteModal}
                                confirmType="acceptAndDelete"
                                handleAccept={() => acceptDelete()}
                                handleAcceptEdit={() => acceptEdit()}
                                handleCancel={() => cancelDelete()}
                                isAbleDeleted={isAbleDeleted}
                                content={confirmDelOrEdMessage}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ml-auto">
                <div className={'icheck-yellow'}>
                    <input
                        type="radio"
                        id={'waitingForApprove'}
                        name={'waitingForApprove'}
                        defaultChecked
                    />
                    <label htmlFor={'waitingForApprove'}>
                        {objectBookDeviceStatus.find(
                            (x) => x.value == bookDeviceStatus.waitingForApprove
                        )
                            ? objectBookDeviceStatus.find(
                                  (x) =>
                                      x.value ==
                                      bookDeviceStatus.waitingForApprove
                              ).label
                            : ''}
                    </label>
                </div>
                <div className={'icheck-blue'}>
                    <input
                        type="radio"
                        id={'approvedAndBooked'}
                        name={'approvedAndBooked'}
                        defaultChecked
                    />
                    <label htmlFor={'approvedAndBooked'}>
                        {objectBookDeviceStatus.find(
                            (x) => x.value == bookDeviceStatus.approvedAndBooked
                        )
                            ? objectBookDeviceStatus.find(
                                  (x) =>
                                      x.value ==
                                      bookDeviceStatus.approvedAndBooked
                              ).label
                            : ''}
                    </label>
                </div>
                <div className={'icheck-green'}>
                    <input
                        type="radio"
                        id={'finishBooking'}
                        name={'finishBooking'}
                        defaultChecked
                    />
                    <label htmlFor={'finishBooking'}>
                        {objectBookDeviceStatus.find(
                            (x) => x.value == bookDeviceStatus.finishBooking
                        )
                            ? objectBookDeviceStatus.find(
                                  (x) =>
                                      x.value == bookDeviceStatus.finishBooking
                              ).label
                            : ''}
                    </label>
                </div>
                <div className={'icheck-grey'}>
                    <input
                        type="radio"
                        id={'notApprove'}
                        name={'notApprove'}
                        defaultChecked
                    />
                    <label htmlFor={'notApprove'}>
                        {objectBookDeviceStatus.find(
                            (x) => x.value == bookDeviceStatus.notApprove
                        )
                            ? objectBookDeviceStatus.find(
                                  (x) => x.value == bookDeviceStatus.notApprove
                              ).label
                            : ''}
                    </label>
                </div>
                <div className={'icheck-red'}>
                    <input
                        type="radio"
                        id={'unApprove'}
                        name={'unApprove'}
                        defaultChecked
                    />
                    <label htmlFor={'finishBooking'}>
                        {objectBookDeviceStatus.find(
                            (x) => x.value == bookDeviceStatus.unApprove
                        )
                            ? objectBookDeviceStatus.find(
                                  (x) => x.value == bookDeviceStatus.unApprove
                              ).label
                            : ''}
                    </label>
                </div>
            </div>
        </div>
    );
};

export default BookDevice;
