import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import { useQuery } from 'react-query';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import {loginUser} from '@store/reducers/auth';
import * as Yup from 'yup';
// import * as AuthService from '../../services/auth';
import Button from '../../components/button/Button';
import {URI_INTERNAL} from '../../utils/pathLocations';
import { USERNAMEANDPHANMEMID_API } from '@app/utils/apiUrl';
import { Get, GetLogin } from '@app/utils/httpProvider';

const Login = () => {
    const [isAuthLoading, setAuthLoading] = useState(false);
    const dispatch = useDispatch();
    const errorLogin = useSelector((state) => state.auth.errorLogin);
    const history = useHistory();
    const location = useLocation();
    const [t] = useTranslation();

    const [userName, setUserName] = useState('');

    const paramUrlOutSide = new URLSearchParams(location.search.substring(1));
    // if (paramUrlOutSide.get('_UserName') != null) {
    //     const nameOfUser = paramUrlOutSide.get('_UserName');
    //     setUserName(nameOfUser);
    // }

    // const itemToCheck = useQuery([paramUrlOutSide.get('_UserName'), paramUrlOutSide.get('_PhanMemID')], () =>
    //     Get(USERNAMEANDPHANMEMID_API.GET_ITEM, null, {
    //         UserName: paramUrlOutSide.get('_UserName'),
    //         PhanMemID: paramUrlOutSide.get('_PhanMemID')
    //     })
    // );

    // if (itemToCheck && itemToCheck.data) {
    //     localStorage.setItem("_UserName", itemToCheck.data.username)
    //     localStorage.setItem("_PhanMemID", itemToCheck.data.phanMemID)
    // }

    useEffect(() => {
        const paramUrl = new URLSearchParams(location.search);
        const nameOfUser = paramUrl.get('_UserName');
        if (nameOfUser !== null) {
            setUserName(nameOfUser);
        }
    }, [location.search]);

    useEffect(() => {
        if (userName && paramUrlOutSide.get('_PhanMemID')) {
            // setAuthLoading(true);
            const model = {
                username: userName + "," + paramUrlOutSide.get('_PhanMemID'),
                password: 'Admin$$1234'
            };
            // setAuthLoading(false);
            dispatch(loginUser(model));
            // history.go(0);
            // window.location.reload(true);
            // window.location.reload();
        }
    }, [userName]);

    const login = async (username, password) => {
        try {
            setAuthLoading(true);
            const model = {
                username: username,
                password: password
            };
            setAuthLoading(false);
            await dispatch(loginUser(model));
            window.location.reload();
        } catch (error) {
            setAuthLoading(false);
            toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
        }
    };

    const printFormError = (formik, key) => {
        if (formik.touched[key] && formik.errors[key]) {
            return <div style={{color: 'red'}}>{formik.errors[key]}</div>;
        }
        return null;
    };
    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string()
                // .email('Invalid email address')
                .required('Tài khoản không được trống'),
            password: Yup.string()
                .min(6, 'Độ dài tối thiểu 6 ký tự')
                .max(30, 'Độ dài không vượt quá 30 ký tự')
                .required('Mật khẩu không được trống')
        }),
        onSubmit: (values) => {
            login(values.username, values.password);
        }
    });
    const printLoginError = (error) => {
        if (error !== null) {
            return (
                <p style={{color: 'red'}} className="login-box-msg">
                    {error}
                </p>
            );
        }
        return null;
    };
    document.getElementById('root').classList = 'hold-transition login-page';

    return (
        <>
            {paramUrlOutSide.get('_UserName') ? 
                errorLogin ? <div style={{ fontSize: "40px", fontWeight: "800", color: "blue" }}>{errorLogin}</div> : <></>
             : (
                // <div className="login-box">
                //     <div className="card card-outline card-primary">
                //         <div className="card-header text-center">
                //             <Link to="/" className="h1">
                //                 <b>Quản trị thiết bị</b>
                //             </Link>
                //         </div>
                //         <div className="card-body">
                //             <p className="login-box-msg">
                //                 {t('login.label.signIn')}
                //             </p>
                //             {printLoginError(errorLogin)}
                //             <form onSubmit={formik.handleSubmit}>
                //                 <div className="mb-3">
                //                     <div className="input-group">
                //                         <input
                //                             type="text"
                //                             className="form-control"
                //                             placeholder="Tài khoản"
                //                             {...formik.getFieldProps(
                //                                 'username'
                //                             )}
                //                         />
                //                         <div className="input-group-append">
                //                             <div className="input-group-text">
                //                                 <span className="fas fa-envelope" />
                //                             </div>
                //                         </div>
                //                     </div>
                //                     {printFormError(formik, 'username')}
                //                 </div>
                //                 <div className="mb-3">
                //                     <div className="input-group">
                //                         <input
                //                             type="password"
                //                             className="form-control"
                //                             placeholder="Password"
                //                             {...formik.getFieldProps(
                //                                 'password'
                //                             )}
                //                         />
                //                         <div className="input-group-append">
                //                             <div className="input-group-text">
                //                                 <span className="fas fa-lock" />
                //                             </div>
                //                         </div>
                //                     </div>
                //                     {printFormError(formik, 'password')}
                //                 </div>

                //                 <div className="row">
                //                     <div className="col-8">
                //                         <div className="icheck-primary">
                //                             <input
                //                                 type="checkbox"
                //                                 id="remember"
                //                             />
                //                             <label htmlFor="remember">
                //                                 Ghi nhớ
                //                             </label>
                //                         </div>
                //                     </div>
                //                     <div className="col-4">
                //                         <Button
                //                             block
                //                             type="submit"
                //                             isLoading={isAuthLoading}
                //                         >
                //                             {t('login.button.signIn.label')}
                //                         </Button>
                //                     </div>
                //                 </div>
                //             </form>
                //             <p className="mb-1">
                //                 <Link to={URI_INTERNAL.FORGOT_PASSWORD_PATH}>
                //                     Quên mật khẩu
                //                 </Link>
                //             </p>
                //         </div>
                //     </div>
                // </div>
                <></>
            )}
        </>
    );
};

export default Login;
