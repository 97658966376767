import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import Table from 'react-bootstrap/Table';
import * as moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { getCountPostByConditionAction } from '../../store/reducers/reportPost';
import { objectReportPost, reportPost, rangeDate, objectDataTypeCategory, objectStatusPost } from '../../utils/define';
import { dateTimeToStringRequest, exportExcel } from '../../utils/helper';

const ReportPost = () => {
    const dispatch = useDispatch();
    const listCountPostByCondition = useSelector(
        (state) => state.reportPost.listCountPostByCondition
    );
    const timeCurentDay = moment().subtract(6, 'days');
    timeCurentDay.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const [valueReportType, setValueReportType] = useState(
        objectReportPost[0].value
    );

    const [dates, setDates] = useState({
        startDate: timeCurentDay,
        endDate: moment()
    });
    const [LabelsChart, setLabelsChart] = useState([]);
    const [DataChart, setDataChart] = useState([]);
    const [Total, setToTal] = useState([]);

    useEffect(() => {
        const model = {
            ReportType: valueReportType,
            fromDate: dates.startDate
                ? dateTimeToStringRequest(dates.startDate.toDate())
                : '',
            toDate: dates.endDate
                ? dateTimeToStringRequest(dates.endDate.toDate())
                : ''
        };
        dispatch(getCountPostByConditionAction(model));
    }, [dates, valueReportType]);

    useEffect(() => {
        let labelsChart = [];
        let dataChart = [];
        let total = 0;
        if (listCountPostByCondition && listCountPostByCondition.length > 0) {
            listCountPostByCondition.forEach((item) => {
                if (
                    valueReportType === reportPost.ByCreatedDate ||
                    valueReportType === reportPost.ByPublicDate
                ) {
                    labelsChart = labelsChart.concat(
                        moment(item.title).format('DD/MM/YYYY')
                    );
                } else {
                    labelsChart = labelsChart.concat(item.title);
                }
                dataChart = dataChart.concat(item.total);
                total += item.total;
            });
        }
        setDataChart(dataChart);
        setLabelsChart(labelsChart);
        setToTal(total);
    }, [listCountPostByCondition]);

    const getTitle = () => {
        switch (valueReportType) {
            case reportPost.Author:
                return 'Người tạo';
            case reportPost.ByCreatedDate:
                return 'Ngày tạo';
            case reportPost.DataType:
                return 'Loại';
            case reportPost.ByPublicDate:
                return 'Ngày xuất bản';
            case reportPost.Category:
                return 'Danh mục';
            case reportPost.ByStatus:
                return 'Trạng thái';
            default:
                break;
        }
        return null;
    };

    const onChangeRepostType = (e) => {
        setValueReportType(e.target.value);
    };

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
    };
    const renderSwitchTitle = (title, param) => {
        switch (param) {
            case reportPost.ByCreatedDate:
            case reportPost.ByPublicDate:
                return moment(
                    title
                ).format(
                    'DD/MM/YYYY'
                );
            case reportPost.ByStatus: {
                const itemType = objectStatusPost.find((x) => x.value === title);
                if (itemType) {
                    return itemType.label;
                }
                return null;
            }
            case reportPost.DataType: {
                const itemType = objectDataTypeCategory.find((x) => x.value === title);
                if (itemType) {
                    return itemType.label;
                }
                return null;
            }
            default:
                return title;
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title"> Thống kê bài viết</h2>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label htmlFor="exampleEmail2" className>
                                        Báo cáo theo :
                                    </label>
                                    <select
                                        className="form-control"
                                        value={valueReportType}
                                        onChange={(e) => onChangeRepostType(e)}
                                    >
                                        {objectReportPost.map((item, key) => (
                                            <option
                                                key={Number(key)}
                                                value={item.value}
                                            >
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3 mr-2"
                                    >
                                        Thời gian đăng tin :
                                    </label>
                                    <DateRangePicker
                                        onApply={handleApply}
                                        onCancel={handleCancel}
                                        initialSettings={{
                                            autoUpdateInput: false,
                                            locale: {
                                                cancelLabel: 'Clear'
                                            },
                                            ranges: rangeDate,
                                            startDate: moment(
                                                dates.startDate
                                            ).format('MM/DD/YYYY'),
                                            endDate: moment(
                                                dates.endDate
                                            ).format('MM/DD/YYYY')
                                        }}
                                    >
                                        <input
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length === 0
                                                ) {
                                                    setDates({
                                                        startDate: null,
                                                        endDate: null
                                                    });
                                                }
                                            }}
                                            defaultValue={`${moment(
                                                dates.startDate
                                            ).format('DD/MM/YYYY')} - ${moment(
                                                dates.endDate
                                            ).format('DD/MM/YYYY')}`}
                                            type="text"
                                            className="form-control"
                                        />
                                    </DateRangePicker>
                                </form>
                                <NavLink
                                    className="nav-link-reject nav-link"
                                    to
                                    onClick={() => exportExcel(`baocao_${valueReportType.toLowerCase()}_${Date.now()}`)}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        Xuất file Excel
                                    </button>
                                </NavLink>
                            </div>
                            <div
                                id="example2_wrapper"
                                className="dataTables_wrapper dt-bootstrap4"
                            >
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Bar
                                            data={{
                                                labels: LabelsChart,
                                                datasets: [
                                                    {
                                                        backgroundColor: [
                                                            '#DB4437'
                                                        ],
                                                        data: DataChart,
                                                        label: 'Tống số bài viết',
                                                    }
                                                ]
                                            }}
                                            options={{
                                                legend: { display: false },
                                                title: {
                                                    display: true,
                                                    text: 'Predicted world population (millions) in 2050'
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Table
                                            id="table-to-xls"
                                            striped
                                            bordered
                                            hover
                                        >
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting">
                                                        {getTitle()}
                                                    </th>
                                                    <th className="sorting">
                                                        Tổng số
                                                    </th>
                                                </tr>
                                            </thead>
                                            {listCountPostByCondition.length >
                                                0 ? (
                                                <tbody>
                                                    {listCountPostByCondition.map(
                                                        (item, index) => (
                                                            <tr
                                                                key={Number(
                                                                    index
                                                                )}
                                                                className="odd"
                                                            >
                                                                <td>
                                                                    {
                                                                        renderSwitchTitle(item.title, valueReportType)
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.total}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                    {listCountPostByCondition.length >
                                                        0 ? (
                                                        <tr>
                                                            <td>Tổng số</td>
                                                            <td>{Total}</td>
                                                        </tr>
                                                    ) : null}
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr className="txt-center">
                                                        <td colSpan="12">
                                                            Không có dữ liệu
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ReportPost;
