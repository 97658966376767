import React from 'react';
import PropTypes from 'prop-types';
import {css} from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const ReactSpinner = (props) => {
    const {loadings} = props;

    return (
        <ClipLoader
            color="#ffffff"
            loading={loadings}
            css={override}
            size={150}
        />
    );
};

ReactSpinner.propTypes = {
    loadings: PropTypes.bool
};

ReactSpinner.defaultProps = {
    loadings: false
};

export default ReactSpinner;
