import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import PreviewFileUpload from '@app/components/input-file/PreviewFileUpload';
import {acceptImage} from '@app/utils/define';
import path from 'path';
import mime from 'mime-types';

const InputFile = (props) => {
    const {values, multiple, accept, enableReinitialize, onChange, labelImage} = props;
    const [files, setFiles] = useState([]);
    const inputUpload = useRef();
    useEffect(() => {
        if (
            (values && values.length > 0 && files.length === 0) ||
            enableReinitialize
        ) {
            const listFile = [];
            for (let index = 0; index < values.length; index++) {
                const fileUrl = values[index];
                const filename = path.basename(fileUrl);
                listFile.push({
                    name: filename,
                    url: fileUrl,
                    type: mime.lookup(filename)
                });
            }
            setFiles(listFile);
        }
    }, [values]);

    useEffect(()=>{
        return(()=>{
            
        })
    })

    const handleInputChange = (e) => {
        const listFile = multiple ? [...files] : [];
        for (let i = 0; i < e.target.files.length; i++) {
            listFile.push(e.target.files[i]);
        }
        inputUpload.current.value = '';
        setFiles(listFile);
    };

    //
    const handleUploaded = (file, index) => {
        files[index] = file;
        // setFiles(files);
        onChange(files);
    };

    const handleRemoveFile = (index) => {
        const listFile = files.filter((x) => x.url !== files[index].url);
        setFiles(listFile);
        onChange(listFile);
    };
    return (
        <>
            <span
                className="btn btn-success col fileinput-button dz-clickable col-sm-3"
                onClick={() => inputUpload.current.click()}
                role="presentation"
                style={{display: multiple ? 'block' : 'none'}}
            >
                <i className="fas fa-plus" />
                <span> Chọn file</span>
            </span>

            <Form.Control
                type="file"
                multiple={multiple}
                hidden
                accept={multiple ? accept : acceptImage}
                ref={inputUpload}
                onChange={(e) => handleInputChange(e)}
            />
            {!multiple ? (
                <div className="img_add">
                    <div className="rowImage">
                        {files[0] && files[0].url !== '' && (
                            <PreviewFileUpload
                                fileView={files[0]}
                                isThumbnail="true"
                                uploaded={(data) => handleUploaded(data, 0)}
                                actionRemove={() => handleRemoveFile(0)}
                            />
                        )}
                    </div>
                    <div
                        className="img_add_placeholder rounded"
                        role="presentation"
                        onClick={() => inputUpload.current.click()}
                    >
                        <span>{labelImage ? labelImage : 'Chọn ảnh upload'}</span>
                    </div>
                </div>
            ) : (
                files.map((file, index) => (
                    <div
                        className="row m-1"
                        key={file.url ? file.url : file.lastModified}
                    >
                        <PreviewFileUpload
                            fileView={file}
                            uploaded={(data) => handleUploaded(data, index)}
                            actionRemove={() => handleRemoveFile(index)}
                        />
                    </div>
                ))
            )}
        </>
    );
};

InputFile.propTypes = {
    multiple: PropTypes.bool,
    enableReinitialize: PropTypes.bool,
    accept: PropTypes.string,
    onChange: PropTypes.func,
    values: PropTypes.arrayOf(PropTypes.string)
};

InputFile.defaultProps = {
    multiple: false,
    enableReinitialize: false,
    accept: 'application/*,image/png,image/jpg,image/jpeg',
    onChange: () => {},
    values: []
};

export default InputFile;
