import React, {useRef, useState} from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup, Image} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import Editor from '@app/components/editor/Editor';
import {Post, Get} from '@app/utils/httpProvider';
import {
    BaseGatewayImage,
    FILE_MANAGER_API,
    DEVICEMAINTENANCE_API,
    BOOKDEVICE_API,
    DEVICE_API,
    ACCOUNT_API
} from '@app/utils/apiUrl';
import {useMutation, useQuery} from 'react-query';
import SelectTree from '@app/components/select-tree/SelectTree';
import {AsyncPaginate} from 'react-select-async-paginate';
import {
    messageError,
    messageToast,
    acceptImage,
    objectDataTypeCategory,
    objectDataTypeVideo,
    styleSelectDefault,
    styleSelectError,
    status200,
    dataTypeCategory,
    DATE_TIME_REACT_FORMAT,
    deviceStatus
} from '../../utils/define';
import Select from 'react-select';
import {
    dateTimeToUTCRequest,
    getInfomationAccountFromToken,
    getToken,
    isValidUrl
} from '@app/utils/helper';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import noImg from '../../../src/assets/no-image.jpg';

import {registerLocale, setDefaultLocale} from 'react-datepicker';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);

// const schema = yup.object().shape({
//     // name: yup.string().required(messageError.emptyMessage),
//     // plantCategoryId: yup.object().shape({
//     //     label: yup.string(),
//     //     value: yup.string()
//     // }).nullable()
//     //     .required(messageError.emptyMessage)
//     // weather: yup.string().required(messageError.emptyMessage),
//     // dataType: yup.object().required(messageError.emptyMessage)
//     // parentId: yup.string().required(messageError.emptyMessage)
// });

const DeviceMaintenanceAdd = (props) => {
    const history = useHistory();
    const inputUpload = useRef();
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));
    // const bookDeviceId = paramUrl.get('bookDeviceId');
    // const accountManageDeviceId = paramUrl.get('accountManageDeviceId');
    let deviceId = props.deviceId
        ? props.deviceId
        : paramUrl.get('deviceId')
        ? paramUrl.get('deviceId')
        : '';
    let accountBookerId = props.accountBookerId;
    let accountManageDeviceId = props.accountManageDeviceId;
    let bookDeviceId = props.bookDeviceId;
    // let deviceId = props.deviceId;

    const inforAccount = getInfomationAccountFromToken();

    const bookDeviceItem = useQuery([bookDeviceId], () => {
        return bookDeviceId ? Get(BOOKDEVICE_API.GET_ITEM, bookDeviceId) : null;
    });

    const deviceItem = useQuery([deviceId], () => {
        return deviceId ? Get(DEVICE_API.GET_ITEM, deviceId) : null;
    });

    console.log('deviceItem', deviceItem);

    // const {isLoading, isError, error, data} = useQuery(['cateData'], () =>
    //     Get(CATEGORY_API.GET_ALL_CATEGORY, null)
    // );

    const addFunc = useMutation((value) =>
        Post(DEVICEMAINTENANCE_API.ADD_ITEM, null, value, false)
    );

    const sendFunc = useMutation((value) =>
        Post(DEVICEMAINTENANCE_API.SEND_ITEM, null, value, false)
    );

    const handleCreate = async (values) => {
        const model = {...values};
        if (deviceId && accountBookerId) {
            model.deviceId = deviceId;
            model.accountId = accountBookerId;
            sendFunc.mutate(model, {
                onSuccess: (res) => {
                    if (res.status !== status200) {
                        toast.error(messageToast.messageToastCreateError);
                    } else {
                        toast.success(
                            messageToast.messageToastSendEmailSuccess
                        );
                        history.goBack();
                    }
                },
                onError: (res) => {
                    toast.error(
                        res.message || 'Đã có lỗi xảy ra, vui lòng thử lại !'
                    );
                }
            });
        } else {
            model.deviceId = deviceId ? deviceId :
                values.deviceId === '' ||
                values.deviceId === 'null' ||
                values.deviceId === undefined
                    ? ''
                    : values.deviceId.value;
            model.accountId =
                values.accountId === '' ||
                values.accountId === 'null' ||
                values.accountId === undefined
                    ? ''
                    : values.accountId.value;

            addFunc.mutate(model, {
                onSuccess: (res) => {
                    if (res.status !== status200) {
                        toast.error(messageToast.messageToastCreateError);
                    } else {
                        toast.success(messageToast.messageToastCreateSuccess);
                        history.goBack();
                    }
                },
                onError: (res) => {
                    toast.error(
                        res.message || 'Đã có lỗi xảy ra, vui lòng thử lại !'
                    );
                }
            });
        }
    };

    const loadOptionsAccount = async (inputValue, loadedOptions, {page}) => {
        const listAccount = await Get(ACCOUNT_API.GET_PAGING_ACCOUNT, null, {
            PageIndex: 1,
            PageSize: 8,
            Keyword: inputValue
        });

        const lstAccount =
            listAccount && listAccount.data
                ? listAccount.data.map((x) => ({
                      label: x.fullName + ' - ' + x.username,
                      value: x.id
                  }))
                : [];

        // const filteredOptions = lstDevice.filter((option) =>
        //     option.label.toLowerCase()
        // );

        // console.log('filteredOptions', filteredOptions);

        // Return all filtered options, no matter how many there are
        return {
            options: lstAccount,
            hasMore: false,
            additional: {
                page: page + 1
            }
        };
    };

    const loadOptions = async (inputValue, loadedOptions, {page}) => {
        // setDeviceName(inputValue);
        // Fetch data from the API based on the inputValue
        const listDevice = await Get(DEVICE_API.GET_PAGING, null, {
            PageIndex: 1,
            PageSize: 8,
            Keyword: inputValue,
            DeviceStatus: deviceStatus.available
        });

        const lstDevice =
            listDevice && listDevice.data
                ? listDevice.data.map((x) => {
                      //   var departmentName =
                      //       lstDepartment &&
                      //       lstDepartment.find((i) => i.value == x.departmentId)
                      //           ? lstDepartment.find(
                      //                 (i) => i.value == x.departmentId
                      //             ).label
                      //           : '';
                      let labelName = '';
                      if (x.name && x.name.includes(x.modelDevice)) {
                          labelName = x.name;
                      } else {
                          labelName =
                              x.name +
                              ' - ' +
                              x.deviceCode +
                              ' - ' +
                              x.modelDevice +
                              ' - ' +
                              x.firmOfDevice +
                              ' - ' +
                              x.seriDevice;
                      }
                      return {
                          label: labelName,
                          value: x.id
                      };
                  })
                : [];

        // const filteredOptions = lstDevice.filter((option) =>
        //     option.label.toLowerCase()
        // );

        // console.log('filteredOptions', filteredOptions);

        // Return all filtered options, no matter how many there are
        return {
            options: lstDevice,
            hasMore: false,
            additional: {
                page: page + 1
            }
        };
    };

    // const uploadFile = async ({ fileUpload }) => {
    //     const token = getToken();
    //     const config = {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             Authorization: `bearer ${token}`
    //         }
    //     };
    //     const data = new FormData();
    //     data.append('file', fileUpload);
    //     try {
    //         const res = await axios.post(FILE_MANAGER_API.UPLOAD, data, config);
    //         return res;
    //     } catch (error) {
    //         const errorMessage = error.response.data.message;
    //         return errorMessage;
    //     }
    // };

    // const handleUploadFile = async (e, listCurrent, functionSetField) => {
    //     if (e && e.target && e.target.files && e.target.files.length > 0) {
    //         const result = [];
    //         for (let i = 0; i < e.target.files.length; i++) {
    //             if (e.target.files[i].size < 1024000 * 10) {
    //                 const fileUpload = e.target.files[i];
    //                 result.push((uploadFile({ fileUpload })));
    //             } else {
    //                 toast.error("Kích thước ảnh quá lớn, hãy thử lại với ảnh nhỏ hơn 10mb");
    //                 continue;
    //             }
    //         }
    //         const responses = await Promise.all(result);
    //         // const lstCurentImage = JSON.parse(JSON.stringify(lstImages))
    //         const lstCurentImage = listCurrent;
    //         responses.forEach((item) => {
    //             const fileUpdated = item.data[0];
    //             const modelImage = {
    //                 "id": uuidv4(),
    //                 "imagePath": fileUpdated.filePath,
    //                 "note": "",
    //                 "order": "1"
    //             }
    //             lstCurentImage.push(modelImage);
    //         })
    //         // setIsShowErrorLstImage(false);
    //         // setLstImage(lstCurentImage);
    //         functionSetField(
    //             'listImageFromik',
    //             lstCurentImage,
    //             true
    //         );
    //     }
    // };

    // map data (kiểu dữ liệu chung sang định danh lstFertilizerdata)
    // const { data: lstFertilizer } = useQuery("lstFertilizerdata",
    //     () =>
    //         Get(FERTILIZER_API.GET_PAGING, null, {
    //             PageIndex: 1,
    //             PageSize: 1000
    //         })
    // );
    // const [selectedOption, setSelectedOption] = useState([]);

    // const handleChangeFerlitier = (lstselectoption) => {
    //     setSelectedOption(lstselectoption);
    // };

    // var options = [];
    // if (lstFertilizer && lstFertilizer.data && lstFertilizer.data.length > 0) {
    //     options = lstFertilizer.data.map((x) => (
    //         {
    //             value: x.id,
    //             label: x.name,
    //             image: x.imagePath
    //         }
    //     ))
    // }

    const isLoading = deviceItem.isLoading ? true : false;
    const isError = deviceItem.isError ? true : false;
    const error = deviceItem.isError ? deviceItem.error : null;

    return (
        <Card>
            <Card.Header as="h5">Thêm sự cố và cách sửa chữa</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Đang tải...</div>
                ) : isError ? (
                    <div>Có lỗi xảy ra: ${error.message}</div>
                ) : (
                    <Formik
                        // validationSchema={schema}
                        onSubmit={(values) => handleCreate(values)}
                        initialValues={{
                            maintenanceOfProblem: '',
                            descriptionOfProblem: '',
                            deviceId: deviceId ? deviceId : '',
                            device: deviceItem.data ? deviceItem.data : null,
                            accountId: '',
                            imagePath: '',
                            imageThumbnail: '',
                            problemArea: '',
                            problemTime: new Date(),
                            endBookTime:
                                bookDeviceItem.data &&
                                bookDeviceItem.data.endBookTime
                                    ? new Date(bookDeviceItem.data.endBookTime)
                                    : new Date()
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {deviceId && accountBookerId ? (
                                    <></>
                                ) : (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label className="col-sm-2 col-form-label">
                                            Ảnh đại diện
                                        </Form.Label>
                                        <Col sm="10">
                                            <InputFile
                                                enableReinitialize={true}
                                                values={
                                                    values.imageThumbnail
                                                        ? [
                                                              isValidUrl(
                                                                  values.imageThumbnail
                                                              )
                                                                  ? values.imageThumbnail
                                                                  : BaseGatewayImage +
                                                                    values.imageThumbnail
                                                          ]
                                                        : []
                                                }
                                                name="imageThumbnail"
                                                accept={acceptImage}
                                                onChange={(files) => {
                                                    // console.log("files", files);
                                                    if (files.length > 0) {
                                                        setFieldValue(
                                                            'imagePath',
                                                            files[0].filePath,
                                                            true
                                                        );
                                                        setFieldValue(
                                                            'imageThumbnail',
                                                            files[0].thumbPath,
                                                            true
                                                        );
                                                    }
                                                }}
                                            />

                                            {/* <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.imageThumbnail}
                                    </div> */}
                                        </Col>
                                    </Form.Group>
                                )}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Địa điểm xảy ra sự cố
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Địa điểm xảy ra sự cố"
                                            type="text"
                                            name="problemArea"
                                            value={values.problemArea}
                                            onChange={handleChange}
                                            // isInvalid={!!errors.problemArea}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả sự cố *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="descriptionOfProblem"
                                            value={values.descriptionOfProblem}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'descriptionOfProblem',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Hành động xử lý tức thì
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="immediateActOfMaintenance"
                                            value={
                                                values.immediateActOfMaintenance
                                            }
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'immediateActOfMaintenance',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                {deviceId && accountBookerId ? (
                                    <></>
                                ) : (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label className="col-sm-2 col-form-label">
                                            Biện pháp khắc phục và phòng ngừa
                                        </Form.Label>
                                        <Col sm="10">
                                            <Editor
                                                name="maintenanceOfProblem"
                                                value={
                                                    values.maintenanceOfProblem
                                                }
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        'maintenanceOfProblem',
                                                        value
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                )}
                                {values.device ? (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label className="col-sm-2 col-form-label">
                                            Thiết bị
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                placeholder="Thiết bị"
                                                type="text"
                                                name="deviceName"
                                                value={
                                                    values.device
                                                        ? (values.device.name &&
                                                          values.device.name.includes(
                                                              values.device
                                                                  .modelDevice
                                                          )
                                                              ? values.device
                                                                    .name
                                                              : values.device
                                                                    .name +
                                                                ' - ' +
                                                                values.device
                                                                    .modelDevice +
                                                                ' - ' +
                                                                values.device
                                                                    .firmOfDevice +
                                                                ' - ' +
                                                                values.device
                                                                    .seriDevice) +
                                                          ' - ' +
                                                          values.device
                                                              .deviceCode
                                                        : ''
                                                }
                                                disabled={true}
                                            />
                                        </Col>
                                    </Form.Group>
                                ) : (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label className="col-sm-2 col-form-label">
                                            Trang thiết bị
                                        </Form.Label>
                                        <Col sm="10">
                                            <AsyncPaginate
                                                name="deviceId"
                                                id="deviceId"
                                                placeholder="Chọn trang thiết bị"
                                                value={values.deviceId}
                                                loadOptions={loadOptions}
                                                isClearable
                                                styles={
                                                    errors.deviceId !==
                                                    undefined
                                                        ? styleSelectError
                                                        : styleSelectDefault
                                                }
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: 'deviceId',
                                                            value: selectedOption
                                                        }
                                                    };
                                                    handleChange(event);
                                                }}
                                                additional={{
                                                    page: 1
                                                }}
                                            />
                                            <div
                                                style={{
                                                    display: 'block',
                                                    color: '#dc3545'
                                                }}
                                                className="invalid-feedback"
                                            >
                                                {errors.deviceId}
                                            </div>
                                        </Col>
                                    </Form.Group>
                                )}
                                {accountBookerId ? (
                                    <></>
                                ) : (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label className="col-sm-2 col-form-label">
                                            Người đặt lịch
                                        </Form.Label>
                                        <Col sm="10">
                                            <AsyncPaginate
                                                name="accountId"
                                                id="accountId"
                                                placeholder="Chọn người đặt lịch"
                                                value={values.accountId}
                                                loadOptions={loadOptionsAccount}
                                                isClearable
                                                styles={
                                                    errors.accountId !==
                                                    undefined
                                                        ? styleSelectError
                                                        : styleSelectDefault
                                                }
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: 'accountId',
                                                            value: selectedOption
                                                        }
                                                    };
                                                    handleChange(event);
                                                }}
                                                additional={{
                                                    page: 1
                                                }}
                                            />
                                            {/* <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.accountId}
                                        </div> */}
                                        </Col>
                                    </Form.Group>
                                )}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thời điểm phát sinh sự cố
                                    </Form.Label>
                                    <Col sm="10">
                                        {deviceId && accountBookerId ? (
                                            <DatePicker
                                                locale="vi"
                                                minDate={new Date()}
                                                maxDate={values.endBookTime}
                                                selected={values.problemTime}
                                                onChange={(date) => {
                                                    setFieldValue(
                                                        'problemTime',
                                                        date,
                                                        true
                                                    );
                                                }}
                                                timeInputLabel="Thời gian:"
                                                dateFormat={
                                                    DATE_TIME_REACT_FORMAT
                                                }
                                                showTimeInput
                                                className="form-control"
                                            />
                                        ) : (
                                            <DatePicker
                                                locale="vi"
                                                selected={values.problemTime}
                                                onChange={(date) => {
                                                    setFieldValue(
                                                        'problemTime',
                                                        date,
                                                        true
                                                    );
                                                }}
                                                timeInputLabel="Thời gian:"
                                                dateFormat={
                                                    DATE_TIME_REACT_FORMAT
                                                }
                                                showTimeInput
                                                className="form-control"
                                            />
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{span: 10, offset: 2}}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                {deviceId && accountBookerId
                                                    ? 'Gửi email'
                                                    : 'Thêm'}
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() => history.goBack()}
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
                {/* <ReactSpinner loadings={isLoading} /> */}
            </Card.Body>
        </Card>
    );
};

export default DeviceMaintenanceAdd;
