const LOGIN_PATH = '/login';
const FORGOT_PASSWORD_PATH = '/forgot-pass';
const RECOVER_PASSWORD_PATH = '/recover-pass';
const ACCOUNT_PATH = '/account';
const ACCOUNT_PATH_ADD = `${ACCOUNT_PATH}/add`;
const ACCOUNT_PATH_EDIT = `${ACCOUNT_PATH}/edit`;
const LOGIN_HISTORY_PATH = '/login-history';
const STATISTIC_HISTORY_PATH = '/statistic-login-history';

const EMAILCONFIG_PATH = '/emailconfig';
const EMAILCONFIG_CREATE_PATH = '/emailconfig/create';
const EMAILCONFIG_EDIT_PATH = '/emailconfig/edit';

const LOG_PATH = '/log';

const REPORT_POST_PATH = '/report-post';
const REPORT_POST_SYNTHETIC_PATH = '/report-post-synthentic';
const REPORT_POST_EXCEL_PATH = '/report-post-excel';
const REPORT_POST_EXCEL_QUANTITY_CREATE_BY =
    '/report-post-excel-quantity-createby';
const REPORT_POST_EXCEL_CREATE_BY = '/report-post-excel-createby';

const REPORT_POST_WEBANALYSIS_PATH = '/report-post-web-analysis';
const REPORT_POST_BY_DATETYPE = '/report-post-by-datetype';
const REPORT_POST_VIEW_DETAIL = '/report-post-view-detail';
const REPORT_POST_PUBLISHTIME_DATATYPE_PATH =
    '/report-post-publish-time-data-type';
const REPORT_ADVERTISING_ANALYSIS_PATH =
    '/advertising-analysis';
const REPORT_COUNT_VISIT_DETAIL =
    '/report-count-visit-detail';

const DEVICE_PATH = '/device';
const DEVICE_EDIT_PATH = `${DEVICE_PATH}/edit`;
const DEVICE_ADD_PATH = `${DEVICE_PATH}/add`;

const BOOKDEVICE_PATH = '/bookDevice';
const BOOKDEVICE_EDIT_PATH = `${BOOKDEVICE_PATH}/edit`;
const BOOKDEVICE_ADD_PATH = `${BOOKDEVICE_PATH}/add`;
const BOOKDEVICEFORAPPROVER_PATH = '/bookDeviceForApprover';

const DEVICEMAINTENANCE_PATH = '/deviceMaintenance';
const DEVICEMAINTENANCE_EDIT_PATH = `${DEVICEMAINTENANCE_PATH}/edit`;
const DEVICEMAINTENANCE_ADD_PATH = `${DEVICEMAINTENANCE_PATH}/add`;

export const URI_INTERNAL = {
    REPORT_ADVERTISING_ANALYSIS_PATH,
    REPORT_POST_VIEW_DETAIL,
    REPORT_POST_BY_DATETYPE,
    REPORT_POST_EXCEL_CREATE_BY,
    REPORT_POST_EXCEL_QUANTITY_CREATE_BY,
    REPORT_POST_SYNTHETIC_PATH,
    REPORT_POST_PATH,
    RECOVER_PASSWORD_PATH,
    FORGOT_PASSWORD_PATH,
    ACCOUNT_PATH_EDIT,
    ACCOUNT_PATH_ADD,
    LOGIN_PATH,
    ACCOUNT_PATH,
    LOGIN_HISTORY_PATH,
    EMAILCONFIG_PATH,
    EMAILCONFIG_CREATE_PATH,
    EMAILCONFIG_EDIT_PATH,
    STATISTIC_HISTORY_PATH,
    LOG_PATH,
    REPORT_POST_EXCEL_PATH,
    REPORT_POST_WEBANALYSIS_PATH,
    REPORT_POST_PUBLISHTIME_DATATYPE_PATH,
    REPORT_COUNT_VISIT_DETAIL,

    DEVICE_PATH,
    DEVICE_ADD_PATH,
    DEVICE_EDIT_PATH,
    BOOKDEVICE_PATH,
    BOOKDEVICE_ADD_PATH,
    BOOKDEVICE_EDIT_PATH,
    BOOKDEVICEFORAPPROVER_PATH,
    DEVICEMAINTENANCE_PATH,
    DEVICEMAINTENANCE_ADD_PATH,
    DEVICEMAINTENANCE_EDIT_PATH
};
