import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const SmallBox = ({
    type = 'info',
    icon = 'ion-bag',
    count,
    title,
    des,
    navigateTo
}) => {
    const [t] = useTranslation();

    return (
        <div style={{color: 'white'}} className={`small-box bg-${type}`}>
            <div className="inner">
                <h4>{count}</h4>
                <p>{title}</p>
                <p>{des}</p>
            </div>
        </div>
    );
};

export default SmallBox;
