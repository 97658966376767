import React, { useState, useRef } from 'react';
import { NavLink, Input } from 'reactstrap';
import { MdAdd, MdRemove } from 'react-icons/md';
import { ID_EMPTY } from '../../utils/define';
import { validInput } from '../../utils/define';
const SelectionMutil = props => {
  const [hasError, setHasError] = useState(false);
  const refTreeMenu = useRef();
  const refTreeInput = useRef();
  let {
    ForceValidate, // Bắt buộc kiểm tra dữ liệu hợp lệ khi mới tạo controller
    EnableValidate, // Bật kiểm tra dữ liệu
    ErrorMessage, // Câu thông báo nếu dữ liệu không hợp lệ
    data, //[{id,name,parentId}]
    actions, //[{title,text,action,Icon,href,color}]
    onChange,
    IconExpand,
    IconCollapse,
    positionExpand, //right,left,
    showCheckboxOnTree,
    multiSelect,
    Placeholder,
    searchKeyword,
    showSearchBox, // Hiển thị ô tìm kiếm
    singleValueSelected, // chỉ chọn 1 (ko có checkbox)
    multiValueSelected, // trong trường hợp chọn nhiều (có checkbox)
  } = props;

  //listData danh sách hiển thị select
  const [listData, setListData] = useState([]);

  const [listDataSearch, setDataSearch] = useState([]);

  //valueSelect giá trị khi chọn một
  const [valueSelect, setValueSelect] = useState('');

  //listSelected giá trị khi chọn nhiều
  const [listSelected, setListSelected] = useState([]);

  const unCheck = 'unCheck';
  const checked = 'checked';
  const checking = 'checking';

  React.useEffect(() => {
    let dataMap = [];
    // Nếu giá trị ban đầu listData = 0 , trả về list  map của data và thêm 2 thuộc tính expand: false (không expand), checked: unCheck(Không check), mặc định ban đầu
    if (listData.length === 0) {
      dataMap = data.map(item => ({
        ...item,
        expand: true,
        checked: unCheck,
      }));
    } else {
      // Nếu listData có giá trị, trả về List map của data với thuộc tính có trong listData
      data.forEach(element => {
        let itemTree = listData.find(x => x.id === element.id);
        dataMap.push({
          ...element,
          expand: itemTree ? itemTree.expand : false,
          checked: itemTree ? itemTree.checked : unCheck,
        });
      });
    }

    // multiValueSelected  [id] của Category
    let listSelectedNew = [];
    if (multiValueSelected && multiValueSelected.length > 0) {
      multiValueSelected.forEach(item => {
        //check xem item có thuộc danh sách dataMap không?
        let itemSelected = dataMap.find(x => x.id === item);
        if (itemSelected) {
          // nếu itemSelected có trong sanh sách thì cập nhật lại trường itemSelected.checked=checked
          itemSelected.checked = checked;

          // nếu như itemSelected là cha thì cập handleSelectParent xem nó là checked hay checking
          // nếu như muốn chọn thằng cha chọn cả thằng con thì dùng  dòng dưới
          // if (itemSelected.parentId !==ID_EMPTY) {
          //   dataMap = handleSelectParent(itemSelected.parentId, dataMap);
          // }

          // thêm vào danh sách của mutilSelect
          listSelectedNew.push(itemSelected);
        }
      });
    }

    setListData(dataMap);

    setDataSearch(dataMap);

    handleSearchOnTree(searchKeyword, dataMap);


    setValueSelect(
      singleValueSelected && singleValueSelected !== ''
        ? singleValueSelected
        : valueSelect,
    );

    setListSelected(
      listSelectedNew.length > 0 ? listSelectedNew : listSelected,
    );

  }, [data, searchKeyword, singleValueSelected, multiValueSelected]);


  if (ForceValidate && hasError === false) {
    if (
      EnableValidate &&
      !validInput(
        multiSelect ? 'tagselect' : 'option',
        multiSelect ? multiValueSelected : singleValueSelected,
      )
    ) {
      setHasError(true);
    }
  }

  // listTree danh sách câu chuyên mục từ listData
  let listTree = [];
  const genTree = (items, id = ID_EMPTY, level = 1, link = 'parentId') =>
    items
      .filter(item => item[link] === id)
      .map(item => ({
        ...item,
        level: level,
        children: genTree(items, item.id, level + 1),
      }));
  
  // kiểm tra xem parentId có tồn tại trong listData không => không tồn tại thì cho nó là cha
  const newListData = listData.map(obj => {
    if (!listData.some(x => x.id === obj.parentId)) {
      return { ...obj, parentId: ID_EMPTY }
    }
    return obj
   }
  )
  listTree = genTree(newListData);
  // listTree = genTree(listData);

  // handleExpandTree mở đóng cây chuyên mục
  const handleExpandTree = item => {
    let listDataNew = [...listData];
    listDataNew.find(x => {
      if (x.id === item.id) {
        x.expand = !x.expand;
      }
    });
    setListData(listDataNew);
  };

  // handleOutsideClick khi click bên ngoài phạm vi box
  function handleOutsideClick(e) {
    if (refTreeMenu.current) {
      if (refTreeInput.current.contains(e.target)) {
        if (
          e.target.tagName.includes('path') ||
          e.target.tagName.includes('svg') ||
          e.target.tagName.includes('INPUT')
        ) {
          return;
        }
      }
      if (refTreeMenu.current.contains(e.target) && multiSelect) {
        return;
      }
      document.removeEventListener('click', handleOutsideClick, false);
      refTreeMenu.current.classList.toggle('show');
    }
  }

  // click box addEventListener sự kiện , thêm class show để hiện nội dung.
  function handleOpenBoxTree() {
    if (!refTreeMenu.current.classList.value.includes('show')) {
      document.addEventListener('click', handleOutsideClick, false);
      refTreeMenu.current.classList.toggle('show');
    }
  }

  // cập nhật các thẻ con từ thẻ cha 
  // const handleSelectChildren = (checked, parentId, listDataNew) => {
  //   listDataNew.forEach(item => {
  //     if (item.parentId === parentId) {
  //       item.checked = checked;
  //       item.expand = true;
  //       listDataNew = handleSelectChildren(checked, item.id, listDataNew);
  //     }
  //   });
  //   return listDataNew;
  // };


  //// 
  // const handleSelectParent = (parentId, listDataNew) => {

  //   // lấy tất cả children của parentId
  //   let children = listDataNew.filter(x => x.parentId === parentId);

  //   // lấy tất cả con có checked
  //   let childrenChecked = children.filter(
  //     x => x.checked === checked || x.checked === checking,
  //   );

  //   // lấy detail parent từ trong listDataNew
  //   let parent = listDataNew.find(x => x.id === parentId);

  //   // kiểm tra xem nó là checked hay checking hay uncheck
  //   if (parent) {
  //     parent.checked =
  //       children && childrenChecked.length > 0
  //         ? children.length === childrenChecked.length
  //           ? checked
  //           : checking
  //         : unCheck;
  //     if (parent.parentId !== ID_EMPTY) {
  //       listDataNew = handleSelectParent(parent.parentId, listDataNew);
  //     }
  //   }
  //   return listDataNew;
  // };

  const handleSelectMulti = (e, item) => {
    if (
      !multiSelect ||
      e.target.tagName.includes('path') ||
      e.target.tagName.includes('svg')
    ) {
      return;
    }

    if (item.isGroup) {
      return;
    }

    let dataChange = null;

    let listDataNew = [...listData];
    let statusCheck =
      item.checked === checking || item.checked === unCheck ? checked : unCheck;

    listDataNew.find(x => {
      if (x.id === item.id) {
        x.checked =
          x.checked === checking || x.checked === unCheck ? checked : unCheck;
        x.expand = true;
      }
    });

    // listDataNew = handleSelectChildren(statusCheck, item.id, listDataNew);
    // if (item.parentId !== '') {
    //   listDataNew = handleSelectParent(item.parentId, listDataNew);
    // }

    dataChange = listDataNew.filter(
      x => x.checked === checked || x.checked === checking,
    );
    setListData(listDataNew);
    setListSelected(dataChange);
    setHasError(EnableValidate && !validInput('tagselect', dataChange));
    onChange(dataChange);
  };

  const handleSelect = (e, item) => {
    if (
      multiSelect ||
      e.target.tagName.includes('path') ||
      e.target.tagName.includes('svg')
    ) {
      return;
    }
    if (item.isGroup) {
      return;
    }
    setValueSelect(item.id);

    onChange(item);

    setHasError(EnableValidate && !validInput('option', item));
  };

  const addParentToTree = (parentId, listSearch) => {
    if (parentId !== undefined) {
      let node = listDataSearch.find(x => x.id === parentId);
      if (node) {
        node.expand = true;
        if (!listSearch.some(x => x.id === parentId)) {
          listSearch.push(node);
        }
        listSearch = addParentToTree(node.parentId, listSearch);
      }
    }

    return listSearch;
  };

  const handleSearchOnTree = (value, listSearch = listDataSearch) => {
    if (listSearch.length > 0) {
      let listDataNew = [];

      if (value.length > 0) {
        listDataNew = listSearch.filter(item =>
          item.name.toLowerCase().includes(value.toLowerCase()),
        );
        listDataNew.forEach(element => {
          listDataNew = addParentToTree(element.parentId, listDataNew);
        });
      } else {
        listDataNew = listSearch;
      }
      setListData(listDataNew);
    }
  };

  const handleSearchChange = e => {
    handleSearchOnTree(e.target.value);
  };

  // const handleRemoveItem = item => {
  //   let dataChange = listSelected.filter(x => x.id !== item.id);
  //   let listDataNew = [...listData];
  //   listDataNew.find(x => {
  //     if (x.id === item.id) {
  //       x.checked = x.checked === checked ? unCheck : checked;
  //     }
  //   });
  //   setListData(listDataNew);
  //   setListSelected(dataChange);
  //   onChange(dataChange);
  // };

  const renderItemTree = (items, expand = true) => {
    if (items) {
      return items.map((item, index) => (
        <div key={index}>
          <div
            key={index}
            className={`tree_item_children ${expand && 'tree_item_active'}`}
          >
            <div
              className={`tree_item ${!showCheckboxOnTree && 'tree_border_item'
                } ${!multiSelect && 'cursor-pointer'}`}
              style={{ paddingLeft: (item.level - 1) * 20 }}
              onClick={e => handleSelect(e, item)}
            >
              {item.children.length > 0 && positionExpand === 'left' ? (
                <span style={{ padding: 5 }} className="cursor-pointer">
                  <IconCollapse
                    name="iconFolder"
                    className="cursor-pointer"
                    style={{ display: item.expand ? 'block' : 'none', }}
                    onClick={() => handleExpandTree(item)}
                  />
                  <IconExpand
                    name="iconFolder"
                    className="cursor-pointer"
                    style={{ display: item.expand ? 'none' : 'block' }}
                    onClick={() => handleExpandTree(item)}
                  />
                </span>
              ) : null}
              <label
                className={`container_check ${(!multiSelect || item.isGroup) && 'container_check_hide'
                  }`}
              >
                {item.name}
                <input
                  type="checkbox"
                  checked={
                    item.checked === checked || item.checked === checking
                  }
                  onChange={e => handleSelectMulti(e, item)}
                />
                <span
                  className={`checkmark ${item.checked} ${item.checked === checking && 'checkmark_parent'
                    }`}
                // onClick={e => handleSelectMulti(e, item)}
                ></span>
              </label>

              {item.children.length > 0 && positionExpand === 'right' ? (
                <span style={{ padding: 5 }} className="cursor-pointer">
                  <IconCollapse
                    name="iconFolder"
                    className="cursor-pointer"
                    style={{ display: item.expand ? 'block' : 'none' }}
                    onClick={() => handleExpandTree(item)}
                  />
                  <IconExpand
                    name="iconFolder"
                    className="cursor-pointer"
                    style={{ display: item.expand ? 'none' : 'block' }}
                    onClick={() => handleExpandTree(item)}
                  />
                </span>
              ) : null}
              <div className="tree_item_action">
                {actions.map((action, i) => (
                  <NavLink
                    key={i}
                    style={{ padding: 0 }}
                    onClick={() => action.action(item)}
                  >
                    <action.Icon
                      title={action.title}
                      color={action.color}
                      className="table-action-control"
                    />
                  </NavLink>
                ))}
              </div>
            </div>
            {renderItemTree(item.children, item.expand)}
          </div>
        </div>
      ));
    } else {
      return null;
    }
  };

  if (showCheckboxOnTree) {
    return (
      <>
        <div style={{ position: 'relative' }} ref={refTreeInput}>
          {multiSelect ? (
            <span
              className={` select2 select2-container select2-container--default select2-container--below ${listSelected &&
                listSelected.length > 0 &&
                'select2-container--focus'
                }`}
              dir="ltr"
              onClick={() => handleOpenBoxTree()}
              style={{ width: '100%' }}
            >
              <span className="selection">
                <span
                  className="select2-selection select2-selection--multiple"
                  aria-haspopup="true"
                  aria-expanded="false"
                  tabIndex={-1}
                >
                  <ul className="select2-selection__rendered">
                    {listSelected &&
                      listSelected.length > 0 &&
                      listSelected.length < 10 ? (
                      listSelected.map((item, i) => (
                        <li
                          key={i}
                          className="select2-selection__choice"
                          title={item}
                        >
                          {item.name}
                        </li>
                      ))
                    ) : listSelected && listSelected.length > 10 ? (
                      <li className="select2-selection__choice">
                        {listSelected.length} selected
                      </li>
                    ) : null}
                    <li className="select2-search select2-search--inline">
                      <input
                        value=""
                        placeholder={Placeholder}
                        className="select2-search__field"
                        id="inputRedirectUri"
                        type="search"
                        onChange={() => { }}
                        tabIndex={0}
                      />
                    </li>
                  </ul>
                </span>
              </span>
            </span>
          ) : (
            <select
              className="form-control"
              value={valueSelect}
              onClick={() => handleOpenBoxTree()}
              onChange={() => { }}
            >
              {listData.map((item, index) => (
                <option style={{ display: 'none' }} key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          )}

          <div
            className={`dropdown-menu`}
            style={{ padding: 0 }}
            ref={refTreeMenu}
          >
            {showSearchBox && (
              <div style={{ padding: 10 }}>
                <Input
                  placeholder="Tìm kiếm ..."
                  onChange={e => handleSearchChange(e)}
                />
              </div>
            )}

            {renderItemTree(listTree)}
          </div>
        </div>
        <div>
          {hasError === true && EnableValidate && (
            <span className="help-block">{ErrorMessage}</span>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div>
          {showSearchBox && (
            <div style={{ marginBottom: 10 }}>
              <Input
                placeholder="Tìm kiếm ..."
                onChange={e => handleSearchChange(e)}
              />
            </div>
          )}

          {renderItemTree(listTree)}
        </div>
        <div>
          {hasError === true && EnableValidate && (
            <span className="help-block">{ErrorMessage}</span>
          )}
        </div>
      </>
    );
  }
};

SelectionMutil.defaultProps = {
  actions: [], //[{title,text,action,Icon,href,color}]
  IconExpand: MdAdd,
  IconCollapse: MdRemove,
  positionExpand: 'left', //right,left,
  showCheckboxOnTree: false,
  multiSelect: false,
  showSearchBox: false,
  Placeholder: '',
  searchKeyword: '',
  data: [], //[{id,name,parentId}]
  onChange: () => { },
  singleValueSelected: '',
  multiValueSelected: [],
  EnableValidate: false,
  ErrorMessage: '',
  ForceValidate: false,
};

export default SelectionMutil;
