import React, {useState, useEffect} from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import Editor from '@app/components/editor/Editor';
import {Post, Get} from '@app/utils/httpProvider';
import {
    BOOKDEVICE_API,
    CHATBOTQA_API,
    DEVICE_API,
    ACCOUNT_API,
    DEPARTMENT_API
} from '@app/utils/apiUrl';
import {useMutation, useQuery} from 'react-query';
import {
    messageError,
    messageToast,
    acceptImage,
    objectDataTypeCategory,
    objectDataTypeVideo,
    styleSelectDefault,
    styleSelectError,
    status200,
    dataTypeCategory,
    objectTypeQA,
    objectAnswerChoice,
    answerChoice,
    objectSentenceType,
    sentenceType,
    rangeDateBooking,
    role,
    deviceStatus,
    DATE_TIME_REACT_FORMAT
} from '../../utils/define';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {WithContext as ReactTags} from 'react-tag-input';
import ColorPicker from 'react-pick-color';
import {AsyncPaginate} from 'react-select-async-paginate';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);

// import moment from 'moment';
// moment.locale('vi');
// moment().format('DD/MM/YY');

import moment from 'moment';
import {
    dateTimeToStringRequest,
    dateTimeToUTCRequest
    // getInfomationAccountFromToken
} from '@app/utils/helper';

moment.defineLocale('vi', {
    // Changed to 'vi' for Vietnamese
    months: [
        'Tháng 1',
        'Tháng 2',
        'Tháng 3',
        'Tháng 4',
        'Tháng 5',
        'Tháng 6',
        'Tháng 7',
        'Tháng 8',
        'Tháng 9',
        'Tháng 10',
        'Tháng 11',
        'Tháng 12'
    ],
    monthsShort: [
        'Th1',
        'Th2',
        'Th3',
        'Th4',
        'Th5',
        'Th6',
        'Th7',
        'Th8',
        'Th9',
        'Th10',
        'Th11',
        'Th12'
    ],
    weekdays: [
        'Chủ Nhật',
        'Thứ Hai',
        'Thứ Ba',
        'Thứ Tư',
        'Thứ Năm',
        'Thứ Sáu',
        'Thứ Bảy'
    ],
    weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    longDateFormat: {
        LTS: 'HH:mm:ss',
        LT: 'HH:mm',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM [năm] YYYY',
        LLL: 'D MMMM [năm] YYYY HH:mm',
        LLLL: 'dddd, D MMMM [năm] YYYY HH:mm'
    },
    calendar: {
        sameDay: '[Hôm nay lúc] LT',
        nextDay: '[Ngày mai lúc] LT',
        nextWeek: 'dddd [tuần tới lúc] LT',
        lastDay: '[Hôm qua lúc] LT',
        lastWeek: 'dddd [tuần rồi lúc] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'trong %s',
        past: '%s trước',
        s: 'vài giây',
        ss: '%d giây',
        m: 'một phút',
        mm: '%d phút',
        h: 'một giờ',
        hh: '%d giờ',
        d: 'một ngày',
        dd: '%d ngày',
        M: 'một tháng',
        MM: '%d tháng',
        y: 'một năm',
        yy: '%d năm'
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4 // The week that contains Jan 4th is the first week of the year.
    }
});

const schema = yup.object().shape({
    deviceId: yup.object().required(messageError.emptyMessage)
    // accountId: yup.object().shape({
    //     label: yup.string(),
    //     value: yup.string()
    //   }).nullable()
    //   .required(messageError.emptyMessage)
    // weather: yup.string().required(messageError.emptyMessage),
    // accountId: yup.object().required(messageError.emptyMessage)
    // parentId: yup.string().required(messageError.emptyMessage)
});

const BookDeviceAdd = () => {
    const history = useHistory();
    // const inforAccount = getInfomationAccountFromToken();

    // const {isLoading, isError, error, data} = useQuery(['cateData'], () =>
    //     Get(CATEGORY_API.GET_ALL_CATEGORY, null)
    // );
    // const timeCurentDay = moment().add(6, 'days');
    // timeCurentDay.set({hour: 0, minute: 0, second: 0, millisecond: 0});

    // const [dates, setDates] = useState({
    //     startDate: moment(),
    //     endDate: timeCurentDay
    // });

    // const listAccount = useQuery(['getAllAccountInBookDevice'], () =>
    //     Get(ACCOUNT_API.GET_PAGING_ACCOUNT, null, {
    //         PageIndex: 1,
    //         PageSize: 10000,
    //         Role: role.Booker
    //     })
    // );

    // const lstAccount =
    //     listAccount && listAccount.data && listAccount.data
    //         ? listAccount.data.data.$values.map((x) => ({
    //               label: x.username,
    //               value: x.id
    //           }))
    //         : [];

    const [bookColor, setBookColor] = useState('blue');
    // const [deviceName, setDeviceName] = useState('');

    // const listDevice = useQuery(['getListDeviceInBookDeviceAdd'], () =>
    //     Get(DEVICE_API.GET_PAGING, null, {
    //         PageIndex: 1,
    //         PageSize: 8,
    //         Keyword: deviceName,
    //         DeviceStatus: deviceStatus.available
    //     })
    // );

    // const lstDevice =
    //     listDevice && listDevice.data && listDevice.data.data
    //         ? listDevice.data.data.map((x) => ({
    //               label: x.name,
    //               value: x.id
    //           }))
    //         : [];

    const listDepartment = useQuery(['getAllDepartment'], () =>
        Get(DEPARTMENT_API.GET_ALL, null, null)
    );

    const lstDepartment =
        listDepartment && listDepartment.data
            ? listDepartment.data.map((x) => ({
                  label: x.Ten,
                  value: x.ID
              }))
            : [];

    // const [tags, setTags] = React.useState([]);
    // const [suggestions, setSuggestions] = React.useState([]);

    // useEffect(() => {
    //     listDevice.refetch();
    // }, [deviceName]);

    // useEffect(() => {
    //     if (
    //         listDevice.data &&
    //         listDevice.data.data &&
    //         listDevice.data.data.length > 0
    //     ) {
    //         setSuggestions(
    //             listDevice.data.data.map((x) => {
    //                 var departmentName = lstDepartment &&
    //                 lstDepartment.find((i) => i.value == x.departmentId)
    //                     ? lstDepartment.find(
    //                           (i) => i.value == x.departmentId
    //                       ).label
    //                     : ''
    //                 return {
    //                     id: x.id,
    //                     text:
    //                         x.name +
    //                             ' - ' +
    //                             x.deviceCode +
    //                             ' - ' + departmentName

    //                 };
    //             })
    //         );
    //     }
    // }, [listDevice.data]);

    const addFunc = useMutation((value) =>
        Post(BOOKDEVICE_API.ADD_ITEM, null, value, false)
    );

    const handleCreate = async (values) => {
        const model = {...values};

        // model.bookTime = dateTimeToUTCRequest(values.bookTime);
        // model.endBookTime = dateTimeToUTCRequest(values.endBookTime);

        model.bookColor = bookColor;

        model.deviceId =
            values.deviceId === '' ||
            values.deviceId === 'null' ||
            values.deviceId === undefined
                ? ''
                : values.deviceId.value;

        // model.bookTime = dates.startDate;
        // model.endBookTime = dates.endDate;
        // model.lstDeviceId = tags.map((tag) => tag.id);

        // dùng admin test
        // model.currentUserId = inforAccount.id;
        // model.parentId = '';
        // model.areaId = values.areaId.value;
        addFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastCreateError);
                } else {
                    toast.success(messageToast.messageToastCreateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error(
                    res.message || 'Đã có lỗi xảy ra, vui lòng thử lại !'
                );
            }
        });
    };

    // const handleApply = (event, picker) => {
    //     setDates({
    //         startDate: picker.startDate,
    //         endDate: picker.endDate
    //     });
    //     picker.element.val(
    //         `${picker.startDate.format(
    //             'DD/MM/YYYY HH:mm A'
    //         )} - ${picker.endDate.format('DD/MM/YYYY HH:mm A')}`
    //     );
    // };
    // const handleCancel = (event, picker) => {
    //     setDates({
    //         startDate: null,
    //         endDate: null
    //     });
    //     picker.element.val('');
    // };

    // if (isLoading) return 'Loading...';

    // if (isError) return `An error has occurred: ${error.message}`;

    // let listMenu = [
    //     {
    //         name: 'Danh mục gốc',
    //         id: 'null',
    //         parentId: NIL_UUID
    //     }
    // ];

    // listMenu = listMenu.concat(data.data.$values);

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        setTags([...tags, tag]);
    };

    const handleInputChange = (text) => {
        setDeviceName(text);
    };

    // const loadOptions = async (inputValue, loadedOptions, {page}) => {
    //     const filteredOptions = lstAccount
    //         .filter((option) =>
    //             option.label.toLowerCase().includes(inputValue.toLowerCase())
    //         )
    //         .slice(0, 6);

    //     return {
    //         options: filteredOptions,
    //         hasMore: false,
    //         additional: {
    //             page: page + 1
    //         }
    //     };
    // };

    const loadOptions = async (inputValue, loadedOptions, {page}) => {
        // setDeviceName(inputValue);
        // Fetch data from the API based on the inputValue
        const listDevice = await Get(DEVICE_API.GET_PAGING, null, {
            PageIndex: 1,
            PageSize: 8,
            Keyword: inputValue,
            DeviceStatus: deviceStatus.available
        });

        const lstDevice =
            listDevice && listDevice.data
                ? listDevice.data.map((x) => {
                    var departmentName = lstDepartment &&
                    lstDepartment.find((i) => i.value == x.departmentId)
                        ? lstDepartment.find(
                              (i) => i.value == x.departmentId
                          ).label
                        : '';
                    return {
                      label: x.name + ' - ' + x.deviceCode + ' - ' + departmentName,
                      value: x.id
                  }})
                : [];

        // const filteredOptions = lstDevice.filter((option) =>
        //     option.label.toLowerCase()
        // );

        // console.log('filteredOptions', filteredOptions);

        // Return all filtered options, no matter how many there are
        return {
            options: lstDevice,
            hasMore: false,
            additional: {
                page: page + 1
            }
        };
    };

    return (
        <Card>
            <Card.Header as="h5">Thêm đặt lịch</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => handleCreate(values)}
                    initialValues={{
                        title: '',
                        description: '',
                        bookTime: new Date(),
                        endBookTime: new Date(),
                        deviceId: ''
                        // accountId: '',
                        // isActive: true
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tiêu đề"
                                            type="text"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group> */}

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mục đích sử dụng
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="description"
                                            value={values.description}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'description',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trang thiết bị
                                    </Form.Label>
                                    <Col sm="10">
                                        <AsyncPaginate
                                            name="deviceId"
                                            id="deviceId"
                                            placeholder="Chọn trang thiết bị"
                                            value={values.deviceId}
                                            loadOptions={loadOptions}
                                            isClearable
                                            styles={
                                                errors.deviceId !== undefined
                                                    ? styleSelectError
                                                    : styleSelectDefault
                                            }
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'deviceId',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            additional={{
                                                page: 1
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.deviceId}
                                        </div>
                                    </Col>
                                </Form.Group>

                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Người đặt thiết bị *
                                    </Form.Label>
                                    <Col sm="10">
                                        <AsyncPaginate
                                            name="accountId"
                                            id="accountId"
                                            placeholder="Chọn người đặt thiết bị"
                                            value={values.accountId}
                                            loadOptions={loadOptions}
                                            isClearable
                                            styles={
                                                errors.accountId !== undefined
                                                    ? styleSelectError
                                                    : styleSelectDefault
                                            }
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'accountId',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            additional={{
                                                page: 1
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.accountId}
                                        </div>
                                    </Col>
                                </Form.Group> */}

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Bắt đầu đặt thiết bị lúc *
                                    </Form.Label>
                                    <Col sm="10">
                                        <DatePicker
                                            locale="vi"
                                            minDate={new Date()}
                                            selected={values.bookTime}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    'bookTime',
                                                    date,
                                                    true
                                                );
                                            }}
                                            timeInputLabel="Thời gian:"
                                            dateFormat={DATE_TIME_REACT_FORMAT}
                                            showTimeInput
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Kết thúc đặt thiết bị lúc *
                                    </Form.Label>
                                    <Col sm="10">
                                        <DatePicker
                                            locale="vi"
                                            selected={values.endBookTime}
                                            minDate={new Date()}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    'endBookTime',
                                                    date,
                                                    true
                                                );
                                            }}
                                            timeInputLabel="Thời gian:"
                                            dateFormat={DATE_TIME_REACT_FORMAT}
                                            showTimeInput
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>

                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thời gian đặt thiết bị *
                                    </Form.Label>
                                    <Col sm="10">
                                        <DateRangePicker
                                            onApply={handleApply}
                                            onCancel={handleCancel}
                                            initialSettings={{
                                                // autoUpdateInput: false,
                                                locale: {
                                                    format: 'DD/MM/YYYY hh:mm A',
                                                    cancelLabel: 'Xóa',
                                                    customRangeLabel:
                                                        'Tùy chọn',
                                                    applyLabel: 'Chọn'
                                                },
                                                ranges: rangeDateBooking,
                                                minDate: moment(
                                                    dates.startDate
                                                ).format('DD/MM/YYYY hh:mm A'),
                                                startDate: moment(
                                                    dates.startDate
                                                ).format('DD/MM/YYYY hh:mm A'),
                                                endDate: moment(
                                                    dates.endDate
                                                ).format('DD/MM/YYYY hh:mm A'),
                                                timePicker: true
                                            }}
                                        >
                                            <input
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value
                                                            .length === 0
                                                    ) {
                                                        setDates({
                                                            startDate: null,
                                                            endDate: null
                                                        });
                                                    }
                                                }}
                                                type="text"
                                                className="form-control"
                                            />
                                        </DateRangePicker>
                                    </Col>
                                </Form.Group> */}

                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Chọn màu để hiển thị trên lịch
                                    </Form.Label>
                                    <Col sm="10">
                                        <ColorPicker
                                            color={bookColor}
                                            onChange={(color) =>
                                                setBookColor(color.hex)
                                            }
                                        />
                                    </Col>
                                </Form.Group> */}

                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái kích hoạt
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary1"
                                            type="checkbox"
                                            placeholder="Trạng thái"
                                            name="isActive"
                                            checked={values.isActive}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary1">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{span: 10, offset: 2}}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Thêm
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() => history.goBack()}
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        );
                    }}
                </Formik>
            </Card.Body>
        </Card>
    );
};

export default BookDeviceAdd;
