import React, {useState, useEffect} from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useLocation, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Get, Put} from '@app/utils/httpProvider';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import Editor from '@app/components/editor/Editor';
import {NIL as NIL_UUID} from 'uuid';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {WithContext as ReactTags} from 'react-tag-input';
import ColorPicker from 'react-pick-color';
import Select from 'react-select';
import {AsyncPaginate} from 'react-select-async-paginate';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {
    messageError,
    messageToast,
    acceptImage,
    objectDataTypeCategory,
    styleSelectDefault,
    styleSelectError,
    status200,
    dataTypeCategory,
    objectDataTypeVideo,
    objectTypeQA,
    objectAnswerChoice,
    answerChoice,
    objectSentenceType,
    rangeDateBooking,
    sentenceType,
    deviceStatus,
    role,
    bookDeviceStatus,
    DATE_TIME_REACT_FORMAT,
    objectBookDeviceStatus
} from '../../utils/define';
import {
    BaseGatewayImage,
    BOOKDEVICE_API,
    DEVICE_API,
    ACCOUNT_API,
    DEPARTMENT_API
} from '../../utils/apiUrl';
import 'react-tabs/style/react-tabs.css';

import {registerLocale, setDefaultLocale} from 'react-datepicker';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);

import moment from 'moment';
import {
    dateTimeToStringRequest,
    dateTimeToUTCRequest,
    dateTimeBackFromUTCRequest,
    getInfomationAccountFromToken
} from '@app/utils/helper';
import DeviceMaintenance from '@app/pages/deviceMaintenance/DeviceMaintenance';
import DeviceMaintenanceAdd from '@app/pages/deviceMaintenance/DeviceMaintenanceAdd';

moment.defineLocale('vi', {
    // Changed to 'vi' for Vietnamese
    months: [
        'Tháng 1',
        'Tháng 2',
        'Tháng 3',
        'Tháng 4',
        'Tháng 5',
        'Tháng 6',
        'Tháng 7',
        'Tháng 8',
        'Tháng 9',
        'Tháng 10',
        'Tháng 11',
        'Tháng 12'
    ],
    monthsShort: [
        'Th1',
        'Th2',
        'Th3',
        'Th4',
        'Th5',
        'Th6',
        'Th7',
        'Th8',
        'Th9',
        'Th10',
        'Th11',
        'Th12'
    ],
    weekdays: [
        'Chủ Nhật',
        'Thứ Hai',
        'Thứ Ba',
        'Thứ Tư',
        'Thứ Năm',
        'Thứ Sáu',
        'Thứ Bảy'
    ],
    weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    longDateFormat: {
        LTS: 'HH:mm:ss',
        LT: 'HH:mm',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM [năm] YYYY',
        LLL: 'D MMMM [năm] YYYY HH:mm',
        LLLL: 'dddd, D MMMM [năm] YYYY HH:mm'
    },
    calendar: {
        sameDay: '[Hôm nay lúc] LT',
        nextDay: '[Ngày mai lúc] LT',
        nextWeek: 'dddd [tuần tới lúc] LT',
        lastDay: '[Hôm qua lúc] LT',
        lastWeek: 'dddd [tuần rồi lúc] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'trong %s',
        past: '%s trước',
        s: 'vài giây',
        ss: '%d giây',
        m: 'một phút',
        mm: '%d phút',
        h: 'một giờ',
        hh: '%d giờ',
        d: 'một ngày',
        dd: '%d ngày',
        M: 'một tháng',
        MM: '%d tháng',
        y: 'một năm',
        yy: '%d năm'
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4 // The week that contains Jan 4th is the first week of the year.
    }
});

const schema = yup.object().shape({
    deviceId: yup.object().required(messageError.emptyMessage)
    // dataType: yup.object().required(messageError.emptyMessage)
});

const BookDeviceEdit = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));

    let accountRoleBooker = false;
    let accountRoleApprover = false;
    const inforAccount = getInfomationAccountFromToken();

    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        accountRoleBooker =
            userRole.includes(role.Admin) || userRole.includes(role.Booker);
        accountRoleApprover =
            userRole.includes(role.Admin) || userRole.includes(role.Approver);
    }

    const bookDeviceId = paramUrl.get('id');

    const bookDeviceItem = useQuery([bookDeviceId], () =>
        Get(BOOKDEVICE_API.GET_ITEM, bookDeviceId)
    );

    const listDepartment = useQuery(['getAllDepartment'], () =>
        Get(DEPARTMENT_API.GET_ALL, null, null)
    );

    const lstDepartment =
        listDepartment && listDepartment.data
            ? listDepartment.data.map((x) => ({
                  label: x.Ten,
                  value: x.ID
              }))
            : [];

    const departmentNameOrigin =
        bookDeviceItem &&
        bookDeviceItem.data &&
        bookDeviceItem.data.device &&
        lstDepartment.find(
            (x) => x.value == bookDeviceItem.data.device.departmentId
        )
            ? lstDepartment.find(
                  (x) => x.value == bookDeviceItem.data.device.departmentId
              ).label
            : '';

    // const accountItem = useQuery([bookDeviceId, bookDeviceItem.data.accountId], () =>
    //     Get(ACCOUNT_API.GET_BY_ID_ACCOUNT, bookDeviceItem && bookDeviceItem.data ? bookDeviceItem.data.accountId : NIL_UUID)
    // );

    // console.log('bookDeviceItem', bookDeviceItem);

    // const timeCurentDay = moment().add(6, 'days');
    // timeCurentDay.set({hour: 0, minute: 0, second: 0, millisecond: 0});

    // const [dates, setDates] = useState({
    //     startDate:
    //         bookDeviceItem && bookDeviceItem.data
    //             ? new Date(bookDeviceItem.data.bookTime)
    //             : moment(),
    //     endDate:
    //         bookDeviceItem && bookDeviceItem.data
    //             ? new Date(bookDeviceItem.data.endBookTime)
    //             : timeCurentDay
    // });

    // const [bookColor, setBookColor] = useState('blue');
    // const [tagName, setTagName] = useState('');

    const listDevice = useQuery(
        ['getListDeviceInBookDevice', bookDeviceId],
        () =>
            Get(DEVICE_API.GET_PAGING, null, {
                PageIndex: 1,
                PageSize: 8,
                // Keyword: tagName,
                DeviceStatus: deviceStatus.available
            })
    );

    // const [tags, setTags] = React.useState([]);

    // const [suggestions, setSuggestions] = React.useState([]);

    // useEffect(() => {
    //     listDevice.refetch();
    // }, [tagName]);

    // useEffect(() => {
    //     if (
    //         bookDeviceItem.data &&
    //         bookDeviceItem.data.devices &&
    //         bookDeviceItem.data.devices.length > 0
    //     ) {
    //         setTags(
    //             bookDeviceItem.data.devices.map((x) => ({
    //                 id: x.id,
    //                 text: x.name
    //             }))
    //         );

    //         // setDates({
    //         //     startDate: new Date(bookDeviceItem.data.bookTime),
    //         //     endDate: new Date(bookDeviceItem.data.endBookTime)
    //         // });

    //         // setBookColor(bookDeviceItem.data.bookColor);
    //     }
    // }, [bookDeviceItem.data]);

    // useEffect(() => {
    //     if (
    //         listDevice.data &&
    //         listDevice.data.data &&
    //         listDevice.data.data.length > 0
    //     ) {
    //         setSuggestions(
    //             listDevice.data.data.map((x) => ({
    //                 id: x.id,
    //                 text: x.name
    //             }))
    //         );
    //     }
    // }, [listDevice.data]);

    // const handleApply = (event, picker, setFieldValue) => {
    //     // setDates({
    //     //     startDate: picker.startDate,
    //     //     endDate: picker.endDate
    //     // });
    //     setFieldValue('startDate', moment(picker.startDate));
    //     setFieldValue('endDate', moment(picker.endDate));
    //     picker.element.val(
    //         `${picker.startDate.format(
    //             'DD/MM/YYYY HH:mm A'
    //         )} - ${picker.endDate.format('DD/MM/YYYY HH:mm A')}`
    //     );
    // };
    // const handleCancel = (event, picker, setFieldValue) => {
    //     // setDates({
    //     //     startDate: null,
    //     //     endDate: null
    //     // });
    //     setFieldValue('startDate', null);
    //     setFieldValue('endDate', null);
    //     picker.element.val('');
    // };

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        setTags([...tags, tag]);
    };

    const handleInputChange = (text) => {
        setTagName(text);
    };

    // const listCate = useQuery(['getAllCate'], () =>
    //     Get(CATEGORY_API.GET_ALL_CATEGORY)
    // );

    const editFunc = useMutation((value) =>
        Put(BOOKDEVICE_API.UPDATE_ITEM, bookDeviceId, value)
    );

    const handleEdit = async (values) => {
        const model = {...values};
        model.id = bookDeviceId;
        model.bookTime = values.bookTime;
        model.endBookTime = values.endBookTime;
        model.deviceId =
            values.deviceId === '' ||
            values.deviceId === 'null' ||
            values.deviceId === undefined
                ? ''
                : values.deviceId.value;
        // model.bookColor = bookColor;
        // model.lstDeviceId = tags.map((tag) => tag.id);

        // dùng admin test
        // model.currentUserId = inforAccount.id;

        editFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    toast.success(messageToast.messageToastUpdateSuccess);
                    model.bookTime = values.bookTime;
                    model.endBookTime = values.endBookTime;
                    queryClient.setQueryData(bookDeviceId, model);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error(
                    res.message || 'Đã có lỗi xảy ra, vui lòng thử lại !'
                );
            }
        });
    };

    const approveFunc = useMutation((value) =>
        Put(BOOKDEVICE_API.UPDATE_APPROVE, bookDeviceId, value)
    );

    const handleApprove = async (approved) => {
        let model = {};
        model.bookTime =
            bookDeviceItem &&
            bookDeviceItem.data &&
            bookDeviceItem.data.bookTime
                ? new Date(bookDeviceItem.data.bookTime)
                : new Date();
        model.endBookTime =
            bookDeviceItem &&
            bookDeviceItem.data &&
            bookDeviceItem.data.endBookTime
                ? new Date(bookDeviceItem.data.endBookTime)
                : new Date();
        model.deviceId =
            bookDeviceItem && bookDeviceItem.data
                ? bookDeviceItem.data.deviceId
                : '';
        // dùng admin test
        // model.currentUserId = inforAccount.id;

        model.bookStatus = approved;

        approveFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    toast.success(messageToast.messageToastUpdateSuccess);
                    queryClient.setQueryData(bookDeviceId, model);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error(
                    res.message || 'Đã có lỗi xảy ra, vui lòng thử lại !'
                );
            }
        });
    };

    const loadOptions = async (inputValue, loadedOptions, {page}) => {
        // setDeviceName(inputValue);
        // Fetch data from the API based on the inputValue
        const listDevice = await Get(DEVICE_API.GET_PAGING, null, {
            PageIndex: 1,
            PageSize: 8,
            Keyword: inputValue,
            DeviceStatus: deviceStatus.available
        });

        const lstDevice =
            listDevice && listDevice.data
                ? listDevice.data.map((x) => {
                      var departmentName =
                          lstDepartment &&
                          lstDepartment.find((i) => i.value == x.departmentId)
                              ? lstDepartment.find(
                                    (i) => i.value == x.departmentId
                                ).label
                              : '';
                      return {
                          label:
                              x.name +
                              ' - ' +
                              x.deviceCode +
                              ' - ' +
                              departmentName,
                          value: x.id
                      };
                  })
                : [];

        // const filteredOptions = lstDevice.filter((option) =>
        //     option.label.toLowerCase()
        // );

        // console.log('filteredOptions', filteredOptions);

        // Return all filtered options, no matter how many there are
        return {
            options: lstDevice,
            hasMore: false,
            additional: {
                page: page + 1
            }
        };
    };

    // const loadOptions = async (inputValue, loadedOptions, {page}) => {
    //     const filteredOptions = lstParentQA
    //         .filter((option) =>
    //             option.label.toLowerCase().includes(inputValue.toLowerCase())
    //         )
    //         .slice(0, 3);

    //     // Return all filtered options, no matter how many there are
    //     return {
    //         options: filteredOptions,
    //         hasMore: false,
    //         additional: {
    //             page: page + 1
    //         }
    //     };
    // };

    // const isLoading = cateItem.isLoading || listCate.isLoading;
    // const isError = cateItem.isError || listCate.isError;
    // const error = cateItem.isError ? cateItem.error : listCate.error;

    const isLoading =
        bookDeviceItem.isLoading || listDevice.isLoading ? true : false;
    const isError = bookDeviceItem.isError || listDevice.isError ? true : false;
    const error =
        bookDeviceItem.isError || listDevice.isError
            ? bookDeviceItem.error
            : null;

    return (
        <Card>
            <Card.Header as="h5">
                <span>Sửa đặt lịch</span>
            </Card.Header>
            <Tabs>
                <TabList>
                    <Tab>Thông tin đặt lịch</Tab>
                    {bookDeviceItem &&
                    bookDeviceItem.data &&
                    bookDeviceItem.data.bookStatus &&
                    bookDeviceItem.data.accountId &&
                    bookDeviceItem.data.accountId == inforAccount.id &&
                    bookDeviceItem.data.bookStatus ==
                        bookDeviceStatus.approvedAndBooked ? (
                        <Tab>Gửi email sự cố</Tab>
                    ) : (
                        <></>
                    )}
                </TabList>

                <TabPanel>
                    <Card.Body>
                        {isLoading ? (
                            <div>Đang tải...</div>
                        ) : isError ? (
                            <div>Có lỗi xảy ra: ${error.message}</div>
                        ) : bookDeviceItem.data == null ? (
                            <div>Danh mục không tồn tại</div>
                        ) : (
                            <Formik
                                enableReinitialize
                                validationSchema={schema}
                                onSubmit={(values) => handleEdit(values)}
                                initialValues={{
                                    // title:
                                    //     bookDeviceItem.data && bookDeviceItem.data.title
                                    //         ? bookDeviceItem.data.title
                                    //         : '',
                                    description:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.description
                                            ? bookDeviceItem.data.description
                                            : '',
                                    deviceId:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.device != null
                                            ? {
                                                  value: bookDeviceItem.data
                                                      .device.id,
                                                  label:
                                                      bookDeviceItem.data.device
                                                          .name +
                                                      ' - ' +
                                                      bookDeviceItem.data.device
                                                          .deviceCode +
                                                      ' - ' +
                                                      departmentNameOrigin
                                              }
                                            : null,
                                    device:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.device
                                            ? bookDeviceItem.data.device
                                            : null,
                                    // deviceName:
                                    //     bookDeviceItem.data &&
                                    //     bookDeviceItem.data.device != null
                                    //         ? bookDeviceItem.data.device.name
                                    //         : '',
                                    bookColor:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.bookColor
                                            ? bookDeviceItem.data.bookColor
                                            : 'blue',
                                    bookStatus:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.bookStatus
                                            ? bookDeviceItem.data.bookStatus
                                            : bookDeviceStatus.waitingForApprove,
                                    borrowDepartmentName:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.borrowDepartmentName
                                            ? bookDeviceItem.data
                                                  .borrowDepartmentName
                                            : '',
                                    // isActive:
                                    //     bookDeviceItem.data &&
                                    //     bookDeviceItem.data.isActive
                                    //         ? bookDeviceItem.data.isActive
                                    //         : false,
                                    accountId:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.accountId
                                            ? bookDeviceItem.data.accountId
                                            : '',
                                    accountApproverId:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.accountApproverId
                                            ? bookDeviceItem.data
                                                  .accountApproverId
                                            : '',
                                    account:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.account
                                            ? bookDeviceItem.data.account
                                            : null,
                                    accountApprover:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.accountApprover
                                            ? bookDeviceItem.data
                                                  .accountApprover
                                            : null,
                                    bookTime:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.bookTime
                                            ? new Date(
                                                  bookDeviceItem.data.bookTime
                                              )
                                            : new Date(),
                                    endBookTime:
                                        bookDeviceItem.data &&
                                        bookDeviceItem.data.endBookTime
                                            ? new Date(
                                                  bookDeviceItem.data.endBookTime
                                              )
                                            : new Date()
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    errors,
                                    setFieldValue
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tiêu đề"
                                            type="text"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                            disabled={
                                                values.accountId ==
                                                    inforAccount.id &&
                                                accountRoleBooker &&
                                                values.bookStatus ==
                                                    bookDeviceStatus.waitingForApprove
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group> */}

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Mục đích sử dụng
                                            </Form.Label>
                                            <Col sm="10">
                                                <Editor
                                                    name="description"
                                                    value={values.description}
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            'description',
                                                            value
                                                        );
                                                    }}
                                                    disabled={
                                                        values.accountId ==
                                                            inforAccount.id &&
                                                        accountRoleBooker &&
                                                        values.bookStatus ==
                                                            bookDeviceStatus.waitingForApprove
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Col>
                                        </Form.Group>

                                        {values.accountId == inforAccount.id &&
                                        accountRoleBooker &&
                                        values.bookStatus ==
                                            bookDeviceStatus.waitingForApprove ? (
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                            >
                                                <Form.Label className="col-sm-2 col-form-label">
                                                    Trang thiết bị
                                                </Form.Label>
                                                <Col sm="10">
                                                    <AsyncPaginate
                                                        name="deviceId"
                                                        id="deviceId"
                                                        placeholder="Chọn trang thiết bị"
                                                        value={values.deviceId}
                                                        loadOptions={
                                                            loadOptions
                                                        }
                                                        isClearable
                                                        styles={
                                                            errors.deviceId !==
                                                            undefined
                                                                ? styleSelectError
                                                                : styleSelectDefault
                                                        }
                                                        onChange={(
                                                            selectedOption
                                                        ) => {
                                                            const event = {
                                                                target: {
                                                                    name: 'deviceId',
                                                                    value: selectedOption
                                                                }
                                                            };
                                                            handleChange(event);
                                                        }}
                                                        additional={{
                                                            page: 1
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            display: 'block',
                                                            color: '#dc3545'
                                                        }}
                                                        className="invalid-feedback"
                                                    >
                                                        {errors.deviceId}
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                        ) : (
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                            >
                                                <Form.Label className="col-sm-2 col-form-label">
                                                    Trang thiết bị
                                                </Form.Label>
                                                <Col sm="10">
                                                    <Form.Control
                                                        placeholder="Trang thiết bị"
                                                        type="text"
                                                        name="deviceName"
                                                        disabled={true}
                                                        value={
                                                            values.device
                                                                ? values.device
                                                                      .name +
                                                                  ' - ' +
                                                                  values.device
                                                                      .deviceCode +
                                                                  ' - ' +
                                                                  departmentNameOrigin
                                                                : ''
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        )}

                                        {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thời gian đặt thiết bị *
                                    </Form.Label>
                                    <Col sm="10">
                                        <DateRangePicker
                                            onApply={(event, picker) =>
                                                handleApply(
                                                    event,
                                                    picker,
                                                    setFieldValue
                                                )
                                            }
                                            onCancel={(event, picker) =>
                                                handleCancel(
                                                    event,
                                                    picker,
                                                    setFieldValue
                                                )
                                            }
                                            // onCancel={handleCancel}
                                            initialSettings={{
                                                // autoUpdateInput: false,
                                                locale: {
                                                    format: 'DD/MM/YYYY hh:mm A',
                                                    cancelLabel: 'Xóa',
                                                    customRangeLabel:
                                                        'Tùy chọn',
                                                    applyLabel: 'Chọn'
                                                },
                                                ranges: rangeDateBooking,
                                                startDate: moment(
                                                    values.startDate
                                                ).format('DD/MM/YYYY hh:mm A'),
                                                endDate: moment(
                                                    values.endDate
                                                ).format('DD/MM/YYYY hh:mm A'),
                                                timePicker: true
                                            }}
                                        >
                                            <input
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value
                                                            .length === 0
                                                    ) {
                                                        // setDates({
                                                        //     startDate: null,
                                                        //     endDate: null
                                                        // });

                                                        setFieldValue(
                                                            'startDate',
                                                            null
                                                        );
                                                        setFieldValue(
                                                            'endDate',
                                                            null
                                                        );
                                                    }
                                                }}
                                                type="text"
                                                className="form-control"
                                                disabled={
                                                    values.accountId ==
                                                        inforAccount.id &&
                                                    accountRoleBooker &&
                                                    values.bookStatus ==
                                                        bookDeviceStatus.waitingForApprove
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </DateRangePicker>
                                    </Col>
                                </Form.Group> */}

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Bắt đầu đặt thiết bị lúc *
                                            </Form.Label>
                                            <Col sm="10">
                                                <DatePicker
                                                    locale="vi"
                                                    minDate={new Date()}
                                                    selected={values.bookTime}
                                                    onChange={(date) => {
                                                        setFieldValue(
                                                            'bookTime',
                                                            date,
                                                            true
                                                        );
                                                    }}
                                                    readOnly={
                                                        values.accountId ==
                                                            inforAccount.id &&
                                                        accountRoleBooker &&
                                                        values.bookStatus ==
                                                            bookDeviceStatus.waitingForApprove
                                                            ? false
                                                            : true
                                                    }
                                                    timeInputLabel="Thời gian:"
                                                    dateFormat={
                                                        DATE_TIME_REACT_FORMAT
                                                    }
                                                    showTimeInput
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Kết thúc đặt thiết bị lúc *
                                            </Form.Label>
                                            <Col sm="10">
                                                <DatePicker
                                                    locale="vi"
                                                    selected={
                                                        values.endBookTime
                                                    }
                                                    minDate={new Date()}
                                                    onChange={(date) => {
                                                        setFieldValue(
                                                            'endBookTime',
                                                            date,
                                                            true
                                                        );
                                                    }}
                                                    readOnly={
                                                        values.accountId ==
                                                            inforAccount.id &&
                                                        accountRoleBooker &&
                                                        values.bookStatus ==
                                                            bookDeviceStatus.waitingForApprove
                                                            ? false
                                                            : true
                                                    }
                                                    timeInputLabel="Thời gian:"
                                                    dateFormat={
                                                        DATE_TIME_REACT_FORMAT
                                                    }
                                                    showTimeInput
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Trạng thái đặt lịch
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    placeholder="Trạng thái đặt lịch"
                                                    type="text"
                                                    name="bookStatusText"
                                                    value={
                                                        objectBookDeviceStatus.find(
                                                            (x) =>
                                                                x.value ==
                                                                values.bookStatus
                                                        )
                                                            ? objectBookDeviceStatus.find(
                                                                  (x) =>
                                                                      x.value ==
                                                                      values.bookStatus
                                                              ).label
                                                            : ''
                                                    }
                                                    onChange={handleChange}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Form.Group>

                                        {values.account ? (
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                            >
                                                <Form.Label className="col-sm-2 col-form-label">
                                                    Người đặt lịch
                                                </Form.Label>
                                                <Col sm="10">
                                                    <Form.Control
                                                        placeholder={
                                                            'Người đặt lịch'
                                                        }
                                                        type="text"
                                                        name={'booker'}
                                                        disabled
                                                        value={
                                                            values.account
                                                                .fullName
                                                                ? values.account
                                                                      .fullName
                                                                : values.account
                                                                      .username
                                                        }
                                                    />
                                                </Col>
                                            </Form.Group>
                                        ) : (
                                            <></>
                                        )}

                                        {values.accountApprover ? (
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                            >
                                                <Form.Label className="col-sm-2 col-form-label">
                                                    Người phê duyệt
                                                </Form.Label>
                                                <Col sm="10">
                                                    <Form.Control
                                                        placeholder={
                                                            'Người phê duyệt'
                                                        }
                                                        type="text"
                                                        name={'approver'}
                                                        disabled
                                                        value={
                                                            values
                                                                .accountApprover
                                                                .fullName
                                                                ? values
                                                                      .accountApprover
                                                                      .fullName
                                                                : values
                                                                      .accountApprover
                                                                      .username
                                                        }
                                                    />
                                                </Col>
                                            </Form.Group>
                                        ) : (
                                            <></>
                                        )}

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Khoa mượn
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    placeholder="Khoa mượn"
                                                    type="text"
                                                    name="borrowDepartmentName"
                                                    value={
                                                        values.borrowDepartmentName
                                                    }
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Form.Group>

                                        {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Chọn màu để hiển thị trên lịch
                                    </Form.Label>
                                    <Col sm="10">
                                        <ColorPicker
                                            color={values.bookColor}
                                            onChange={(color) =>
                                                setFieldValue(
                                                    'bookColor',
                                                    color.hex
                                                )
                                            }
                                        />
                                    </Col>
                                </Form.Group> */}

                                        {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái kích hoạt
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            disabled={
                                                values.accountId ==
                                                    inforAccount.id &&
                                                accountRoleBooker &&
                                                values.bookStatus ==
                                                    bookDeviceStatus.waitingForApprove
                                                    ? false
                                                    : true
                                            }
                                            id="checkboxPrimary1"
                                            type="checkbox"
                                            placeholder="Trạng thái"
                                            name="isActive"
                                            checked={values.isActive}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary1">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group> */}
                                        <Form.Group as={Row} className="mb-3">
                                            <Col md={{span: 10, offset: 2}}>
                                                <ButtonGroup className="mb-2">
                                                    {values.accountId ==
                                                        inforAccount.id &&
                                                    accountRoleBooker &&
                                                    values.bookStatus ==
                                                        bookDeviceStatus.waitingForApprove ? (
                                                        <>
                                                            <Button
                                                                type="submit"
                                                                theme="primary"
                                                            >
                                                                Sửa
                                                            </Button>
                                                            &nbsp;&nbsp;&nbsp;
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {values.device &&
                                                    values.device.accountId ==
                                                        inforAccount.id &&
                                                    accountRoleApprover &&
                                                    values.bookStatus ==
                                                        bookDeviceStatus.waitingForApprove ? (
                                                        <>
                                                            <Button
                                                                theme="primary"
                                                                onClick={() =>
                                                                    handleApprove(
                                                                        bookDeviceStatus.approvedAndBooked
                                                                    )
                                                                }
                                                            >
                                                                Phê duyệt
                                                            </Button>
                                                            &nbsp;&nbsp;&nbsp;
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {values.device &&
                                                    values.device.accountId ==
                                                        inforAccount.id &&
                                                    accountRoleApprover &&
                                                    values.bookStatus ==
                                                        bookDeviceStatus.waitingForApprove ? (
                                                        <>
                                                            <Button
                                                                theme="primary"
                                                                onClick={() =>
                                                                    handleApprove(
                                                                        bookDeviceStatus.notApprove
                                                                    )
                                                                }
                                                            >
                                                                Không phê duyệt
                                                            </Button>
                                                            &nbsp;&nbsp;&nbsp;
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {values.accountApproverId ==
                                                        inforAccount.id &&
                                                    accountRoleApprover &&
                                                    values.bookStatus ==
                                                        bookDeviceStatus.approvedAndBooked &&
                                                    moment().diff(
                                                        values.bookTime
                                                    ) < 0 ? (
                                                        <>
                                                            <Button
                                                                theme="primary"
                                                                onClick={() =>
                                                                    handleApprove(
                                                                        bookDeviceStatus.unApprove
                                                                    )
                                                                }
                                                            >
                                                                Hủy phê duyệt
                                                            </Button>
                                                            &nbsp;&nbsp;&nbsp;
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <Button
                                                        onClick={() =>
                                                            history.goBack()
                                                        }
                                                        theme="secondary"
                                                    >
                                                        Hủy
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Card.Body>
                </TabPanel>
                {bookDeviceItem &&
                bookDeviceItem.data &&
                bookDeviceItem.data.bookStatus &&
                bookDeviceItem.data.accountId &&
                bookDeviceItem.data.accountId == inforAccount.id &&
                bookDeviceItem.data.bookStatus ==
                    bookDeviceStatus.approvedAndBooked ? (
                    <TabPanel>
                        <DeviceMaintenanceAdd
                            // plantItemRefetch={() => plantItem.refetch()}
                            // listGrowthProcedureRefetch={() =>
                            //     listGrowthProcedure.refetch()
                            // }
                            // lstPlantGrowProcedureId={
                            //     plantItem.data ? plantItem.data.lstGrowthId : []
                            // }
                            bookDeviceId={bookDeviceId}
                            accountBookerId={
                                bookDeviceItem.data &&
                                bookDeviceItem.data.accountId
                                    ? bookDeviceItem.data.accountId
                                    : ''
                            }
                            accountManageDeviceId={
                                bookDeviceItem.data &&
                                bookDeviceItem.data.accountApproverId
                                    ? bookDeviceItem.data.accountApproverId
                                    : bookDeviceItem.data &&
                                      bookDeviceItem.data.device
                                    ? bookDeviceItem.data.device.accountId
                                    : ''
                            }
                            deviceId={
                                bookDeviceItem.data &&
                                bookDeviceItem.data.device &&
                                bookDeviceItem.data.device.id
                                    ? bookDeviceItem.data.device.id
                                    : ''
                            }
                            deviceName={
                                bookDeviceItem.data &&
                                bookDeviceItem.data.device &&
                                bookDeviceItem.data.device.name
                                    ? bookDeviceItem.data.device.name
                                    : ''
                            }
                        />
                    </TabPanel>
                ) : (
                    <></>
                )}
            </Tabs>
        </Card>
    );
};

export default BookDeviceEdit;
