import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {REPORT_POST_CONST} from '../constants';
import {Get} from '../../utils/httpProvider';
import {POST_API} from '../../utils/apiUrl';

export const getCountPostByConditionAction = createAsyncThunk(
    REPORT_POST_CONST.GET_PAGING,
    async (model) => {
        return Get(POST_API.COUNT_POST_BY_CONDITION_API, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);
export const getStatisticPostSynthticAction = createAsyncThunk(
    REPORT_POST_CONST.NAME_SLICE_SYNTHENTIC,
    async (model) => {
        return Get(POST_API.STATISTIC_POST_SYNTHETIC_API, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);
const initialState = {
    listCountPostByCondition: [],
    listStatisticPostSynthtic: []
};
export const reportPostSlice = createSlice({
    name: REPORT_POST_CONST.NAME_SLICE,
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(
                getCountPostByConditionAction.fulfilled,
                (state, action) => {
                    if (action.payload && action.payload.$values) {
                        state.listCountPostByCondition = action.payload.$values;
                    }
                }
            )
            .addCase(
                getStatisticPostSynthticAction.fulfilled,
                (state, action) => {
                    if (action.payload && action.payload.$values) {
                        state.listStatisticPostSynthtic =
                            action.payload.$values;
                    }
                }
            );
    }
});

export default reportPostSlice.reducer;
