import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
// import {useDispatch} from 'react-redux';
// import {Gatekeeper} from 'gatekeeper-client-sdk';
// import {loadUser, logoutUser} from '@store/reducers/auth';
import Dashboard from '@pages/Dashboard';
import Profile from '@pages/profile/Profile';
import Account from '@pages/account/Account';
import AccountAdd from '@pages/account/AccountAdd';
import AccountEdit from '@pages/account/AccountEdit';

import EmailConfig from '@pages/emailConfig/EmailConfig';
import EmailConfigAdd from '@pages/emailConfig/EmailConfigAdd';
import EmailConfigEdit from '@pages/emailConfig/EmailConfigEdit';

import Log from '@pages/log/Log';

import LoginHistory from '@app/pages/report/countLogin/LoginHistory';
import StatisticLoginHistory from '@app/pages/report/countLogin/StatisticLoginHistory';

import ReportPostByWebAnalysis from '@pages/report/ReportPostByWebAnalysis';
import ReportPostQuantityCreateBy from '@app/pages/report/ReportPostQuantityCreateBy';
import ReportPostCreateBy from '@app/pages/report/ReportPostCreateBy';
import ReportViewDetail from '@app/pages/report/reportViewDetail/ReportViewDetail';
import ReportPostPublishTimeAndDataType from '@pages/report/ReportPostPublishTimeAndDataType';
import RepostAdvertising from '@pages/report/RepostAdvertising';

import Header from './header/Header';
import Footer from './footer/Footer';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import ReportPost from '../../pages/report/ReportPost';
import ReportPostSynthetic from '../../pages/report/ReportPostSynthetic';
import {URI_INTERNAL} from '../../utils/pathLocations';

import Device from '@app/pages/device/Device';
import DeviceAdd from '@app/pages/device/DeviceAdd';
import DeviceEdit from '@app/pages/device/DeviceEdit';
import BookDeviceAdd from '@app/pages/bookDevice/BookDeviceAdd';
import BookDevice from '@app/pages/bookDevice/BookDevice';
import BookDeviceEdit from '@app/pages/bookDevice/BookDeviceEdit';
import BookDeviceForApprover from '@app/pages/bookDevice/BookDeviceForApprover';
import DeviceMaintenanceAdd from '@app/pages/deviceMaintenance/DeviceMaintenanceAdd';
import DeviceMaintenanceEdit from '@app/pages/deviceMaintenance/DeviceMaintenanceEdit';
import DeviceMaintenance from '@app/pages/deviceMaintenance/DeviceMaintenance';

// import PageLoading from '../../components/page-loading/PageLoading';

const Main = () => {
    // const [appLoadingState, updateAppLoading] = useState(false);
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     updateAppLoading(true);
    //     const fetchProfile = async () => {
    //         try {
    //             const response = await Gatekeeper.getProfile();
    //             dispatch(loadUser(response));
    //             updateAppLoading(false);
    //         } catch (error) {
    //             dispatch(logoutUser());
    //             updateAppLoading(false);
    //         }
    //     };
    //     fetchProfile();
    //     return () => {};
    // }, []);

    // useEffect(() => {

    // }, []);

    // const token = localStorage.getItem(TOKEN_KEY);

    // // const [permitted, setPermitted] = useState()

    // if (token) {
    // }

    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');

    document.body.className += ' sidebar-mini';

    const toggleMenuSidebar = () => {
        document.body.classList.add('sidebar-collapse');
        document.body.classList.remove('sidebar-open');
    };

    const template = (
        <>
            <Header />

            <MenuSidebar />

            <div className="content-wrapper">
                <div className="pt-3" />
                <section className="content">
                    <Switch>
                        <Route exact path="/profile" component={Profile} />
                        <Route exact path="/" component={BookDevice} />
                        <Route
                            exact
                            path={URI_INTERNAL.ACCOUNT_PATH}
                            component={Account}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.ACCOUNT_PATH_ADD}
                            component={AccountAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.ACCOUNT_PATH_EDIT}
                            component={AccountEdit}
                        />
                        
                        <Route
                            exact
                            path={URI_INTERNAL.EMAILCONFIG_PATH}
                            component={EmailConfig}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.EMAILCONFIG_CREATE_PATH}
                            component={EmailConfigAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.EMAILCONFIG_EDIT_PATH}
                            component={EmailConfigEdit}
                        />
                       
                        <Route
                            exact
                            path={URI_INTERNAL.LOGIN_HISTORY_PATH}
                            component={LoginHistory}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.STATISTIC_HISTORY_PATH}
                            component={StatisticLoginHistory}
                        />
                       
                        <Route
                            exact
                            path={URI_INTERNAL.LOG_PATH}
                            component={Log}
                        />
                       
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_PATH}
                            component={ReportPost}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_SYNTHETIC_PATH}
                            component={ReportPostSynthetic}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_WEBANALYSIS_PATH}
                            component={ReportPostByWebAnalysis}
                        />
                        <Route
                            exact
                            path={
                                URI_INTERNAL.REPORT_POST_EXCEL_QUANTITY_CREATE_BY
                            }
                            component={ReportPostQuantityCreateBy}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_EXCEL_CREATE_BY}
                            component={ReportPostCreateBy}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_VIEW_DETAIL}
                            component={ReportViewDetail}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_BY_DATETYPE}
                            component={ReportPostPublishTimeAndDataType}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_ADVERTISING_ANALYSIS_PATH}
                            component={RepostAdvertising}
                        />

                        <Route
                            exact
                            path={URI_INTERNAL.DEVICE_ADD_PATH}
                            component={DeviceAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.DEVICE_EDIT_PATH}
                            component={DeviceEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.DEVICE_PATH}
                            component={Device}
                        />

                        <Route
                            exact
                            path={URI_INTERNAL.BOOKDEVICE_ADD_PATH}
                            component={BookDeviceAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.BOOKDEVICE_EDIT_PATH}
                            component={BookDeviceEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.BOOKDEVICE_PATH}
                            component={BookDevice}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.BOOKDEVICEFORAPPROVER_PATH}
                            component={BookDeviceForApprover}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.DEVICEMAINTENANCE_ADD_PATH}
                            component={DeviceMaintenanceAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.DEVICEMAINTENANCE_EDIT_PATH}
                            component={DeviceMaintenanceEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.DEVICEMAINTENANCE_PATH}
                            component={DeviceMaintenance}
                        />
                    </Switch>
                </section>
            </div>
            <Footer />
            <div
                id="sidebar-overlay"
                role="presentation"
                onClick={toggleMenuSidebar}
                onKeyDown={() => {}}
            />
        </>
    );

    // if (appLoadingState) {
    //     template = <PageLoading />;
    // } else {
    //     template = (
    //         <>
    //             <Header toggleMenuSidebar={toggleMenuSidebar} />

    //             <MenuSidebar />

    //             <div className="content-wrapper">
    //                 <div className="pt-3" />
    //                 <section className="content">
    //                     <Switch>
    //                         <Route exact path="/profile" component={Profile} />
    //                         <Route exact path="/" component={Dashboard} />
    //                     </Switch>
    //                 </section>
    //             </div>
    //             <Footer />
    //             <div
    //                 id="sidebar-overlay"
    //                 role="presentation"
    //                 onClick={toggleMenuSidebar}
    //                 onKeyDown={() => {}}
    //             />
    //         </>
    //     );
    // }

    return <div className="wrapper">{template}</div>;
};

export default Main;
