import React, {useState} from 'react';
import Pagination from 'react-js-pagination';
import {useSelector, useDispatch} from 'react-redux';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
    accountPagingAction,
    deleteAccountAction,
    filterListAccount
} from '@store/reducers/account';
import Table from 'react-bootstrap/Table';
import {createUrlWithParam} from '../../utils/helper';
import {URI_INTERNAL} from '../../utils/pathLocations';
import {objectShowNumber, messageToast} from '../../utils/define';
import {ConfirmModal} from '../../modules/modal/ConfirmModal';

const Account = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const valueSearch = useSelector((state) => state.search.valueSearch);
    const totalAccount = useSelector((state) => state.account.totalAccount);
    const listAccount = useSelector((state) => state.account.listAccount);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');

    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [idWantDelete, setIdWantDelete] = useState('');
    const [confirmDelMessage, setConfirmDelMessage] = useState('');

    const paramUrl = new URLSearchParams(location.search.substring(1));
    if (
        paramUrl.get('page') != null &&
        parseInt(paramUrl.get('page'), 10) !== page
    ) {
        const pageResult = parseInt(paramUrl.get('page'), 10);
        setPage(pageResult);
    }
    if (
        paramUrl.get('size') != null &&
        parseInt(paramUrl.get('size'), 10) !== pageSize
    ) {
        const sizeResult = parseInt(paramUrl.get('size'), 10);
        setPageSize(sizeResult);
    }
    if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
        setSearch(paramUrl.get('search'));
    }

    const model = {
        query: search
    };

    const replaceHistory = async (Page, Size, Search) => {
        const modelPost = {
            Keyword: Search,
            PageIndex: Page,
            PageSize: Size
        };
        await dispatch(accountPagingAction(modelPost));

        history.replace(
            createUrlWithParam(URI_INTERNAL.ACCOUNT_PATH, {
                page: Page,
                size: Size,
                search: Search
            })
        );
    };

    React.useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            model.query = valueSearch;
            setSearch(model.query);
        }
        replaceHistory(page, pageSize, model.query);
    }, [valueSearch]);

    const changePage = (number) => {
        setPage(number);
        replaceHistory(number, pageSize, search);
    };

    const onChangeShowNumber = (e) => {
        setPageSize(e.target.value);
        replaceHistory(page, e.target.value, search);
    };

    const deleteAction = (id, name) => {
        setIdWantDelete(id);
        setConfirmDelMessage(name);
        setOpenConfirmDeleteModal(true);
    };

    const acceptDelete = async () => {
        if (idWantDelete !== '') {
            try {
                const result = await dispatch(
                    deleteAccountAction(idWantDelete)
                );
                if (result.payload.errors) {
                    toast.error(result.payload.errors.content[0]);
                }
                if (result.payload === 200) {
                    await dispatch(filterListAccount(idWantDelete));
                    toast.success(messageToast.messageToastDeleteSuccess);
                    // history.goBack();

                    if (
                        listAccount != null &&
                        listAccount.length === 1 &&
                        page !== 1
                    ) {
                        history.push(
                            createUrlWithParam(URI_INTERNAL.DEVICE_PATH, {
                                page: page - 1,
                                size: pageSize,
                                search: search
                            })
                        );
                    }
                }
            } catch (error) {
                toast.error(messageToast.messageToastTryCatch);
            }
            setOpenConfirmDeleteModal(false);
        }
    };

    const cancelDelete = () => {
        setIdWantDelete('');
        setOpenConfirmDeleteModal(false);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"> Danh sách tài khoản</h3>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="mr-2"
                                    >
                                        Hiển thị :
                                    </label>
                                    <select
                                        className="form-control"
                                        value={pageSize}
                                        onChange={(e) => onChangeShowNumber(e)}
                                    >
                                        {objectShowNumber.map((item, key) => (
                                            <option
                                                key={Number(key)}
                                                value={item.value}
                                            >
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                </form>
                                {/* <NavLink
                                    className="nav-link-reject nav-link"
                                    to={URI_INTERNAL.ACCOUNT_PATH_ADD}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        Thêm mới
                                    </button>
                                </NavLink> */}
                            </div>
                            <div
                                id="example2_wrapper"
                                className="dataTables_wrapper dt-bootstrap4"
                                style={{marginTop: '20px'}}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting">
                                                        STT
                                                    </th>
                                                    <th className="sorting">
                                                        Tài khoản
                                                    </th>
                                                    {/* <th className="sorting">
                                                        Họ tên
                                                    </th>
                                                    <th className="sorting">
                                                        Bút danh
                                                    </th>
                                                    <th className="sorting">
                                                        Email
                                                    </th> */}
                                                    <th className="sorting">
                                                        Trạng thái
                                                    </th>
                                                    <th className="sorting">
                                                        Chức năng
                                                    </th>
                                                </tr>
                                            </thead>
                                            {listAccount != null &&
                                            listAccount !== undefined &&
                                            listAccount.length > 0 ? (
                                                <tbody>
                                                    {listAccount.map(
                                                        (item, index) => {
                                                            return (
                                                                <tr
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="odd"
                                                                >
                                                                    <td className="dtr-control sorting_1">
                                                                        {index +
                                                                            1 +
                                                                            (page -
                                                                                1) *
                                                                                pageSize}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.username
                                                                        }
                                                                    </td>
                                                                    {/* <td>
                                                                    {
                                                                        item.fullname
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.pseudonym
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.email}
                                                                </td> */}
                                                                    <td>
                                                                        <div
                                                                            className={
                                                                                item.isActive
                                                                                    ? 'icheck-primary'
                                                                                    : 'icheck-danger'
                                                                            }
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                id={
                                                                                    item.id
                                                                                }
                                                                                name={
                                                                                    item.id
                                                                                }
                                                                                defaultChecked
                                                                            />
                                                                            <label
                                                                                htmlFor={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {item.isActive
                                                                                    ? 'Đã kích hoạt'
                                                                                    : 'Chưa kích hoạt'}
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <ul className="nav">
                                                                            <li className="nav-item">
                                                                                <NavLink
                                                                                    to={createUrlWithParam(
                                                                                        URI_INTERNAL.ACCOUNT_PATH_EDIT,
                                                                                        {
                                                                                            id: item.id
                                                                                        }
                                                                                    )}
                                                                                    exact
                                                                                    className="nav-link"
                                                                                >
                                                                                    <i
                                                                                        title="Sửa"
                                                                                        className="nav-icon font-icon fas fa-pen"
                                                                                    />
                                                                                </NavLink>
                                                                            </li>
                                                                            {/* <li className="nav-item">
                                                                                <NavLink
                                                                                    onClick={() =>
                                                                                        deleteAction(
                                                                                            item.id,
                                                                                            item.username
                                                                                        )
                                                                                    }
                                                                                    to
                                                                                    exact
                                                                                    className="nav-link"
                                                                                >
                                                                                    <i
                                                                                        title="Xóa"
                                                                                        className="nav-icon font-icon far fa-trash-alt"
                                                                                    />
                                                                                </NavLink>
                                                                            </li> */}
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr className="txt-center">
                                                        <td colSpan="12">
                                                            Không có dữ liệu
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                            <tfoot>
                                                <tr role="row">
                                                    <th className="sorting">
                                                        STT
                                                    </th>
                                                    <th className="sorting">
                                                        Tài khoản
                                                    </th>
                                                    {/* <th className="sorting">
                                                        Họ tên
                                                    </th>
                                                    <th className="sorting">
                                                        Email
                                                    </th> */}
                                                    <th className="sorting">
                                                        Trạng thái
                                                    </th>
                                                    <th className="sorting">
                                                        Chức năng
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="example2_paginate"
                                        >
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                innerClass="pagination pagination-sm no-margin pull-right"
                                                activePage={page}
                                                itemsCountPerPage={Number(
                                                    pageSize
                                                )}
                                                totalItemsCount={totalAccount}
                                                pageRangeDisplayed={5}
                                                onChange={(number) =>
                                                    changePage(number)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ConfirmModal
                                isOpen={openConfirmDeleteModal}
                                confirmType="delete"
                                handleAccept={() => acceptDelete()}
                                handleCancel={() => cancelDelete()}
                                content={confirmDelMessage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
