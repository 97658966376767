import React, {useState, useEffect} from 'react';
import Pagination from 'react-js-pagination';
import {Form, Col, Row, ButtonGroup} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import {toast} from 'react-toastify';
import {useMutation, useQueryClient, useQuery} from 'react-query';
import {Image} from 'react-bootstrap';
import {
    PLANT_GROWTH_PROCEDURE_API,
    PLANT_API,
    BaseGatewayImage,
    DEVICEMAINTENANCE_API
} from '@app/utils/apiUrl';
import {DeleteSingle, Get, Put} from '@app/utils/httpProvider';
import {
    createUrlWithParam,
    formatDateTimeCrawler,
    getInfomationAccountFromToken,
    isValidUrl
} from '../../utils/helper';
import {URI_INTERNAL} from '../../utils/pathLocations';
import {
    objectShowNumber,
    messageToast,
    status200,
    dataChoosePlantProcedure,
    objectDataChoosePlantProcedure,
    role
} from '../../utils/define';
import {ConfirmModal} from '../../modules/modal/ConfirmModal';

const DeviceMaintenance = (props) => {
    // let accountBookerId = props.accountBookerId;
    let accountManageDeviceId = props.accountManageDeviceId;
    // let bookDeviceId = props.bookDeviceId;
    let deviceId = props.deviceId;
    let deviceName = props.deviceName;

    const inforAccount = getInfomationAccountFromToken();
    // let accountRoleBooker = false;

    // if (inforAccount && inforAccount.role) {
    //     const userRole = inforAccount.role.split(', ');
    //     accountRoleBooker =
    //         userRole.includes(role.Admin) || userRole.includes(role.Booker);
    // }
    // let plantItem = props.plantItem ? props.plantItem : {};

    // let plantItem = props.plantItem;
    // let plantName = plantItem.data && plantItem.data.name
    // ? plantItem.data.name
    // : '';

    const history = useHistory();
    const location = useLocation();
    const queryClient = useQueryClient();
    const valueSearch = useSelector((state) => state.search.valueSearch);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');
    // const [lstPlantGrowProcedureId, setLstPlantGrowProcedureId] = useState(plantItem.data && lantItem.data ? plantItem.data.lstGrowthId : []);
    // const [lstPlantGrowProcedureId, setLstPlantGrowProcedureId] = useState(props.lstPlantGrowProcedureId);

    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [idWantDelete, setIdWantDelete] = useState('');
    const [confirmDelMessage, setConfirmDelMessage] = useState('');
    const [dataChoose, setDataChoose] = useState(dataChoosePlantProcedure.all);

    const deleteFunc = useMutation((value) =>
        DeleteSingle(DEVICEMAINTENANCE_API.DELETE_ITEM, value, null)
    );

    // const editFunc = useMutation((value) =>
    //     Put(PLANT_API.UPDATE_ITEM_BY_SPECIFIC, null, value)
    // );

    // const paramUrl = new URLSearchParams(location.search.substring(1));
    // const plantId = paramUrl.get('plantId');
    // const plantName = paramUrl.get('plantName');
    // let lstPlantGrowProcedureId = [];

    // if (paramUrl.getAll('growthId') != null && paramUrl.getAll('growthId').length > 0) {
    //     lstPlantGrowProcedureId = JSON.parse(paramUrl.getAll('growthId'))
    // }

    // if (
    //     paramUrl.get('page') != null &&
    //     parseInt(paramUrl.get('page'), 10) !== page
    // ) {
    //     const pageResult = parseInt(paramUrl.get('page'), 10);
    //     setPage(pageResult);
    // }
    // if (
    //     paramUrl.get('size') != null &&
    //     parseInt(paramUrl.get('size'), 10) !== pageSize
    // ) {
    //     const sizeResult = parseInt(paramUrl.get('size'), 10);
    //     setPageSize(sizeResult);
    // }
    // if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
    //     setSearch(paramUrl.get('search'));
    // }

    const {isLoading, isError, error, data, refetch} = useQuery(
        ['deviceMaintenanceData', valueSearch, page, pageSize],
        () => {
            return Get(DEVICEMAINTENANCE_API.GET_PAGING, null, {
                Keyword: valueSearch ? valueSearch : '',
                PageIndex: page,
                PageSize: pageSize,
                deviceId: deviceId ? deviceId : '',
                // bookDeviceId: bookDeviceId ? bookDeviceId : '',
                accountManageDeviceId: accountManageDeviceId
                    ? accountManageDeviceId
                    : ''
            });
        }
    );

    // const lstSortedPlantProcedure = useQuery(
    //     ['lstSortedPlantProcedureData'],
    //     () =>
    //         Get(PLANT_API.GET_ALL_BY_CONDITION, null, {
    //             LstGrowthId: lstPlantGrowProcedureId,
    //         })
    // );

    // console.log("lstSortedPlantProcedure", lstSortedPlantProcedure)

    useEffect(() => {
        refetch();
    }, [pageSize, page]);

    // useEffect(() => {
    //     lstSortedPlantProcedure.refetch();
    // }, [pageSize, page, lstPlantGrowProcedureId]);

    // const replaceHistory = async (Page, Size, Search, lstPlantGrowthId) => {
    //     history.replace(
    //         createUrlWithParam(URI_INTERNAL.PLANT_GROWTH_PROCEDURE_PATH, {
    //             page: Page,
    //             size: Size,
    //             search: Search,
    //             // plantId: plantId,
    //             // plantName: plantName,
    //             // growthId: JSON.stringify(lstPlantGrowthId)
    //         })
    //     );
    // };

    React.useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            // replaceHistory(1, pageSize, valueSearch, lstPlantGrowProcedureId);
        }
    }, [valueSearch]);

    const changePage = (number) => {
        setPage(number);
        // replaceHistory(number, pageSize, search, lstPlantGrowProcedureId);
    };

    const onChangeShowNumber = (e) => {
        setPageSize(e.target.value);
        // replaceHistory(page, e.target.value, search, lstPlantGrowProcedureId);
    };

    const deleteAction = (id, name) => {
        setIdWantDelete(id);
        setConfirmDelMessage(name);
        setOpenConfirmDeleteModal(true);
    };

    // const chooseProcedure = (id, event) => {
    //     // tránh nổi bọt, tránh bị click 2 lần: preventDefault
    //     event.preventDefault();
    //     let tempLstPlantGrowProcedureId = [...lstPlantGrowProcedureId];
    //     if (!tempLstPlantGrowProcedureId.includes(id)) {
    //         tempLstPlantGrowProcedureId.push(id);
    //     } else {
    //         tempLstPlantGrowProcedureId = tempLstPlantGrowProcedureId.filter(x => x != id);
    //     }

    //     setLstPlantGrowProcedureId(tempLstPlantGrowProcedureId);
    //     // replaceHistory(page, pageSize, search, lstPlantGrowProcedureId);
    // }

    // const updateGrowthProcedureInPlant = (plantId, lstGrowthId, event) => {
    //     // tránh nổi bọt, tránh bị click 2 lần: preventDefault
    //     event.preventDefault();

    //     let model = {};
    //     model.id = plantId;
    //     model.name = deviceName;
    //     model.lstGrowthId = lstGrowthId;
    //     // const modelUpdate = {
    //     //     data: model,
    //     //     id: areaId
    //     // };

    //     editFunc.mutate(model, {
    //         onSuccess: (res) => {
    //             if (res.status !== status200) {
    //                 toast.error(messageToast.messageToastUpdateError);
    //             } else {
    //                 toast.success(messageToast.messageToastUpdateSuccess);
    //                 queryClient.setQueryData(plantId + "-" + lstGrowthId.join(","), model);
    //                 props.plantItemRefetch();
    //                 props.listGrowthProcedureRefetch();
    //             }
    //         },
    //         onError: (res) => {
    //             toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
    //         }
    //     });
    // }

    const acceptDelete = async () => {
        if (idWantDelete !== '') {
            deleteFunc.mutate(idWantDelete, {
                onSuccess: (res) => {
                    if (res.status === 201 || res.status === 200) {
                        refetch();
                        // props.plantItemRefetch();
                        // props.listGrowthProcedureRefetch();
                        // let tempLstPlantGrowProcedureId =
                        //     lstPlantGrowProcedureId.filter(
                        //         (x) => x != idWantDelete
                        //     );
                        // setLstPlantGrowProcedureId(tempLstPlantGrowProcedureId);
                        toast.success(messageToast.messageToastDeleteSuccess);
                        if (data.data.length === 1 && page !== 1) {
                            setPage(page - 1);

                            // history.push(
                            //     createUrlWithParam(URI_INTERNAL.PLANT_GROWTH_PROCEDURE_PATH, {
                            //         page: page - 1,
                            //         size: pageSize,
                            //         search: search,
                            //         // plantId: plantId,
                            //         // plantName: plantName,
                            //         // growthId: JSON.stringify(lstPlantGrowProcedureId)
                            //     })
                            // );
                            // history.push(
                            //     createUrlWithParam(
                            //         URI_INTERNAL.PLANT_EDIT_PATH,
                            //         {
                            //             id: plantId
                            //         }
                            //     )
                            // );
                        }
                    } else {
                        toast.error(messageToast.messageToastDeleteError);
                    }
                },
                onError: (res) => {
                    toast.error(messageToast.messageToastTryCatch);
                }
            });

            // replaceHistory(page, pageSize, search);
            setOpenConfirmDeleteModal(false);
        }
    };

    const cancelDelete = () => {
        setIdWantDelete('');
        setOpenConfirmDeleteModal(false);
    };

    const handleSelectChoose = (e) => {
        setDataChoose(e.target.value);
    };

    const renderTr = (item, index) => {
        return (
            <tr key={Number(index)} className="odd">
                <td className="dtr-control sorting_1">
                    {index + 1 + (page - 1) * pageSize}
                </td>
                <td>
                    {item.imageThumbnail ? (
                        <Image
                            src={
                                isValidUrl(item.imageThumbnail)
                                    ? item.imageThumbnail
                                    : ` ${BaseGatewayImage}${item.imageThumbnail}`
                            }
                            thumbnail
                            width="50px"
                            height="50px"
                        />
                    ) : item.imagePath ? (
                        <Image
                            src={
                                isValidUrl(item.imagePath)
                                    ? item.imagePath
                                    : ` ${BaseGatewayImage}${item.imagePath}`
                            }
                            thumbnail
                            width="50px"
                            height="50px"
                        />
                    ) : null}
                </td>
                <td>
                    <div className={isFullScreen ? "style-desc clearfix color-des font-size-14" : "desc style-desc clearfix color-des font-size-14"}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: item.descriptionOfProblem
                            }}
                        />
                    </div>
                </td>
                {isFullScreen ? (
                    <td>
                        <div className={isFullScreen ? "style-desc clearfix color-des font-size-14" : "desc style-desc clearfix color-des font-size-14"}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: item.maintenanceOfProblem
                                }}
                            />
                        </div>
                    </td>
                ) : (
                    <></>
                )}
                <td>{formatDateTimeCrawler(item.problemTime)}</td>
                <td>
                    <ul className="nav">
                        <li className="nav-item">
                            <NavLink
                                to={createUrlWithParam(
                                    URI_INTERNAL.DEVICEMAINTENANCE_EDIT_PATH,
                                    {
                                        id: item.id
                                    }
                                )}
                                exact
                                className="nav-link"
                            >
                                <i
                                    title="Sửa"
                                    className="nav-icon font-icon fas fa-pen"
                                />
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                onClick={() => deleteAction(item.id, 'này')}
                                to
                                exact
                                className="nav-link"
                            >
                                <i
                                    title="Xóa"
                                    className="nav-icon font-icon far fa-trash-alt"
                                />
                            </NavLink>
                        </li>
                    </ul>
                </td>
            </tr>
        );
    };
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };
    return (
        <div
            className={
                isFullScreen ? 'container-fluid full-screen' : 'container-fluid'
            }
        >
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {' '}
                                Danh sách sự cố đặt lịch của{' '}
                                {deviceName
                                    ? deviceName
                                    : inforAccount.username}
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="mr-2"
                                    >
                                        Hiển thị :
                                    </label>
                                    <select
                                        className="form-control"
                                        value={pageSize}
                                        onChange={(e) => onChangeShowNumber(e)}
                                    >
                                        {objectShowNumber.map((item, key) => (
                                            <option
                                                key={Number(key)}
                                                value={item.value}
                                            >
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        type="button"
                                        className="btn btn-secondary ml-2"
                                        onClick={toggleFullScreen}
                                    >
                                        {isFullScreen
                                            ? 'Tắt toàn màn hình'
                                            : 'Bật toàn màn hình'}
                                    </button>
                                </form>

                                <NavLink
                                    className="nav-link-reject nav-link"
                                    to={createUrlWithParam(
                                        URI_INTERNAL.DEVICEMAINTENANCE_ADD_PATH,
                                        {
                                            // bookDeviceId: bookDeviceId,
                                            deviceId: deviceId ? deviceId : ''
                                            // accountManageDeviceId:
                                            //     accountManageDeviceId
                                            // plantName: plantName,
                                            // growthId: JSON.stringify(plantItem.data.lstGrowthId)
                                        }
                                    )}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        Thêm mới
                                    </button>
                                </NavLink>
                            </div>
                            {isLoading ? (
                                <div>Đang tải...</div>
                            ) : isError ? (
                                <div>Có lỗi xảy ra: ${error.message}</div>
                            ) : (
                                <div
                                    id="example2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4"
                                    // style={{
                                    //     marginTop:
                                    //         bookDeviceId != '' ? '0px' : '20px'
                                    // }}
                                >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th className="sorting">
                                                            Ảnh đại diện
                                                        </th>
                                                        <th className="sorting">
                                                            Nội dung sự cố
                                                        </th>
                                                        {isFullScreen ? (
                                                            <th className="sorting">
                                                                Biện pháp khắc
                                                                phục và phòng
                                                                ngừa
                                                            </th>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <th className="sorting">
                                                            Ngày phát sinh sự cố
                                                        </th>
                                                        <th className="sorting">
                                                            Hành động
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {data &&
                                                data.data != null &&
                                                data.data !== undefined &&
                                                data.data.length > 0 ? (
                                                    <tbody>
                                                        {data.data.map(
                                                            (item, index) =>
                                                                renderTr(
                                                                    item,
                                                                    index
                                                                )
                                                        )}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr className="txt-center">
                                                            <td colSpan="12">
                                                                Không có dữ liệu
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                                <tfoot>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th className="sorting">
                                                            Ảnh đại diện
                                                        </th>
                                                        <th className="sorting">
                                                            Nội dung sự cố
                                                        </th>
                                                        {isFullScreen ? (
                                                            <th className="sorting">
                                                                Biện pháp khắc
                                                                phục và phòng
                                                                ngừa
                                                            </th>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <th className="sorting">
                                                            Ngày phát sinh sự cố
                                                        </th>
                                                        <th className="sorting">
                                                            Hành động
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div
                                                className="dataTables_paginate paging_simple_numbers"
                                                id="example2_paginate"
                                            >
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination pagination-sm no-margin pull-right"
                                                    activePage={page}
                                                    itemsCountPerPage={Number(
                                                        pageSize
                                                    )}
                                                    totalItemsCount={
                                                        data
                                                            ? data.totalRecords
                                                            : 0
                                                    }
                                                    pageRangeDisplayed={5}
                                                    onChange={(number) =>
                                                        changePage(number)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <ConfirmModal
                                isOpen={openConfirmDeleteModal}
                                confirmType="delete"
                                handleAccept={() => acceptDelete()}
                                handleCancel={() => cancelDelete()}
                                content={confirmDelMessage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeviceMaintenance;
