import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Table from 'react-bootstrap/Table';
import * as moment from 'moment';
import { POST_API } from '@app/utils/apiUrl';
import { Post, Get } from '@app/utils/httpProvider';
import { useMutation, useQuery } from 'react-query';
import { getStatisticPostSynthticAction } from '../../store/reducers/reportPost';
import { objectDataTypeCategory, rangeDate } from '../../utils/define';
import { dateTimeToStringRequest, exportExcel, subInput } from '../../utils/helper';

const ReportPostQuantityCreateBy = () => {
    const dispatch = useDispatch();
    const listStatisticPostSynthtic = useSelector(
        (state) => state.reportPost.listStatisticPostSynthtic
    );

    const [valueDateType, setValueDateType] = useState('');

    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });

    // useEffect(() => {
    //     const modelPost = {
    //         dataTypeCategory: valueDateType,
    //         fromDate: dates.startDate
    //             ? dateTimeToStringRequest(dates.startDate.toDate())
    //             : '',
    //         toDate: dates.endDate
    //             ? dateTimeToStringRequest(dates.endDate.toDate())
    //             : ''
    //     };
    //     dispatch(getStatisticPostSynthticAction(modelPost));
    // }, [valueDateType, dates]);

    const { isLoading, isError, error, data, refetch } = useQuery(
        ['countPostStatusByAccount', valueDateType, dates],
        () => {
            const model = {
                dataType: valueDateType,
            };
            if (dates.startDate) {
                model.fromDate = dateTimeToStringRequest(
                    dates.startDate.toDate()
                );
            }
            if (dates.endDate) {
                model.toDate = dateTimeToStringRequest(dates.endDate.toDate());
            }
            return Get(POST_API.COUNT_POST_STATUS_BY_ACCOUNT, null, model);
        }
    );

    React.useEffect(() => {
        refetch();
    }, [valueDateType, dates]);

    const onChangeRepostType = (e) => {
        setValueDateType(e.target.value);
    };

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        setDates({
            startDate: null,
            endDate: null
        });
        picker.element.val('');
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h1 className="card-title">
                                {' '}
                                Thống kê số lượng đăng theo tác giả
                            </h1>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label htmlFor="exampleEmail2" className>
                                        Loại bài viết :
                                    </label>
                                    <select
                                        className="form-control"
                                        value={valueDateType}
                                        onChange={(e) => onChangeRepostType(e)}
                                    >
                                        <option value="">Tất cả</option>
                                        {objectDataTypeCategory.map(
                                            (item, key) => (
                                                <option
                                                    key={Number(key)}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </option>
                                            )
                                        )}
                                    </select>
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3 mr-2"
                                    >
                                        Thời gian đăng tin :
                                    </label>
                                    <DateRangePicker
                                        onApply={handleApply}
                                        onCancel={handleCancel}
                                        initialSettings={{
                                            autoUpdateInput: false,
                                            locale: {
                                                cancelLabel: 'Clear'
                                            },
                                            ranges: rangeDate
                                        }}
                                    >
                                        <input
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length === 0
                                                ) {
                                                    setDates({
                                                        startDate: null,
                                                        endDate: null
                                                    });
                                                }
                                            }}
                                            type="text"
                                            className="form-control"
                                        />
                                    </DateRangePicker>
                                </form>
                                <NavLink
                                    className="nav-link-reject nav-link"
                                    to
                                    onClick={() => exportExcel(`baocao_thongkeluotdangtheotacgia_${Date.now()}`)}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        Xuất file Excel
                                    </button>
                                </NavLink>
                            </div>
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : isError ? (
                                <div>
                                    An error has occurred: ${error.message}
                                </div>
                            ) : (
                                <div
                                    id="example2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4"
                                >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Table id="table-to-xls">
                                                <thead>
                                                    {dates.startDate ||
                                                        dates.endDate ? (
                                                        <tr role="row">
                                                            <th
                                                                className="sorting"
                                                                rowSpan="2"
                                                            >
                                                                Tài khoản
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                rowSpan="2"
                                                            >
                                                                Email
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                colSpan="2"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                Tổng số
                                                            </th>
                                                        </tr>
                                                    ) : (
                                                        <tr role="row">
                                                            <th
                                                                className="sorting"
                                                                rowSpan="2"
                                                            >
                                                                Tài khoản
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                rowSpan="2"
                                                            >
                                                                Email
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                colSpan="2"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                Tuần này
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                colSpan="2"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                Tuần trước
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                colSpan="2"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                Tháng này
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                colSpan="2"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                Tháng trước
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                colSpan="2"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                Năm nay
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                colSpan="2"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                Tổng số
                                                            </th>
                                                        </tr>
                                                    )}
                                                    {dates.startDate ||
                                                        dates.endDate ? (
                                                        <tr role="row">
                                                            <th className="sorting">
                                                                Duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Không duyệt
                                                            </th>
                                                        </tr>
                                                    ) : (
                                                        <tr role="row">
                                                            <th className="sorting">
                                                                Duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Không duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Không duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Không duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Không duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Không duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Duyệt
                                                            </th>
                                                            <th className="sorting">
                                                                Không duyệt
                                                            </th>
                                                        </tr>
                                                    )}
                                                </thead>
                                                {data &&
                                                    data !== undefined &&
                                                    data.$values.length > 0 ? (
                                                    <tbody>
                                                        {data.$values.map(
                                                            (item, index) => (
                                                                <tr role="row" key={Number(
                                                                    index
                                                                )}
                                                                    className="odd">
                                                                    <td className="sorting">
                                                                        {dates.startDate ||
                                                                            dates.endDate ? subInput(item.userName, 50)
                                                                            : (
                                                                                item.$values != null &&
                                                                                    item.$values !== undefined &&
                                                                                    item.$values.length > 0 ? subInput(item.$values[0].userName, 50) : null
                                                                            )}
                                                                    </td>
                                                                    <td className="sorting">
                                                                        {dates.startDate ||
                                                                            dates.endDate ? subInput(item.email, 50)
                                                                            : (
                                                                                item.$values != null &&
                                                                                    item.$values !== undefined &&
                                                                                    item.$values.length > 0 ? subInput(item.$values[0].email, 50) : null
                                                                            )}
                                                                    </td>
                                                                    {dates.startDate ||
                                                                        dates.endDate ? (
                                                                        <>
                                                                            <td className="sorting">
                                                                                {item.getCountRelease}
                                                                            </td>
                                                                            <td className="sorting color-red">
                                                                                {item.getCountNotRelease}
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {item.$values.map(
                                                                                (itemInside, indexInside) => (
                                                                                    <>
                                                                                        <td className="sorting">
                                                                                            {itemInside.getCountRelease}
                                                                                        </td>
                                                                                        <td className="sorting">
                                                                                            {itemInside.getCountNotRelease}
                                                                                        </td>
                                                                                    </>
                                                                                ))}
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr className="txt-center">
                                                            <td colSpan="12">
                                                                Không có dữ liệu
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </Table>

                                            {/* <Table
                                            id="table-to-xls"
                                            striped
                                            bordered
                                            hover
                                        >
                                            <thead>
                                                {listStatisticPostSynthtic.length >
                                                0 ? (
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            Trang
                                                        </th>
                                                        {listStatisticPostSynthtic.map(
                                                            (item, index) => (
                                                                <th
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="sorting"
                                                                >
                                                                    {item.title}
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                ) : null}
                                            </thead>
                                            {listStatisticPostSynthtic.length >
                                            0 ? (
                                                <tbody>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                             My Health
                                                        </th>
                                                        {listStatisticPostSynthtic.map(
                                                            (item, index) => (
                                                                <th
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="sorting"
                                                                >
                                                                    {item.total}
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr className="txt-center">
                                                        <td colSpan="12">
                                                            Không có dữ liệu
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Table> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ReportPostQuantityCreateBy;
