import * as moment from 'moment';
import * as XLSX from 'xlsx';
// import { parsePhoneNumberFromString } from 'libphonenumber-js';
import jwt_decode from 'jwt-decode';
import {
    TOKEN_KEY,
    DATE_TIME_REACT_FORMAT,
    DATE_TIME_REACT_WITHOUT_HOUR_FORMAT,
    DATE_TIME_REACT_TYPE_ONE_WITHOUT_HOUR_FORMAT
} from './define';
import {BaseGatewayImage} from './apiUrl';

export function consoleLog(message) {
    console.log(message);
}

export const exportExcel = (fileName) => {
    const table = document.getElementById('table-to-xls');
    if (table) {
        const wb = XLSX.utils.table_to_book(table, {sheet: 'Sheet JS'});
        return XLSX.writeFile(wb, null || `${fileName}.xlsx`);
    }
    return null;
};

export function checkRoleUser(roles, user) {
    if (roles && user) {
        const userRole = user.role.split(', ');
        return userRole
            ? roles.some((x) => userRole.includes(x.toLowerCase().trim()))
            : false;
    }
    return false;
}

export const formatDateTimeMoment = (date) => {
    const newDate = new Date(date);
    const showDate = moment(newDate).format(DATE_TIME_REACT_FORMAT);
    return showDate;
};

export const formatDateTimeWithoutHourMoment = (date) => {
    const newDate = new Date(date);
    const showDate = moment(newDate).format(
        DATE_TIME_REACT_WITHOUT_HOUR_FORMAT
    );
    return showDate;
};

export const formatDateTimeWithoutHourTypeOneMoment = (date) => {
    const newDate = new Date(date);
    const showDate = moment(newDate).format(
        DATE_TIME_REACT_TYPE_ONE_WITHOUT_HOUR_FORMAT
    );
    return showDate;
};

export const getDateTimeISOString = (date) => {
    date.setHours(date.getHours() + 7);
    return date.toISOString();
};

export function getToken() {
    if (localStorage.getItem(TOKEN_KEY) !== 'undefined') {
        // return JSON.parse(localStorage.getItem(TOKEN_KEY));
        return localStorage.getItem(TOKEN_KEY);
    }
    return '';
}

// lấy thông tin tài khoản account từ token
export function getInfomationAccountFromToken() {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) return jwt_decode(token);
    return null;
}

// kiểm tra thời gian hết hạn của token
export function checkExpToken() {
    const tokenDecode = getInfomationAccountFromToken();
    let currentDate = new Date();

    if (tokenDecode && tokenDecode.refreshexp && (new Date(tokenDecode.refreshexp)) >= currentDate) {
        return true;
    }

    return false;
    // const providedDate = new Date(tokenDecode.refreshexp);

    // if (!tokenDecode || providedDate < currentDate) {
    //     // localStorage.clear();
    //     return false;
    // }
    // if (!tokenDecode) {
    //     return false;
    // }
    // return true;
}

export const createUrlWithParam = (baseUrl = '', model = {}) => {
    const formGet = [];
    Object.keys(model).forEach(function (property) {
        if (Array.isArray(model[property])) {
            model[property].forEach((element) => {
                const encodedKey = encodeURIComponent(property);
                const encodedValue = encodeURIComponent(element);
                formGet.push(`${encodedKey}=${encodedValue}`);
            });
        } else {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(model[property]);
            formGet.push(`${encodedKey}=${encodedValue}`);
        }
    });
    const strParams = formGet.join('&');
    return strParams.length > 0 ? `${baseUrl}?${strParams}` : baseUrl;
};
export function isValidUrl(_string) {
    let url_string;
    try {
        url_string = new URL(_string);
    } catch (_) {
        return false;
    }
    return true;
}

export function subInput(text, maxLenght) {
    if (text) {
        if (text.length > maxLenght) {
            let trimmedString = text.substr(0, maxLenght);
            trimmedString = trimmedString.substr(
                0,
                Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
            );
            return `${trimmedString}...`;
        }
        return text;
    }
    return null;
}

// export const getPhone = phone => {
//   const phoneNumber = parsePhoneNumberFromString(`Phone: ${phone}`, 'VN');
//   return phoneNumber.number;
//   // if (phone.startsWith('0')) {
//   //   return {
//   //     phoneValidate: phone.replace('0', '+84'),
//   //   };
//   // } else {
//   //   return {
//   //     phoneValidate: phone,
//   //   };
//   // }
// };

// export const validInput = (type, value, isCheckSpace = false) => {
//   if (
//     type.toLowerCase() === 'text' ||
//     type.toLowerCase() === 'option' ||
//     type.toLowerCase() === 'tagselect' ||
//     type.toLowerCase() === 'file'
//   ) {
//     if (isCheckSpace) {
//       if (value.length === 0 || value.includes(' ')) {
//         return false;
//       }
//     } else {
//       if (value == null || value.length === 0) {
//         return false;
//       }
//     }
//     return true;
//     // let regexp = /([^\s])/;
//     // let checkingResult = regexp.exec(text);
//     // return checkingResult !== null ? true : false;
//   }
//   if (type.toLowerCase() === 'number') {
//     if (value == null || value.length === 0 || value === 0) {
//       return false;
//     }
//     return true;
//   }
//   if (type.toLowerCase() === 'password') {
//     let regexp = /^(?=.{8,})/;
//     // let regexp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
//     let checkingResult = regexp.exec(value);
//     return checkingResult !== null ? true : false;
//   }
//   if (type.toLowerCase() === 'notspace') {
//     let regexp = /^(\w+\S+)$/;
//     let checkingResult = regexp.exec(value);
//     return checkingResult !== null ? true : false;
//   }

//   if (type.toLowerCase() === 'phone') {
//     let regexp = /^\+?\d+$/;
//     try {
//       if (regexp.test(value)) {
//         const phoneNumber = parsePhoneNumberFromString(
//           `Phone: ${parseInt(value)}`,
//           'VN',
//         );
//         if (phoneNumber !== undefined && phoneNumber.isValid()) {
//           return true;
//         }
//         return false;
//       } else {
//         return false;
//       }
//     } catch (e) {
//       console.error('e', e);
//     }

//     // let regexp = /^([0|\+[0-9]{1,5})?([0-9]{10,12})$/;
//     // let checkingResult = regexp.exec(text);
//     // return checkingResult !== null ? true : false;
//   }
//   if (type.toLowerCase() === 'email') {
//     var pattern =
//       /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/;
//     return pattern.test(value);
//   }
//   return true;
// };

// export const string_to_slug = str => {
//   // Chuyển hết sang chữ thường
//   str = str.toLowerCase();

//   // xóa dấu
//   str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
//   str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
//   str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
//   str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
//   str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
//   str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
//   str = str.replace(/(đ)/g, 'd');

//   // Xóa ký tự đặc biệt
//   str = str.replace(/([^0-9a-z-\s])/g, '');

//   // Xóa khoảng trắng thay bằng ký tự -
//   str = str.replace(/(\s+)/g, '-');

//   // Xóa ký tự - liên tiếp
//   str = str.replace(/-+/g, '-');

//   // xóa phần dự - ở đầu
//   str = str.replace(/^-+/g, '');

//   // xóa phần dư - ở cuối
//   str = str.replace(/-+$/g, '');

//   // return
//   return str;
// };

// export const dateFormatVi = date => {
//   // let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
//   // let month =
//   //   parseInt(date.getMonth() + 1) < 10
//   //     ? '0' + parseInt(date.getMonth() + 1)
//   //     : parseInt(date.getMonth() + 1);
//   // return day + '/' + month + '/' + date.getFullYear();
//   return moment(date).format('DD/MM/YYYY');
// };

export const formatDateTimeCrawler = (date) => {
    const showDate = moment(date).format('DD/MM/YYYY HH:mm A');
    return showDate;
};

export const isString = (value) => {
    return typeof value === 'string' || value instanceof String;
};

export const convertStringToArrayImages = (sentences) => {
    if (isString(sentences)) {
        const listSentences = sentences.split(',');
        const listImages = [];
        listSentences.forEach((sentence) => {
            sentence = BaseGatewayImage + sentence;
            listImages.push(sentence);
        });
        return listImages;
    }
    return '';
};

export const dateTimeToString = (date, longDate = false, endDay = false) => {
    if (date != null && typeof date !== 'undefined') {
        const temmp = moment(date).format('YYYYMMDD');
        if (longDate) {
            if (endDay) {
                return `${temmp}235959`;
            }
            return `${temmp}000000`;
        }
        return temmp;
    }
    return '';
};

// yyyyMMdd
function isValidDate(dateString) {
    const regEx =
        /^(19[5-9][0-9]|20[0-4][0-9]|2050)(0?[1-9]|1[0-2])(0?[1-9]|[12][0-9]|3[01])$/;
    return dateString.match(regEx) != null;
}

// yyyyMMdd 20200506144600
export const stringToDateTime = (dateString) => {
    if (isValidDate(dateString)) {
        const year = parseInt(dateString.substring(0, 4));
        const month = parseInt(dateString.substring(4, 6)) - 1;
        const date = parseInt(dateString.substring(6, 8));
        return new Date(year, month, date, 0, 0, 0, 0);
    }
    return null;
};

export const dateTimeToStringRequest = (date) => {
    const dateTemp = date;
    dateTemp.setHours(dateTemp.getHours() + 7);
    return dateTemp.toISOString();
};

export const dateTimeToUTCRequest = (date) => {
    const dateTemp = date;
    dateTemp.setHours(dateTemp.getHours() + 7);
    return dateTemp;
};

export const dateTimeBackFromUTCRequest = (date) => {
    const dateTemp = date;
    dateTemp.setHours(dateTemp.getHours() - 7);
    return dateTemp;
};

// export const getIdYoutube = url => {
//   var regExp =
//     /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
//   var match = url.match(regExp);

//   if (match && match[2].length == 11) {
//     return match[2];
//   } else {
//     return 'error';
//   }
// };

// export const isEmptyObject = obj => {
//   return Object.keys(obj).length === 0;
// };

// export const createUrlWithParam = (baseUrl = '', params = {}) => {
//   let temp = [];

//   for (let property in params) {
//     if (Array.isArray(params[property])) {
//       params[property].forEach(element => {
//         let encodedKey = encodeURIComponent(property);
//         let encodedValue = encodeURIComponent(element);
//         temp.push(encodedKey + '=' + encodedValue);
//       });
//     } else {
//       let encodedKey = encodeURIComponent(property);
//       let encodedValue = encodeURIComponent(params[property]);
//       temp.push(encodedKey + '=' + encodedValue);
//     }
//   }

//   let strParams = temp.join('&');

//   return strParams.length > 0 ? baseUrl + '?' + strParams : baseUrl;
// };
