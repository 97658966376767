import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {NEWSCRAWLER} from '../constants';
import {Get} from '../../utils/httpProvider';
import {NEWSCRAWLER_API} from '../../utils/apiUrl';

export const newsCrawlerPagingAction = createAsyncThunk(
    NEWSCRAWLER.GET_PAGING,
    async (model) => {
        return Get(NEWSCRAWLER_API.GET_PAGING_NEWSCRAWLER, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

export const newsCrawlerGetItemAction = createAsyncThunk(
    NEWSCRAWLER.GET_ITEM,
    async (id) => {
        return Get(NEWSCRAWLER_API.GET_ITEM_NEWSCRAWLER, id, null)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

const initialState = {
    listNewsCrawler: [],
    totalNewsCrawler: 0,
    newsCrawlerItem: null
};
export const newsCrawlerSlice = createSlice({
    name: NEWSCRAWLER.NAME_SLICE,
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(newsCrawlerPagingAction.fulfilled, (state, action) => {
                state.listNewsCrawler =
                    action.payload && action.payload.data
                        ? action.payload.data
                        : [];
                state.totalNewsCrawler =
                    action.payload && action.payload.totalRecords
                        ? action.payload.totalRecords
                        : 0;
            })
            .addCase(newsCrawlerGetItemAction.fulfilled, (state, action) => {
                state.newsCrawlerItem = action.payload;
            })
            .addCase(newsCrawlerGetItemAction.rejected, (state) => {
                state.newsCrawlerItem = null;
            });
    }
});

export default newsCrawlerSlice.reducer;
