import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Pagination from 'react-js-pagination';
import { useMutation, useQuery } from 'react-query';
import { Post, Get } from '@app/utils/httpProvider';
import { ACCOUNT_API, CATEGORY_API } from '@app/utils/apiUrl';
import Table from 'react-bootstrap/Table';
import * as moment from 'moment';
import { postPagingReportCreateByAction, exportExcelPostCreateByAction } from '../../store/reducers/post';
import {
    objectStatusPost,
    objectDataTypeCategory,
    rangeDate
} from '../../utils/define';
import { dateTimeToStringRequest, formatDateTimeCrawler, exportExcel, subInput } from '../../utils/helper';
import { FaFolderOpen, FaFolder } from 'react-icons/fa';
import SelectionMutil from '../../components/selectionMutil/SelectionMutil';

const ReportPostCreateBy = () => {
    const dispatch = useDispatch();

    //categoryValues giá trị được chọn
    const [categoryValues, setCategoryValues] = useState([]);
    const [forceValidate, setForceValidate] = useState(false);


    const valueSearch = useSelector((state) => state.search.valueSearch);
    const listPostReportCreateBy = useSelector((state) => state.post.listPostReportCreateBy);
    const totalPostReportCreateBy = useSelector((state) => state.post.totalPostReportCreateBy);

    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY)
    );
    const listAccount = useQuery(['getAllAccount'], () =>
        Get(ACCOUNT_API.GET_PAGING_ACCOUNT, null, {
            PageIndex: 1,
            PageSize: 100
        })
    );

    // let lstObjectCategory;
    // if (
    //     listCate.data &&
    //     listCate.data.data &&
    //     listCate.data.data.$values.length > 0
    // ) {
    //     lstObjectCategory = listCate.data.data.$values.map((elem) => ({
    //         label: elem.name,
    //         value: elem.id
    //     }));
    // }

    let lstCategoryMap = [];
    if (listCate.data && listCate.data.data && listCate.data.data.$values.length > 0) {
        listCate.data.data.$values.forEach(item => {
            lstCategoryMap.push({
                id: item.id,
                parentId: item.parentId,
                name: item.name
            });
        });
    }


    let lstObjectAccount;
    if (
        listAccount.data &&
        listAccount.data.data &&
        listAccount.data.data.$values.length > 0
    ) {
        lstObjectAccount = listAccount.data.data.$values.map((elem) => ({
            label: elem.fullname,
            value: elem.username
        }));
    }
    // loại bài viết
    const [valueDateType, setValueDateType] = useState('');
    // danh mục
    const [valueCate, setValueCate] = useState('');
    // trạng thái kiểm duyệt
    const [status, setStatus] = useState('');
    // người tạo
    const [createdBy, setCreateBy] = useState('');
    // ngày xuất bản
    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const getCallPostReportCreateBy = async (page, size, search, status, fromDate, toDate, createdBy, dataType, cateId,cateIds) => {
        const modelPost = {
            Keyword: search === null ? "" : search,
            Status: status,
            FromDate: fromDate === null ? "" : dateTimeToStringRequest(fromDate.toDate()),
            ToDate: toDate === null ? "" : dateTimeToStringRequest(toDate.toDate()),
            CreatedBy: createdBy,
            DataType: dataType,
            // CateId: cateId,
            CateIds: cateIds,
            PageIndex: page,
            PageSize: size
        };
        await dispatch(postPagingReportCreateByAction(modelPost));
    };
    useEffect(() => {
        getCallPostReportCreateBy(
            page,
            pageSize,
            valueSearch,
            status,
            dates.startDate,
            dates.endDate,
            createdBy,
            valueDateType,
            valueCate,
            categoryValues
        )
    }, [page,
        pageSize,
        valueSearch,
        status,
        dates,
        createdBy,
        valueDateType,
        valueCate,
        categoryValues
    ]);

    // useEffect(() => {
    //     const modelPost = {
    //         dataTypeCategory: valueDateType,
    //         fromDate: dates.startDate
    //             ? dateTimeToStringRequest(dates.startDate.toDate())
    //             : '',
    //         toDate: dates.endDate
    //             ? dateTimeToStringRequest(dates.endDate.toDate())
    //             : ''
    //     };
    //     dispatch(getStatisticPostSynthticAction(modelPost));
    // }, [valueDateType, dates]);

    const onChangeRepostType = (e) => {
        setValueDateType(e.target.value);
    };

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
    };
    const changePage = (number) => {
        setPage(number);
    };
    const renderExcel = async () => {
        const modelPost = {
            Keyword: valueSearch,
            Status: status === "" ? null : status,
            FromDate: dates.startDate === "" || dates.startDate === null ? null : dateTimeToStringRequest(dates.startDate.toDate()),
            ToDate: dates.endDate === "" || dates.endDate === null ? null : dateTimeToStringRequest(dates.endDate.toDate()),
            CreatedBy: createdBy,
            DataType: valueDateType === "" ? null : valueDateType,
            // CateId: valueCate,
            CateIds:categoryValues,
        };
        await dispatch(exportExcelPostCreateByAction(modelPost));
    }
    const handleSelectCategory = data => {
        if (data) {
            setCategoryValues(data.map(x => x.id));
        }
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h1 className="card-title">
                                {' '}
                                Báo cáo bài viết theo tác giả
                            </h1>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label htmlFor="exampleEmail2">
                                        Người tạo:
                                    </label>
                                    <select
                                        className="form-control"
                                        value={createdBy}
                                        onChange={(e) =>
                                            setCreateBy(e.target.value)
                                        }
                                    >
                                        <option value="">Tất cả</option>
                                        {lstObjectAccount &&
                                            lstObjectAccount.length > 0
                                            ? lstObjectAccount.map(
                                                (item, key) => (
                                                    <option
                                                        key={Number(key)}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </option>
                                                )
                                            )
                                            : null}
                                    </select>

                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3 mr-2"
                                    >
                                        Trạng thái kiểm duyệt:
                                    </label>
                                    <select
                                        className="form-control"
                                        value={status}
                                        onChange={(e) =>
                                            setStatus(e.target.value)
                                        }
                                    >
                                        {/* <option value="">Trạng thái kiểm duyệt</option> */}
                                        {objectStatusPost.map((item, key) => (
                                            <option
                                                key={Number(key)}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3 mr-2"
                                    >
                                        Thời gian xuất bản :
                                    </label>
                                    <DateRangePicker
                                        onApply={handleApply}
                                        onCancel={handleCancel}
                                        initialSettings={{
                                            autoUpdateInput: false,
                                            locale: {
                                                cancelLabel: 'Clear'
                                            },
                                            ranges: rangeDate
                                        }}
                                    >
                                        <input
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length === 0
                                                ) {
                                                    setDates({
                                                        startDate: null,
                                                        endDate: null
                                                    });
                                                }
                                            }}
                                            type="text"
                                            className="form-control"
                                        />
                                    </DateRangePicker>
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3 mr-2"
                                    >
                                        Loại bài viết:
                                    </label>
                                    <select
                                        className="form-control"
                                        value={valueDateType}
                                        onChange={(e) => onChangeRepostType(e)}
                                    >
                                        <option value="">Tất cả</option>
                                        {objectDataTypeCategory.map(
                                            (item, key) => (
                                                <option
                                                    key={Number(key)}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </option>
                                            )
                                        )}
                                    </select>
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3 mr-2"
                                    >
                                        Danh mục:
                                    </label>
                                    <SelectionMutil
                                        data={lstCategoryMap}
                                        showCheckboxOnTree={true}
                                        EnableValidate={true}
                                        ErrorMessage={""}
                                        multiSelect={true}
                                        Placeholder="Danh mục"
                                        IconExpand={FaFolder}
                                        IconCollapse={FaFolderOpen}
                                        positionExpand="right"
                                        multiValueSelected={categoryValues}
                                        onChange={handleSelectCategory}
                                        ForceValidate={forceValidate}
                                    />
                                    {/* <select
                                        className="form-control"
                                        value={valueCate}
                                        onChange={(e) =>
                                            setValueCate(e.target.value)
                                        }
                                    >
                                        <option value="">Tất cả</option>
                                        {lstObjectCategory &&
                                            lstObjectCategory.length > 0
                                            ? lstObjectCategory.map(
                                                (item, key) => (
                                                    <option
                                                        key={Number(key)}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </option>
                                                )
                                            )
                                            : null}
                                    </select> */}
                                </form>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{ }</div>
                                <NavLink
                                    className="nav-link-reject nav-link "
                                    to
                                // onClick={() => exportExcel(`baocao_baiviettonghop_${Date.now()}`)}
                                >
                                    <button
                                        onClick={() =>
                                            renderExcel()
                                        }
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        Xuất file Excel
                                    </button>
                                </NavLink>
                            </div>

                            <div
                                id="example2_wrapper"
                                className="dataTables_wrapper dt-bootstrap4"
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Table id="table-to-xls">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting">
                                                        STT
                                                    </th>
                                                    <th className="sorting">
                                                        Tiêu đề
                                                    </th>
                                                    <th className="sorting">
                                                        Ngày xuất bản
                                                    </th>
                                                    <th className="sorting">
                                                        Tác giả
                                                    </th>
                                                    <th className="sorting">
                                                        Nguồn
                                                    </th>
                                                    <th className="sorting">
                                                        Biên tập viên
                                                    </th>
                                                    <th className="sorting">
                                                        Người xuất bản
                                                    </th>
                                                    <th className="sorting">
                                                        Danh mục
                                                    </th>
                                                    <th className="sorting">
                                                        Người đăng
                                                    </th>
                                                    <th className="sorting">
                                                        Số lượng ảnh
                                                    </th>
                                                    <th className="sorting">
                                                        Số từ
                                                    </th>
                                                </tr>
                                            </thead>
                                            {listPostReportCreateBy != null &&
                                                listPostReportCreateBy !==
                                                undefined &&
                                                listPostReportCreateBy.length > 0 ? (
                                                <tbody>
                                                    {listPostReportCreateBy.map(
                                                        (item, index) => (
                                                            <tr key={Number(
                                                                index
                                                            )} role="row">
                                                                <td className="sorting">
                                                                    {index +
                                                                        1 +
                                                                        (page -
                                                                            1) *
                                                                        pageSize}
                                                                </td>
                                                                <td className="sorting">
                                                                    {
                                                                        item.title ? subInput(item.title, 50) : ""
                                                                    }
                                                                </td>
                                                                <td className="sorting">
                                                                    {formatDateTimeCrawler(
                                                                        item.publishTime
                                                                    )}
                                                                </td>
                                                                <td className="sorting">
                                                                    {item.author ? item.author : ""}
                                                                </td>
                                                                <td className="sorting">
                                                                    {item.sourceLink ? item.sourceLink : ""}
                                                                </td>
                                                                <td className="sorting">
                                                                    {item.createdBy ? item.createdBy : ""}
                                                                </td>
                                                                <td className="sorting">
                                                                    {item.publishBy ? item.publishBy : ""}
                                                                </td>
                                                                <td className="sorting">
                                                                    {item.nameCategory ? item.nameCategory : ""}
                                                                </td>
                                                                <td className="sorting">
                                                                    {item.createdBy ? item.createdBy : ""}
                                                                </td>
                                                                <td className="sorting">
                                                                    {item.numberImage ? item.numberImage.toString() : ""}
                                                                </td>
                                                                <td className="sorting">
                                                                    {item.numberWord ? item.numberWord.toString() : ""}
                                                                </td>

                                                            </tr>
                                                        ))}

                                                </tbody>) :
                                                <tbody>
                                                    <tr className="txt-center">
                                                        <td colSpan="12">
                                                            Không có dữ liệu
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </Table>

                                        {/* <Table
                                            id="table-to-xls"
                                            striped
                                            bordered
                                            hover
                                        >
                                            <thead>
                                                {listStatisticPostSynthtic.length >
                                                0 ? (
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            Trang
                                                        </th>
                                                        {listStatisticPostSynthtic.map(
                                                            (item, index) => (
                                                                <th
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="sorting"
                                                                >
                                                                    {item.title}
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                ) : null}
                                            </thead>
                                            {listStatisticPostSynthtic.length >
                                            0 ? (
                                                <tbody>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                             My Health
                                                        </th>
                                                        {listStatisticPostSynthtic.map(
                                                            (item, index) => (
                                                                <th
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="sorting"
                                                                >
                                                                    {item.total}
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr className="txt-center">
                                                        <td colSpan="12">
                                                            Không có dữ liệu
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Table> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="example2_paginate"
                                        >
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                innerClass="pagination pagination-sm no-margin pull-right"
                                                activePage={page}
                                                itemsCountPerPage={Number(
                                                    pageSize
                                                )}
                                                totalItemsCount={totalPostReportCreateBy}
                                                pageRangeDisplayed={5}
                                                onChange={(number) =>
                                                    changePage(number)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ReportPostCreateBy;
