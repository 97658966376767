import React, {useRef, useState, useEffect} from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup, Image} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useLocation, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import {Get, Put} from '@app/utils/httpProvider';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import Editor from '@app/components/editor/Editor';
import Select from 'react-select';
import {NIL as NIL_UUID} from 'uuid';
import {
    messageError,
    messageToast,
    acceptImage,
    objectDataTypeCategory,
    styleSelectDefault,
    styleSelectError,
    status200,
    dataTypeCategory,
    objectDataTypeVideo,
    DATE_TIME_REACT_FORMAT,
    role
} from '../../utils/define';
import {BaseGatewayImage, DEVICEMAINTENANCE_API} from '../../utils/apiUrl';
import {
    getInfomationAccountFromToken,
    getToken,
    isValidUrl
} from '@app/utils/helper';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import noImg from '../../../src/assets/no-image.jpg';

import {registerLocale, setDefaultLocale} from 'react-datepicker';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);

// const schema = yup.object().shape({
//     name: yup.string().required(messageError.emptyMessage),
//     plantCategoryId: yup.object().shape({
//         label: yup.string(),
//         value: yup.string()
//     }).nullable()
//         .required(messageError.emptyMessage)
//     // dataType: yup.object().required(messageError.emptyMessage)
// });

const DeviceMaintenanceEdit = () => {
    const history = useHistory();
    const inputUpload = useRef();
    const queryClient = useQueryClient();
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const deviceMaintenanceId = paramUrl.get('id');
    const inforAccount = getInfomationAccountFromToken();
    let accountRoleApproverDevice = false;

    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        accountRoleApproverDevice =
            userRole.includes(role.Admin) || userRole.includes(role.Approver);
        // accountRoleBooker =
        //     userRole.includes(role.Admin) || userRole.includes(role.Booker);
    }

    const deviceMaintenanceItem = useQuery([deviceMaintenanceId], () =>
        Get(DEVICEMAINTENANCE_API.GET_ITEM, deviceMaintenanceId)
    );

    // const listCate = useQuery(['getAllCate'], () =>
    //     Get(CATEGORY_API.GET_ALL_CATEGORY)
    // );

    const editFunc = useMutation((value) =>
        Put(DEVICEMAINTENANCE_API.UPDATE_ITEM, deviceMaintenanceId, value)
    );

    const handleEdit = async (values) => {
        const model = {...values};
        model.id = deviceMaintenanceId;

        editFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    toast.success(messageToast.messageToastUpdateSuccess);
                    queryClient.setQueryData(deviceMaintenanceId, model);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    // const isLoading = cateItem.isLoading || listCate.isLoading;
    // const isError = cateItem.isError || listCate.isError;
    // const error = cateItem.isError ? cateItem.error : listCate.error;

    const isLoading = deviceMaintenanceItem.isLoading ? true : false;
    const isError = deviceMaintenanceItem.isError ? true : false;
    const error = deviceMaintenanceItem.isError
        ? deviceMaintenanceItem.error
        : null;

    // let menuData = [
    //     {
    //         name: 'Danh mục gốc',
    //         id: 'null',
    //         parentId: NIL_UUID
    //     }
    // ];
    // if (!listCate.isLoading && !listCate.isError) {
    //     menuData = menuData
    //         .concat(listCate.data.data.$values)
    //         .filter((x) => x.id !== cateId);
    // }

    console.log('deviceMaintenanceItem', deviceMaintenanceItem);

    return (
        <Card>
            <Card.Header as="h5">Sửa thông tin sự cố thiết bị</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Đang tải...</div>
                ) : isError ? (
                    <div>Có lỗi xảy ra: ${error.message}</div>
                ) : deviceMaintenanceItem.data == null ? (
                    <div>Danh mục không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        // validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            maintenanceOfProblem:
                                deviceMaintenanceItem.data &&
                                deviceMaintenanceItem.data.maintenanceOfProblem
                                    ? deviceMaintenanceItem.data
                                          .maintenanceOfProblem
                                    : '',
                            device:
                                deviceMaintenanceItem.data &&
                                deviceMaintenanceItem.data.device
                                    ? deviceMaintenanceItem.data.device
                                    : null,
                            accountName:
                                deviceMaintenanceItem.data &&
                                deviceMaintenanceItem.data.account &&
                                deviceMaintenanceItem.data.account.fullName
                                    ? deviceMaintenanceItem.data.account
                                          .fullName
                                    : '',
                            immediateActOfMaintenance:
                                deviceMaintenanceItem.data &&
                                deviceMaintenanceItem.data
                                    .immediateActOfMaintenance
                                    ? deviceMaintenanceItem.data
                                          .immediateActOfMaintenance
                                    : '',
                            // accountBookerId:
                            //     deviceMaintenanceItem.data &&
                            //     deviceMaintenanceItem.data.bookDevice
                            //         ? deviceMaintenanceItem.data.bookDevice
                            //               .accountId
                            //         : '',
                            // accountBookerName:
                            //     deviceMaintenanceItem.data &&
                            //     deviceMaintenanceItem.data.bookDevice &&
                            //     deviceMaintenanceItem.data.bookDevice.account
                            //         ? deviceMaintenanceItem.data.bookDevice
                            //               .account.fullName
                            //         : '',
                            // accountApproverName:
                            //     deviceMaintenanceItem.data &&
                            //     deviceMaintenanceItem.data.bookDevice &&
                            //     deviceMaintenanceItem.data.bookDevice
                            //         .accountApprover
                            //         ? deviceMaintenanceItem.data.bookDevice
                            //               .accountApprover.fullName
                            //         : deviceMaintenanceItem.data &&
                            //           deviceMaintenanceItem.data.bookDevice &&
                            //           deviceMaintenanceItem.data.bookDevice
                            //               .device &&
                            //           deviceMaintenanceItem.data.bookDevice
                            //               .device.account
                            //         ? deviceMaintenanceItem.data.bookDevice
                            //               .device.account.fullName
                            //         : '',
                            // device:
                            //     deviceMaintenanceItem.data &&
                            //     deviceMaintenanceItem.data.bookDevice &&
                            //     deviceMaintenanceItem.data.bookDevice.device
                            //         ? deviceMaintenanceItem.data.bookDevice
                            //               .device
                            //         : '',
                            // accountApproverId:
                            //     deviceMaintenanceItem.data &&
                            //     deviceMaintenanceItem.data.bookDevice &&
                            //     deviceMaintenanceItem.data.bookDevice
                            //         .accountApproverId
                            //         ? deviceMaintenanceItem.data.bookDevice
                            //               .accountApproverId
                            //         : deviceMaintenanceItem.data &&
                            //           deviceMaintenanceItem.data.bookDevice &&
                            //           deviceMaintenanceItem.data.bookDevice
                            //               .device
                            //         ? deviceMaintenanceItem.data.bookDevice
                            //               .device.accountId
                            //         : '',
                            descriptionOfProblem:
                                deviceMaintenanceItem.data &&
                                deviceMaintenanceItem.data.descriptionOfProblem
                                    ? deviceMaintenanceItem.data
                                          .descriptionOfProblem
                                    : '',
                            imagePath:
                                deviceMaintenanceItem.data &&
                                deviceMaintenanceItem.data.imagePath
                                    ? deviceMaintenanceItem.data.imagePath
                                    : '',
                            imageThumbnail:
                                deviceMaintenanceItem.data &&
                                deviceMaintenanceItem.data.imageThumbnail
                                    ? deviceMaintenanceItem.data.imageThumbnail
                                    : '',
                            problemArea:
                                deviceMaintenanceItem.data &&
                                deviceMaintenanceItem.data.problemArea
                                    ? deviceMaintenanceItem.data.problemArea
                                    : '',
                            problemTime:
                                deviceMaintenanceItem.data &&
                                deviceMaintenanceItem.data.problemTime
                                    ? new Date(
                                          deviceMaintenanceItem.data.problemTime
                                      )
                                    : new Date()
                            // endBookTime:
                            //     deviceMaintenanceItem.data &&
                            //     deviceMaintenanceItem.data.bookDevice
                            //         ? new Date(
                            //               deviceMaintenanceItem.data.bookDevice.endBookTime
                            //           )
                            //         : new Date()
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh đại diện
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            enableReinitialize={true}
                                            values={
                                                values.imageThumbnail
                                                    ? [
                                                          isValidUrl(
                                                              values.imageThumbnail
                                                          )
                                                              ? values.imageThumbnail
                                                              : BaseGatewayImage +
                                                                values.imageThumbnail
                                                      ]
                                                    : []
                                            }
                                            name="imageThumbnail"
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                if (files.length > 0) {
                                                    setFieldValue(
                                                        'imagePath',
                                                        files[0].filePath,
                                                        true
                                                    );
                                                    setFieldValue(
                                                        'imageThumbnail',
                                                        files[0].thumbPath,
                                                        true
                                                    );
                                                }
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Địa điểm xảy ra sự cố
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Địa điểm xảy ra sự cố"
                                            type="text"
                                            name="problemArea"
                                            value={values.problemArea}
                                            onChange={handleChange}
                                            // isInvalid={!!errors.problemArea}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả sự cố *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            // disabled={
                                            //     values.accountBookerId ==
                                            //         inforAccount.id ||
                                            //     values.accountApproverId ==
                                            //         inforAccount.id
                                            //         ? false
                                            //         : true
                                            // }
                                            name="descriptionOfProblem"
                                            value={values.descriptionOfProblem}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'descriptionOfProblem',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Hành động xử lý tức thì
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="immediateActOfMaintenance"
                                            value={
                                                values.immediateActOfMaintenance
                                            }
                                            disabled={
                                                values.accountBookerId ==
                                                inforAccount.id
                                                    ? false
                                                    : true
                                            }
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'immediateActOfMaintenance',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Biện pháp khắc phục và phòng ngừa
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            // disabled={
                                            //     values.accountBookerId ==
                                            //         inforAccount.id ||
                                            //     values.accountApproverId ==
                                            //         inforAccount.id
                                            //         ? false
                                            //         : true
                                            // }
                                            name="maintenanceOfProblem"
                                            value={values.maintenanceOfProblem}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'maintenanceOfProblem',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thời điểm phát sinh sự cố
                                    </Form.Label>
                                    <Col sm="10">
                                        <DatePicker
                                            locale="vi"
                                            selected={values.problemTime}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    'problemTime',
                                                    date,
                                                    true
                                                );
                                            }}
                                            // readOnly={
                                            //     values.accountBookerId ==
                                            //         inforAccount.id ||
                                            //     values.accountApproverId ==
                                            //         inforAccount.id
                                            //         ? false
                                            //         : true
                                            // }
                                            timeInputLabel="Thời gian:"
                                            dateFormat={DATE_TIME_REACT_FORMAT}
                                            showTimeInput
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Người sử dụng
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Người sử dụng"
                                            type="text"
                                            name="accountBookerName"
                                            value={values.accountBookerName}
                                            disabled={true}
                                        />
                                    </Col>
                                </Form.Group> */}
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Người quản lý thiết bị
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Người quản lý thiết bị"
                                            type="text"
                                            name="accountApproverName"
                                            value={values.accountApproverName}
                                            disabled={true}
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thiết bị
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Thiết bị"
                                            type="text"
                                            name="deviceName"
                                            value={
                                                values.device
                                                    ? (values.device.name &&
                                                      values.device.name.includes(
                                                          values.device
                                                              .modelDevice
                                                      )
                                                          ? values.device.name
                                                          : values.device.name +
                                                            ' - ' +
                                                            values.device
                                                                .modelDevice +
                                                            ' - ' +
                                                            values.device
                                                                .firmOfDevice +
                                                            ' - ' +
                                                            values.device
                                                                .seriDevice) +
                                                      ' - ' +
                                                      values.device.deviceCode
                                                    : ''
                                            }
                                            disabled={true}
                                        />
                                    </Col>
                                </Form.Group>
                                {values.accountName ? (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label className="col-sm-2 col-form-label">
                                            Người đặt lịch
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                placeholder="Người đặt lịch"
                                                type="text"
                                                name="deviceName"
                                                disabled={true}
                                                value={values.accountName}
                                                // onChange={handleChange}
                                                // isInvalid={!!errors.problemArea}
                                            />
                                        </Col>
                                    </Form.Group>
                                ) : (
                                    <></>
                                )}
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{span: 10, offset: 2}}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Sửa
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() => history.goBack()}
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card.Body>
        </Card>
    );
};

export default DeviceMaintenanceEdit;
