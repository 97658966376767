import React from 'react';
import {Card, Form, Row, Col} from 'react-bootstrap';
import {Button} from '@components';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {messageError, messageToast} from '../../utils/define';
import {changePasswordAction} from '../../store/reducers/auth';

const ChangePassWordTab = ({isActive}) => {
    const dispatch = useDispatch();
    const schema = yup.object().shape({
        currentPassword: yup
            .string()
            .min(6, messageError.minLength6Message)
            .max(30, messageError.maxLength30Message)
            .required(messageError.emptyMessage),
        newPassword: yup
            .string()
            .min(6, messageError.minLength6Message)
            .max(30, messageError.maxLength30Message)
            .required(messageError.emptyMessage),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('newPassword'), null], messageError.likePassword)
            .required(messageError.emptyMessage)
    });
    const handleChangePassWord = async (values, actions) => {
        try {
            const result = await dispatch(changePasswordAction(values));
            if (result.payload.errors) {
                actions.setErrors({
                    currentPassword: result.payload.errors.content[0]
                });
            } else {
                toast.success(messageToast.messageToastChangePassSuccess);
            }
        } catch (error) {
            toast.error(messageToast.messageToastTryCatch);
        }
    };
    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            <Card.Body>
                <Formik
                    enableReinitialize
                    validationSchema={schema}
                    onSubmit={(values, actions) =>
                        handleChangePassWord(values, actions)
                    }
                    initialValues={{
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: ''
                    }}
                >
                    {({handleSubmit, handleChange, values, errors}) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Mật khẩu cũ
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="password"
                                        placeholder="Mật khẩu hiện tại"
                                        name="currentPassword"
                                        value={values.currentPassword}
                                        onChange={handleChange}
                                        isInvalid={!!errors.currentPassword}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.currentPassword}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Mật khẩu mới
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="password"
                                        placeholder="Mật khẩu mới"
                                        name="newPassword"
                                        value={values.newPassword}
                                        onChange={handleChange}
                                        isInvalid={!!errors.newPassword}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.newPassword}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Xác nhận mật khẩu
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="password"
                                        placeholder="Xác nhận mật khẩu"
                                        name="confirmPassword"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        isInvalid={!!errors.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.confirmPassword}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col md={{span: 10, offset: 2}}>
                                    <Button type="submit" theme="danger">
                                        Đổi mật khẩu
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </div>
    );
};

export default ChangePassWordTab;
