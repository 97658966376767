import React, {useState} from 'react';
import Pagination from 'react-js-pagination';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useLocation, NavLink} from 'react-router-dom';
import {loginHistoryPagingAction} from '@store/reducers/loginHistory';
import * as moment from 'moment';
import Table from 'react-bootstrap/Table';
import {createUrlWithParam} from '../../../utils/helper';
import {URI_INTERNAL} from '../../../utils/pathLocations';
import {objectShowNumber} from '../../../utils/define';

const LoginHistory = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const valueSearch = useSelector((state) => state.search.valueSearch);
    const totalLoginHistory = useSelector(
        (state) => state.loginHistory.totalLoginHistory
    );
    const listLoginHistory = useSelector(
        (state) => state.loginHistory.listLoginHistory
    );

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');

    const paramUrl = new URLSearchParams(location.search.substring(1));
    if (
        paramUrl.get('page') != null &&
        parseInt(paramUrl.get('page'), 10) !== page
    ) {
        const pageResult = parseInt(paramUrl.get('page'), 10);
        setPage(pageResult);
    }
    if (
        paramUrl.get('size') != null &&
        parseInt(paramUrl.get('size'), 10) !== pageSize
    ) {
        const sizeResult = parseInt(paramUrl.get('size'), 10);
        setPageSize(sizeResult);
    }
    if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
        setSearch(paramUrl.get('search'));
    }

    const model = {
        query: search
    };

    const replaceHistory = async (Page, Size, Search) => {
        const modelPost = {
            Keyword: Search,
            PageIndex: Page,
            PageSize: Size
        };
        await dispatch(loginHistoryPagingAction(modelPost));

        history.replace(
            createUrlWithParam(URI_INTERNAL.LOGIN_HISTORY_PATH, {
                page: Page,
                size: Size,
                search: Search
            })
        );
    };

    React.useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            model.query = valueSearch;
            setSearch(model.query);
        }
        replaceHistory(page, pageSize, model.query);
    }, [valueSearch]);
    const changePage = (number) => {
        setPage(number);
        replaceHistory(number, pageSize, search);
    };

    const onChangeShowNumber = (e) => {
        setPageSize(e.target.value);
        replaceHistory(page, e.target.value, search);
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {' '}
                                Danh sách đăng nhập hệ thống{' '}
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="mr-2"
                                    >
                                        Hiển thị :
                                    </label>
                                    <select
                                        className="form-control"
                                        value={pageSize}
                                        onChange={(e) => onChangeShowNumber(e)}
                                    >
                                        {objectShowNumber.map((item, key) => (
                                            <option
                                                key={Number(key)}
                                                value={item.value}
                                            >
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                </form>
                                <NavLink
                                    className="nav-link-reject nav-link"
                                    to={URI_INTERNAL.STATISTIC_HISTORY_PATH}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                    >
                                        Thống kê đăng nhập
                                    </button>
                                </NavLink>
                            </div>
                            <div
                                id="example2_wrapper"
                                className="dataTables_wrapper dt-bootstrap4"
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting">
                                                        STT
                                                    </th>
                                                    <th className="sorting">
                                                        Tài khoản
                                                    </th>
                                                    <th className="sorting">
                                                        Thời gian truy cập
                                                    </th>
                                                </tr>
                                            </thead>
                                            {listLoginHistory != null &&
                                            listLoginHistory !== undefined &&
                                            listLoginHistory.length > 0 ? (
                                                <tbody>
                                                    {listLoginHistory.map(
                                                        (item, index) => (
                                                            <tr
                                                                key={Number(
                                                                    index
                                                                )}
                                                                className="odd"
                                                            >
                                                                <td className="dtr-control sorting_1">
                                                                    {index +
                                                                        1 +
                                                                        (page -
                                                                            1) *
                                                                            pageSize}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.userName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {moment(
                                                                        item.loginDate
                                                                    ).format(
                                                                        'DD/MM/YYYY HH:mm a'
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr className="txt-center">
                                                        <td colSpan="12">
                                                            Không có dữ liệu
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                            <tfoot>
                                                <tr role="row">
                                                    <th className="sorting">
                                                        STT
                                                    </th>
                                                    <th className="sorting">
                                                        Tài khoản
                                                    </th>
                                                    <th className="sorting">
                                                        Thời gian truy cập
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="example2_paginate"
                                        >
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                innerClass="pagination pagination-sm no-margin pull-right"
                                                activePage={page}
                                                itemsCountPerPage={Number(
                                                    pageSize
                                                )}
                                                totalItemsCount={
                                                    totalLoginHistory
                                                }
                                                pageRangeDisplayed={5}
                                                onChange={(number) =>
                                                    changePage(number)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginHistory;
