import React, {useState} from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import Select from 'react-select';
import {FileUploader} from '@uploadfiles/react-drag-drop-files';
// import {Importer, ImporterField, viVN} from '@csv-importer/react-csv-importer';
import InputFile from '@app/components/input-file/InputFile';
import {Post, Get} from '@app/utils/httpProvider';
import {DEVICE_API, DEPARTMENT_API, ACCOUNT_API} from '@app/utils/apiUrl';
import {useMutation, useQuery} from 'react-query';
import {AsyncPaginate} from 'react-select-async-paginate';
import {
    messageError,
    messageToast,
    status200,
    acceptImage,
    styleSelectDefault,
    objectDeviceStatus,
    styleSelectError
} from '../../utils/define';
import ReactSpinner from '../../components/reactSpinner/ReactSpinner';
// import 'react-csv-importer/dist/index.css';
import Editor from '@app/components/editor/Editor';
import * as XLSX from 'xlsx';
// import { getInfomationAccountFromToken } from '@app/utils/helper';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    deviceCode: yup.string().required(messageError.emptyMessage),
    deviceManagementEmail: yup
        .string()
        .email(messageError.emailMessage)
        .required(messageError.emptyMessage),
    deviceManagementPhone: yup
        .string()
        .matches(phoneRegExp, 'Điện thoại có định dạng không đúng')
});

const fileTypes = ['XLSX'];
const originalHeaderMapping = {
    'Tên thiết bị': 'name',
    'Mã thiết bị': 'deviceCode',
    // 'Mô tả': 'description',
    // 'Thông Số': 'parameter',
    'Link ảnh': 'imagePath',
    Model: 'modelDevice',
    Seri: 'seriDevice',
    Hãng: 'firmOfDevice',
    'Xuất xứ': 'deviceOrigin',
    // 'Link ảnh kích cỡ nhỏ': 'imageThumbnail',
    'Người quản lý': 'deviceManagementUserName',
    'Điện thoại': 'deviceManagementPhone',
    'Email': 'deviceManagementEmail',
    // 'Khoa quản lý': 'departmentName',
    // 'Mã khoa quản lý': 'departmentCode',
    'Số lần sử dụng tối đa': 'recommendedNumberOfUse',
    'Số lần hiện tại sử dụng': 'currentNumberOfUse'
    // 'Id Khoa': 'departmentId'
};

const headerMapping = Object.keys(originalHeaderMapping).reduce((acc, key) => {
    acc[key.toLowerCase()] = originalHeaderMapping[key];
    return acc;
}, {});

const DeviceAdd = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    // const inforAccount = getInfomationAccountFromToken();

    // const listDepartment = useQuery(['getAllDepartment'], () =>
    //     Get(DEPARTMENT_API.GET_ALL, null, null)
    // );

    const listAccount = useQuery(['getAllAccount'], () =>
        Get(ACCOUNT_API.GET_PAGING_ACCOUNT, null, {
            PageIndex: 1,
            PageSize: 5000
        })
    );

    // console.log(
    //     'test ' + listDepartment != null && listDepartment.data != null
    //         ? listDepartment.data.$values
    //         : []
    // );

    // const lstDepartment =
    //     listDepartment && listDepartment.data
    //         ? listDepartment.data.map((x) => ({
    //               label: x.Ten,
    //               value: x.ID
    //           }))
    //         : [];

    const lstAccount =
        listAccount && listAccount.data && listAccount.data.data
            ? listAccount.data.data.map((x) => ({
                  label: x.fullName,
                  value: x.id
              }))
            : [];

    // const lstParentQA =
    //     listParentQA && listParentQA.data && listParentQA.data.data
    //         ? listParentQA.data.data.map((x) => ({label: x.title, value: x.id}))
    //         : [];

    // const [file, setFile] = useState(null);
    const handleChangeFileExcel = (file) => {
        handleFileExelUpload(file);
    };

    const addFunc = useMutation((value) =>
        Post(DEVICE_API.ADD_ITEM, null, value, false)
    );

    const addMultipleFunc = useMutation((value) =>
        Post(DEVICE_API.ADD_MULTIPLE_ITEMS, null, value, false)
    );

    const handleCreate = async (values) => {
        const model = values;
        model.status = values.status.value;
        // model.departmentId =
        //     values.departmentId === '' ||
        //     values.departmentId === 'null' ||
        //     values.departmentId === undefined
        //         ? ''
        //         : values.departmentId.value;
        model.accountId =
            values.accountId === '' ||
            values.accountId === 'null' ||
            values.accountId === undefined
                ? ''
                : values.accountId.value;
        // model.currentUserId = inforAccount.id;
        // model.imagePath = imagePath;
        // model.imageThumbnail = imageThumbnail;

        addFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastCreateError);
                } else {
                    setIsLoading(true);
                    toast.success(messageToast.messageToastCreateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    const handleFileExelUpload = (file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: 'binary'});
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet, {defval: ''});

            const dataWithMappedHeaders = parsedData.map((row) => {
                const mappedRow = {};
                Object.keys(row).forEach((key) => {
                    const mappedKey = headerMapping[key.toLowerCase()];
                    if (mappedKey) {
                        mappedRow[mappedKey] = row[key];
                    }
                });
                
                mappedRow.deviceCode = mappedRow.deviceCode.toString();
                mappedRow.recommendedNumberOfUse = mappedRow.recommendedNumberOfUse ? mappedRow.recommendedNumberOfUse : 0;
                mappedRow.currentNumberOfUse = mappedRow.currentNumberOfUse ? mappedRow.currentNumberOfUse : 0;

                // if (mappedRow.departmentCode) {
                //     mappedRow.departmentId =
                //         listDepartment &&
                //         listDepartment.data &&
                //         listDepartment.data.find(
                //             (x) => x.TenTat == mappedRow.departmentCode
                //         )
                //             ? listDepartment.data.find(
                //                   (x) => x.TenTat == mappedRow.departmentCode
                //               ).ID
                //             : listDepartment.data.find((x) =>
                //                   x.Ten.includes(mappedRow.departmentCode)
                //               )
                //             ? listDepartment.data.find((x) =>
                //                   x.Ten.includes(mappedRow.departmentCode)
                //               ).ID
                //             : '';
                // } else {
                //     mappedRow.departmentId =
                //         listDepartment &&
                //         listDepartment.data &&
                //         listDepartment.data.find((x) =>
                //             x.Ten.includes(mappedRow.departmentName)
                //         )
                //             ? listDepartment.data.find((x) =>
                //                   x.Ten.includes(mappedRow.departmentName)
                //               ).ID
                //             : '';
                // }

                // if (
                //     mappedRow.departmentId === null ||
                //     mappedRow.departmentId === ''
                // ) {
                //     if (mappedRow.departmentCode) {
                //         toast.error(
                //             messageToast.messageToastImportExcelErrorLackIdDepartment +
                //                 mappedRow.departmentName +
                //                 ' - ' +
                //                 mappedRow.departmentCode
                //         );
                //     } else {
                //         toast.error(
                //             messageToast.messageToastImportExcelErrorLackId +
                //                 mappedRow.departmentName
                //         );
                //     }
                //     return; // Early return
                // }

                if (mappedRow.deviceManagementUserName) {
                    mappedRow.accountId =
                        listAccount &&
                        listAccount.data &&
                        listAccount.data.data &&
                        listAccount.data.data.find(
                            (x) =>
                                x.username == mappedRow.deviceManagementUserName
                        )
                            ? listAccount.data.data.find(
                                  (x) =>
                                      x.username ==
                                      mappedRow.deviceManagementUserName
                              ).id
                            : '';
                }

                if (
                    mappedRow.accountId === null ||
                    mappedRow.accountId === ''
                ) {
                    toast.error(
                        messageToast.messageToastImportExcelErrorLackIdAccount +
                            mappedRow.deviceManagementUserName
                    );

                    return;
                }

                return mappedRow;
            });

            // setData(dataWithMappedHeaders);

            importDataExcelToDatabase(dataWithMappedHeaders);
        };

        reader.readAsBinaryString(file);
    };

    const importDataExcelToDatabase = (values) => {
        // cần sửa lại currentUserName khi dùng chính
        // departmentId chưa có
        const model = {
            listDevice: values
            // currentUserId: inforAccount.id
        };

        if (!model.listDevice.includes(undefined) && !model.listDevice.includes(null)) {
            addMultipleFunc.mutate(model, {
                onSuccess: (res) => {
                    if (res.status !== status200) {
                        toast.error(messageToast.messageToastCreateError);
                    } else {
                        setIsLoading(true);
                        toast.success(messageToast.messageToastCreateSuccess);
                        history.goBack();
                    }
                },
                onError: (res) => {
                    toast.error(
                        res.message || 'Đã có lỗi xảy ra, vui lòng thử lại !'
                    );
                }
            });
        }
    };

    // const loadOptions = async (inputValue, loadedOptions, {page}) => {
    //     const filteredOptions = lstDepartment
    //         .filter((option) =>
    //             option.label.toLowerCase().includes(inputValue.toLowerCase())
    //         )
    //         .slice(0, 6);

    //     // Return all filtered options, no matter how many there are
    //     return {
    //         options: filteredOptions,
    //         hasMore: false,
    //         additional: {
    //             page: page + 1
    //         }
    //     };
    // };

    const loadOptionsAccount = async (inputValue, loadedOptions, {page}) => {
        const filteredOptions = lstAccount
            .filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            )
            .slice(0, 6);

        // Return all filtered options, no matter how many there are
        return {
            options: filteredOptions,
            hasMore: false,
            additional: {
                page: page + 1
            }
        };
    };

    return (
        <Card>
            <Card.Header as="h5">Thêm thiết bị</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => handleCreate(values)}
                    initialValues={{
                        name: '',
                        deviceCode: '',
                        departmentId: '',

                        accountId: '',
                        deviceManagementName: '',
                        deviceManagementPhone: '',
                        deviceManagementEmail: '',
                        modelDevice: '',
                        seriDevice: '',
                        firmOfDevice: '',
                        deviceOrigin: '',

                        imagePath: '',
                        imageThumbnail: '',
                        description: '',
                        parameter: '',
                        status: objectDeviceStatus[0].value,
                        orderNumber: 0,
                        recommendedNumberOfUse: 0,
                        currentNumberOfUse: 0
                        // isActive: true
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tên thiết bị *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tên thiết bị"
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Mã thiết bị *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Mã thiết bị"
                                        type="text"
                                        name="deviceCode"
                                        value={values.deviceCode}
                                        onChange={handleChange}
                                        isInvalid={!!errors.deviceCode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.deviceCode}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Model thiết bị
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Model thiết bị"
                                        type="text"
                                        name="modelDevice"
                                        value={values.modelDevice}
                                        onChange={handleChange}
                                        isInvalid={!!errors.modelDevice}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.modelDevice}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Seri thiết bị
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Seri thiết bị"
                                        type="text"
                                        name="seriDevice"
                                        value={values.seriDevice}
                                        onChange={handleChange}
                                        isInvalid={!!errors.seriDevice}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.seriDevice}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Hãng
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Hãng"
                                        type="text"
                                        name="firmOfDevice"
                                        value={values.firmOfDevice}
                                        onChange={handleChange}
                                        isInvalid={!!errors.firmOfDevice}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.firmOfDevice}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Xuất xứ
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Xuất xứ"
                                        type="text"
                                        name="deviceOrigin"
                                        value={values.deviceOrigin}
                                        onChange={handleChange}
                                        isInvalid={!!errors.deviceOrigin}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.deviceOrigin}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Người quản lý
                                </Form.Label>
                                <Col sm="10">
                                    <AsyncPaginate
                                        name="accountId"
                                        id="accountId"
                                        placeholder="Chọn người quản lý"
                                        value={values.accountId}
                                        loadOptions={loadOptionsAccount}
                                        isClearable
                                        styles={
                                            errors.accountId !== undefined
                                                ? styleSelectError
                                                : styleSelectDefault
                                        }
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: 'accountId',
                                                    value: selectedOption
                                                }
                                            };
                                            handleChange(event);
                                        }}
                                        additional={{
                                            page: 1
                                        }}
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.accountId}
                                    </div>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Email người quản lý *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Email người quản lý"
                                        type="text"
                                        name="deviceManagementEmail"
                                        value={values.deviceManagementEmail}
                                        onChange={handleChange}
                                        isInvalid={
                                            !!errors.deviceManagementEmail
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.deviceManagementEmail}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Số điện thoại người quản lý
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Số điện thoại người quản lý"
                                        type="text"
                                        name="deviceManagementPhone"
                                        value={values.deviceManagementPhone}
                                        onChange={handleChange}
                                        isInvalid={
                                            !!errors.deviceManagementPhone
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.deviceManagementPhone}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Mô tả
                                </Form.Label>
                                <Col sm="10">
                                    <Editor
                                        name="description"
                                        value={values.description}
                                        onChange={(value) => {
                                            setFieldValue('description', value);
                                        }}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Thông số
                                </Form.Label>
                                <Col sm="10">
                                    <Editor
                                        name="parameter"
                                        value={values.parameter}
                                        onChange={(value) => {
                                            setFieldValue('parameter', value);
                                        }}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Ảnh đại diện
                                </Form.Label>
                                <Col sm="10">
                                    <InputFile
                                        name="imageThumbnail"
                                        accept={acceptImage}
                                        onChange={(files) => {
                                            if (files.length > 0) {
                                                setFieldValue(
                                                    'imagePath',
                                                    files[0].filePath,
                                                    true
                                                );
                                                setFieldValue(
                                                    'imageThumbnail',
                                                    files[0].thumbPath,
                                                    true
                                                );
                                            }
                                        }}
                                    />

                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.imageThumbnail}
                                    </div>

                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.parentId}
                                    </Form.Control.Feedback> */}
                                </Col>
                            </Form.Group>

                            {/* <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Khoa
                                </Form.Label>
                                <Col sm="10">
                                    <AsyncPaginate
                                        name="departmentId"
                                        id="departmentId"
                                        placeholder="Chọn Khoa"
                                        value={values.departmentId}
                                        loadOptions={loadOptions}
                                        isClearable
                                        styles={
                                            errors.departmentId !== undefined
                                                ? styleSelectError
                                                : styleSelectDefault
                                        }
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: 'departmentId',
                                                    value: selectedOption
                                                }
                                            };
                                            handleChange(event);
                                        }}
                                        additional={{
                                            page: 1
                                        }}
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.departmentId}
                                    </div>
                                </Col>
                            </Form.Group> */}

                            {/* Có thể dùng cái này để check thiết bị khoa nào, tự động xuất hiện sau khi chọn khoa */}
                            {/* {values.departmentId.value == '140' ? (
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Test
                                    </Form.Label>
                                    <Col sm="10">Test</Col>
                                </Form.Group>
                            ) : (
                                <></>
                            )} */}

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Trạng thái *
                                </Form.Label>
                                <Col sm="10">
                                    <Select
                                        name="status"
                                        id="status"
                                        placeholder="Trạng thái"
                                        value={values.status}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: 'status',
                                                    value: selectedOption
                                                }
                                            };
                                            handleChange(event);
                                        }}
                                        options={objectDeviceStatus}
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.status}
                                    </div>
                                </Col>
                            </Form.Group>
                            {/* <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Trạng thái kích hoạt
                                </Form.Label>
                                <Col sm="10" className="icheck-primary">
                                    <Form.Control
                                        id="checkboxPrimary2"
                                        type="checkbox"
                                        placeholder="Trạng thái kích hoạt"
                                        name="isActive"
                                        checked={values.isActive}
                                        onClick={handleChange}
                                    />
                                    <Form.Label htmlFor="checkboxPrimary2">
                                        {' '}
                                    </Form.Label>
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Số lần sử dụng tối đa
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="recommendedNumberOfUse"
                                        placeholder="Số lần sử dụng tối đa"
                                        value={values.recommendedNumberOfUse}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Số lần hiện tại sử dụng
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="currentNumberOfUse"
                                        placeholder="Số lần hiện tại sử dụng"
                                        value={values.currentNumberOfUse}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Số lần còn lại
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="remainNumberOfUse"
                                        placeholder="Số lần còn lại"
                                        value={values.recommendedNumberOfUse - values.currentNumberOfUse}
                                        disabled={true}
                                        // onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Thêm trang thiết bị bằng Excel
                                </Form.Label>
                                <Col sm="10">
                                    <FileUploader
                                        handleChange={handleChangeFileExcel}
                                        name="file"
                                        types={fileTypes}
                                        hoverTitle="Kéo vào đây"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col md={{span: 10, offset: 2}}>
                                    <ButtonGroup className="mb-2">
                                        <Button
                                            type="submit"
                                            theme="primary"
                                            disabled={isLoading}
                                        >
                                            Thêm
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            onClick={() => history.goBack()}
                                            theme="secondary"
                                        >
                                            Hủy
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
                <ReactSpinner loadings={isLoading} />
            </Card.Body>
        </Card>
    );
};

export default DeviceAdd;
