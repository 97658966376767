import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Table from 'react-bootstrap/Table';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {getStatisticPostSynthticAction} from '../../store/reducers/reportPost';
import {objectDataTypeCategory, rangeDate} from '../../utils/define';
import {dateTimeToStringRequest,exportExcel} from '../../utils/helper';

const ReportPostSynthetic = () => {
    const dispatch = useDispatch();
    const listStatisticPostSynthtic = useSelector(
        (state) => state.reportPost.listStatisticPostSynthtic
    );

    const [valueDateType, setValueDateType] = useState('');

    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });

    useEffect(() => {
        const modelPost = {
            dataTypeCategory: valueDateType,
            fromDate: dates.startDate
                ? dateTimeToStringRequest(dates.startDate.toDate())
                : '',
            toDate: dates.endDate
                ? dateTimeToStringRequest(dates.endDate.toDate())
                : ''
        };
        dispatch(getStatisticPostSynthticAction(modelPost));
    }, [valueDateType, dates]);

    const onChangeRepostType = (e) => {
        setValueDateType(e.target.value);
    };

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h1 className="card-title">
                                {' '}
                                Báo cáo bài viết tổng hợp
                            </h1>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label htmlFor="exampleEmail2" className>
                                        Loại bài viết :
                                    </label>
                                    <select
                                        className="form-control"
                                        value={valueDateType}
                                        onChange={(e) => onChangeRepostType(e)}
                                    >
                                        <option value="">Tất cả</option>
                                        {objectDataTypeCategory.map(
                                            (item, key) => (
                                                <option
                                                    key={Number(key)}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </option>
                                            )
                                        )}
                                    </select>
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3 mr-2"
                                    >
                                        Thời gian đăng tin :
                                    </label>
                                    <DateRangePicker
                                        onApply={handleApply}
                                        onCancel={handleCancel}
                                        initialSettings={{
                                            autoUpdateInput: false,
                                            locale: {
                                                cancelLabel: 'Clear'
                                            },
                                            ranges: rangeDate
                                        }}
                                    >
                                        <input
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length === 0
                                                ) {
                                                    setDates({
                                                        startDate: null,
                                                        endDate: null
                                                    });
                                                }
                                            }}
                                            type="text"
                                            className="form-control"
                                        />
                                    </DateRangePicker>
                                </form>
                                   <NavLink
                                    className="nav-link-reject nav-link"
                                    to
                                    onClick={() => exportExcel(`baocao_baiviettonghop_${Date.now()}`)}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        Xuất file Excel
                                    </button>
                                </NavLink>
                            </div>
                            <div
                                id="example2_wrapper"
                                className="dataTables_wrapper dt-bootstrap4"
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Table
                                            id="table-to-xls"
                                            striped
                                            bordered
                                            hover
                                        >
                                            <thead>
                                                {listStatisticPostSynthtic.length >
                                                0 ? (
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            Trang
                                                        </th>
                                                        {listStatisticPostSynthtic.map(
                                                            (item, index) => (
                                                                <th
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="sorting"
                                                                >
                                                                    {item.title}
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                ) : null}
                                            </thead>
                                            {listStatisticPostSynthtic.length >
                                            0 ? (
                                                <tbody>
                                                    <tr role="row">
                                                        <td className="sorting">
                                                            Trang web
                                                        </td>
                                                        {listStatisticPostSynthtic.map(
                                                            (item, index) => (
                                                                <td
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="sorting"
                                                                >
                                                                    {item.total}
                                                                </td>
                                                            )
                                                        )}
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr className="txt-center">
                                                        <td colSpan="12">
                                                            Không có dữ liệu
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ReportPostSynthetic;
