import {consoleLog, getToken} from './helper';

function FormGet(model) {
    const formGet = [];
    Object.keys(model).forEach(function (property) {
        if (Array.isArray(model[property])) {
            model[property].forEach((element) => {
                const encodedKey = encodeURIComponent(property);
                const encodedValue = encodeURIComponent(element);
                formGet.push(`${encodedKey}=${encodedValue}`);
            });
        } else {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(model[property]);
            formGet.push(`${encodedKey}=${encodedValue}`);
        }
    });
    return formGet.join('&');
}

function Header(ContentType = '') {
    const token = getToken();
    const headers = new Headers();
    if (token) {
        headers.append('Authorization', `bearer ${token}`);
    }
    if (ContentType.length > 0) {
        headers.append('Content-Type', ContentType);
    }
    return headers;
}

export async function Post(api, param = null, model, isLogin = false) {
    let API = api;
    if (param && param.toString().length > 0) {
        API = `${API}/${param}`;
    }
    return fetch(API, {
        method: 'POST',
        headers: Header('application/json'),
        body: JSON.stringify(model)
    }).then((res) => {
        // if (convertToJson) {
        //     if (res.status === 200 || res.status === 400 || res.status === 201) {
        //         if (res.json() != null) return res.json();
        //     }
        // }
        // return res;
        // if (!res.ok) {
        //     console.log(res.json()); // Check if the response status is not ok (200-299)
        //     return res.json().then(err => Promise.reject(err)).catch((e) => Promise.reject({ message: e.message }));
        // }
        // return convertToJson ? res.json() : res;

        if (isLogin) {
            return res;
        } else {
            return res.json().then((body) => {
                // If the response was not OK, throw the body as the error
                if (!res.ok) {
                    throw body;
                }
                // Otherwise, return the body for successful responses
                return res;
            });
        }
    });
    // .catch((error) => {
    //     consoleLog(error);
    //     return null;
    // });
}

export function Get(api, param = null, model = null) {
    let API = api;
    if (param) {
        API = `${API}/${param}`;
    }
    if (model) {
        API = `${API}?${FormGet(model)}`;
    }

    return fetch(API, {
        method: 'GET',
        headers: Header('application/json')
    })
        .then((res) => {
            if (res.ok) return res.json();
            return null;
        })
        .catch((error) => {
            consoleLog(error);
            return null;
        });
}

export function DeleteSingle(api, param, model = null) {
    let API = api;
    if (param) {
        API = `${API}/${param}`;
    }
    if (model) {
        API = `${API}?${FormGet(model)}`;
    }
    return fetch(API, {
        method: 'DELETE',
        headers: Header('application/json'),
        body: model ? JSON.stringify(model) : ''
    })
        .then((res) => res)
        .catch((error) => {
            consoleLog(error);
            return error;
        });
}

export function Put(api, param = null, model = null) {
    let API = api;
    if (param) {
        API = `${API}/${param}`;
    }
    return fetch(API, {
        method: 'PUT',
        headers: Header('application/json'),
        body: model ? JSON.stringify(model) : ''
    }).then((res) => {
        return res.json().then((body) => {
            if (!res.ok) {
                throw body;
            }
            return res;
        });
    });
}
export async function UploadFile(api, param, data) {
    let API = api;
    if (param) {
        API = `${API}?${FormGet(param)}`;
    }
    const requestOptions = {
        method: 'POST',
        body: data,
        headers: Header('')
    };
    return fetch(API, requestOptions)
        .then(async (res) => res)
        .catch((error) => {
            return error;
        });
}

export function GetLogin(api, param = null, model = null) {
    let API = api;
    if (param) {
        API = `${API}/${param}`;
    }
    if (model) {
        API = `${API}?${FormGet(model)}`;
    }

    return fetch(API, {
        method: 'GET',
        headers: {
            'Content-Type': "application/json; charset=utf-8",
        },
        // mode: 'no-cors'
    })
        .then((res) => {
            if (res.ok) 
            {
                return true;
            };
            return false;
        })
        .catch((error) => {
            consoleLog(error);
            return null;
        });
}

export async function DeleteLogin(api, param = null, model) {
    let API = api;
    if (param) {
        API = `${API}/${param}`;
    }
    if (model) {
        API = `${API}?${FormGet(model)}`;
    }

    return fetch(API, {
        method: 'GET',
        headers: Header('application/json')
    })
        .then((res) => {
            if (res.ok) return null;
            return null;
        })
        .catch((error) => {
            consoleLog(error);
            return null;
        });
}




