import {FILE_MANAGER_API, BaseGatewayImage} from '../../utils/apiUrl';
import {getToken} from '../../utils/helper';

export class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    async upload() {
        return this.loader.file.then(
            (file) =>
                new Promise((resolve, reject) => {
                    this.initRequest();
                    this.initListeners(resolve, reject, file);
                    this.sendRequest(file);
                })
        );
    }

    // abort() {
    //     return Promise.reject();
    // }

    initRequest() {
        const xhr = new XMLHttpRequest();
        this.xhr = xhr;

        // Note that your request may look different. It is up to you and your editor
        // integration to choose the right communication channel. This example uses
        // a POST request with JSON as a data structure but your configuration
        // could be different.
        xhr.open('POST', FILE_MANAGER_API.UPLOAD, true);
        xhr.responseType = 'json';
        const token = getToken();
        xhr.setRequestHeader('Authorization', `bearer ${token}`);
    }

    // Initializes XMLHttpRequest listeners.
    initListeners(resolve, reject, file) {
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        this.xhr.addEventListener('error', () => reject(genericErrorText));
        this.xhr.addEventListener('abort', () => reject());
        this.xhr.addEventListener('load', () => {
            const {response} = this.xhr;

            // This example assumes the XHR server's "response" object will come with
            // an "error" which has its own "message" that can be passed to reject()
            // in the upload promise.
            //
            // Your integration may handle upload errors in a different way so make sure
            // it is done properly. The reject() function must be called when the upload fails.
            if (!response || response.error) {
                return reject(
                    response && response.error
                        ? response.error.message
                        : genericErrorText
                );
            }

            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            // This URL will be used to display the image in the content. Learn more in the
            // UploadAdapter#upload documentation.
            return resolve({
                default: BaseGatewayImage + response[0].filePath
            });
        });

        // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
        // properties which are used e.g. to display the upload progress bar in the editor
        // user interface.
        if (this.xhr.upload) {
            this.xhr.upload.addEventListener('progress', (evt) => {
                if (evt.lengthComputable) {
                    this.loader.uploadTotal = evt.total;
                    this.loader.uploaded = evt.loaded;
                }
            });
        }
    }

    // Prepares the data and sends the request.
    sendRequest(file) {
        // Prepare the form data.
        const data = new FormData();

        data.append('upload', file);

        // Important note: This is the right place to implement security mechanisms
        // like authentication and CSRF protection. For instance, you can use
        // XMLHttpRequest.setRequestHeader() to set the request headers containing
        // the CSRF token generated earlier by your application.

        // Send the request.
        this.xhr.send(data);
    }
}

export function uploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
        new UploadAdapter(loader);
}
