import React, {useState, useEffect} from 'react';
import Pagination from 'react-js-pagination';
import {useSelector} from 'react-redux';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import {toast} from 'react-toastify';
import {useMutation, useQuery} from 'react-query';
import {Image} from 'react-bootstrap';
import {AsyncPaginate} from 'react-select-async-paginate';
import {
    BOOKDEVICE_API,
    BaseGatewayImage,
    DEPARTMENT_API
} from '@app/utils/apiUrl';
import {DeleteSingle, Get} from '@app/utils/httpProvider';
import Select from 'react-select';
import {
    createUrlWithParam,
    getInfomationAccountFromToken,
    isValidUrl
} from '../../utils/helper';
import {URI_INTERNAL} from '../../utils/pathLocations';
import {
    objectShowNumber,
    messageToast,
    objectDeviceStatus,
    deviceStatus,
    styleSelectDefault,
    role,
    objectBookDeviceStatus
} from '../../utils/define';
import {ConfirmModal} from '../../modules/modal/ConfirmModal';
import {BrowserView, MobileView} from 'react-device-detect';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import DeviceMaintenance from '@app/pages/deviceMaintenance/DeviceMaintenance';

const BookDeviceForApprover = () => {
    const history = useHistory();
    const location = useLocation();
    const valueSearch = useSelector((state) => state.search.valueSearch);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');

    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [idWantDelete, setIdWantDelete] = useState('');
    const [confirmDelMessage, setConfirmDelMessage] = useState('');
    const [departmentId, setDepartmentId] = useState('');

    // let accountRoleAdmin = false;
    const inforAccount = getInfomationAccountFromToken();

    // if (inforAccount && inforAccount.role) {
    //     const userRole = inforAccount.role.split(', ');
    //     accountRoleAdmin =
    //         userRole.includes(role.Admin) || userRole.includes(role.Manager);
    // }

    const deleteFunc = useMutation((value) =>
        DeleteSingle(BOOKDEVICE_API.DELETE_ITEM, value, null)
    );

    const paramUrl = new URLSearchParams(location.search.substring(1));
    if (
        paramUrl.get('page') != null &&
        parseInt(paramUrl.get('page'), 10) !== page
    ) {
        const pageResult = parseInt(paramUrl.get('page'), 10);
        setPage(pageResult);
    }
    if (
        paramUrl.get('size') != null &&
        parseInt(paramUrl.get('size'), 10) !== pageSize
    ) {
        const sizeResult = parseInt(paramUrl.get('size'), 10);
        setPageSize(sizeResult);
    }
    if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
        setSearch(paramUrl.get('search'));
    }

    const [isSortedNameAlphabet, setIsSortedNameAlphabet] = useState(false);
    const [bookStatus, setBookStatus] = useState('');

    const {isLoading, isError, error, data, refetch} = useQuery(
        ['deviceData', valueSearch, page, pageSize, departmentId],
        () => {
            return Get(BOOKDEVICE_API.GET_PAGING, null, {
                Keyword: valueSearch ? valueSearch : '',
                PageIndex: page,
                PageSize: pageSize,
                BookStatus: bookStatus,
                AccountApproverId: inforAccount.id
            });
        }
    );

    useEffect(() => {
        refetch();
    }, [pageSize, page, bookStatus]);

    const replaceHistory = async (Page, Size, Search) => {
        history.replace(
            createUrlWithParam(URI_INTERNAL.BOOKDEVICEFORAPPROVER_PATH, {
                page: Page,
                size: Size,
                search: Search
            })
        );
    };

    React.useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            replaceHistory(1, pageSize, valueSearch);
        }
    }, [valueSearch]);

    const changePage = (number) => {
        setPage(number);
        replaceHistory(number, pageSize, search);
    };

    const onChangeShowNumber = (e) => {
        setPageSize(e.target.value);
        replaceHistory(page, e.target.value, search);
    };

    const deleteAction = (id, name) => {
        setIdWantDelete(id);
        setConfirmDelMessage(name);
        setOpenConfirmDeleteModal(true);
    };

    const acceptDelete = async () => {
        if (idWantDelete !== '') {
            deleteFunc.mutate(idWantDelete, {
                onSuccess: (res) => {
                    if (res.status === 201 || res.status === 200) {
                        refetch();
                        toast.success(messageToast.messageToastDeleteSuccess);
                        if (
                            data != null &&
                            data.data.length === 1 &&
                            page !== 1
                        ) {
                            history.push(
                                createUrlWithParam(
                                    URI_INTERNAL.BOOKDEVICEFORAPPROVER_PATH,
                                    {
                                        page: page - 1,
                                        size: pageSize,
                                        search: search
                                    }
                                )
                            );
                        }
                    } else {
                        toast.error(messageToast.messageToastDeleteError);
                    }
                },
                onError: (res) => {
                    toast.error(messageToast.messageToastTryCatch);
                }
            });

            replaceHistory(page, pageSize, search);
            setOpenConfirmDeleteModal(false);
        }
    };

    const cancelDelete = () => {
        setIdWantDelete('');
        setOpenConfirmDeleteModal(false);
    };

    const listDepartment = useQuery(['getAllDepartment'], () =>
        Get(DEPARTMENT_API.GET_ALL, null, null)
    );

    // Khoa
    const lstDepartment =
        listDepartment && listDepartment.data
            ? listDepartment.data.map((x) => ({
                  label: x.Ten,
                  value: x.ID
              }))
            : [];

    const loadOptions = async (inputValue, loadedOptions, {page}) => {
        const filteredOptions = lstDepartment
            .filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            )
            .slice(0, 6);

        // Return all filtered options, no matter how many there are
        return {
            options: filteredOptions,
            hasMore: false,
            additional: {
                page: page + 1
            }
        };
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {' '}
                                Danh sách lịch phê duyệt
                            </h3>
                        </div>
                        <Tabs>
                            <TabList>
                                <Tab>Lịch của người phê duyệt</Tab>
                                <Tab>Sổ bảo dưỡng và sự cố</Tab>
                            </TabList>
                            <TabPanel>
                                <div className="card-body">
                                    <BrowserView>
                                        <div className="d-flex justify-content-between">
                                            <form className="form-inline">
                                                <label
                                                    htmlFor="exampleEmail2"
                                                    className="mr-2"
                                                >
                                                    Hiển thị :
                                                </label>
                                                <select
                                                    className="form-control"
                                                    value={pageSize}
                                                    onChange={(e) =>
                                                        onChangeShowNumber(e)
                                                    }
                                                >
                                                    {objectShowNumber.map(
                                                        (item, key) => (
                                                            <option
                                                                key={Number(
                                                                    key
                                                                )}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.text}
                                                            </option>
                                                        )
                                                    )}
                                                </select>

                                                <span
                                                    style={{
                                                        marginLeft: '40px'
                                                    }}
                                                >
                                                    <Select
                                                        name="tus"
                                                        id="bookStatus"
                                                        placeholder="Trạng thái đặt thiết bị"
                                                        value={objectBookDeviceStatus.find(
                                                            (x) =>
                                                                x.value ==
                                                                bookStatus
                                                        )}
                                                        onChange={(
                                                            selectedOption
                                                        ) => {
                                                            setBookStatus(
                                                                selectedOption.value
                                                            );
                                                        }}
                                                        options={
                                                            objectBookDeviceStatus
                                                        }
                                                    />
                                                </span>

                                                {/* <span style={{marginLeft: '20px'}}>
                                            <AsyncPaginate
                                                name="departmentId"
                                                id="departmentId"
                                                placeholder="Chọn Khoa"
                                                value={departmentId.value}
                                                loadOptions={loadOptions}
                                                styles={styleSelectDefault}
                                                isClearable
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: 'departmentId',
                                                            value: selectedOption
                                                        }
                                                    };
                                                    setDepartmentId(event);
                                                }}
                                                additional={{
                                                    page: 1
                                                }}
                                            />
                                        </span> */}
                                            </form>
                                        </div>
                                    </BrowserView>
                                    <MobileView>
                                        <div className="d-flex justify-content-between">
                                            <form className="form-inline">
                                                <label
                                                    htmlFor="exampleEmail2"
                                                    className="mr-2"
                                                >
                                                    Hiển thị :
                                                </label>
                                                <select
                                                    className="form-control"
                                                    value={pageSize}
                                                    onChange={(e) =>
                                                        onChangeShowNumber(e)
                                                    }
                                                >
                                                    {objectShowNumber.map(
                                                        (item, key) => (
                                                            <option
                                                                key={Number(
                                                                    key
                                                                )}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.text}
                                                            </option>
                                                        )
                                                    )}
                                                </select>

                                                <div
                                                    style={{
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >
                                                    <AsyncPaginate
                                                        name="departmentId"
                                                        id="departmentId"
                                                        placeholder="Chọn Khoa"
                                                        value={
                                                            departmentId.value
                                                        }
                                                        loadOptions={
                                                            loadOptions
                                                        }
                                                        styles={
                                                            styleSelectDefault
                                                        }
                                                        isClearable
                                                        onChange={(
                                                            selectedOption
                                                        ) => {
                                                            const event = {
                                                                target: {
                                                                    name: 'departmentId',
                                                                    value: selectedOption
                                                                }
                                                            };
                                                            setDepartmentId(
                                                                event
                                                            );
                                                        }}
                                                        additional={{
                                                            page: 1
                                                        }}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </MobileView>
                                    {isLoading ? (
                                        <div>Đang tải...</div>
                                    ) : isError ? (
                                        <div>
                                            Có lỗi xảy ra: ${error.message}
                                        </div>
                                    ) : (
                                        <div
                                            id="example2_wrapper"
                                            className="dataTables_wrapper dt-bootstrap4"
                                            style={{
                                                marginTop: '20px'
                                            }}
                                        >
                                            <div className="row">
                                                <div
                                                    className="col-sm-12"
                                                    style={{overflow: 'auto'}}
                                                >
                                                    <Table
                                                        striped
                                                        bordered
                                                        hover
                                                    >
                                                        <thead>
                                                            <tr role="row">
                                                                <th className="sorting">
                                                                    STT
                                                                </th>
                                                                <th className="sorting">
                                                                    Thiết bị
                                                                </th>
                                                                <th className="sorting">
                                                                    Người đặt
                                                                    lịch
                                                                </th>
                                                                <th className="sorting">
                                                                    Trạng thái
                                                                </th>
                                                                <th className="sorting">
                                                                    Chức năng
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        {data != null &&
                                                        data.data != null &&
                                                        data.data != null &&
                                                        data.data !==
                                                            undefined &&
                                                        data.data.length > 0 ? (
                                                            <tbody>
                                                                {data.data.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <tr
                                                                            key={Number(
                                                                                index
                                                                            )}
                                                                            className="odd"
                                                                        >
                                                                            <td className="dtr-control sorting_1">
                                                                                {index +
                                                                                    1 +
                                                                                    (page -
                                                                                        1) *
                                                                                        pageSize}
                                                                            </td>
                                                                            <td>
                                                                                {item.device
                                                                                    ? item
                                                                                          .device
                                                                                          .name +
                                                                                      ' - ' +
                                                                                      item
                                                                                          .device
                                                                                          .deviceCode
                                                                                    : ''}
                                                                            </td>
                                                                            <td>
                                                                                {item.account
                                                                                    ? item
                                                                                          .account
                                                                                          .fullName
                                                                                    : ''}
                                                                            </td>
                                                                            <td>
                                                                                {objectBookDeviceStatus.find(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.value ==
                                                                                        item.bookStatus
                                                                                )
                                                                                    ? objectBookDeviceStatus.find(
                                                                                          (
                                                                                              x
                                                                                          ) =>
                                                                                              x.value ==
                                                                                              item.bookStatus
                                                                                      )
                                                                                          .label
                                                                                    : ''}
                                                                            </td>
                                                                            <td>
                                                                                <ul className="nav">
                                                                                    <li className="nav-item">
                                                                                        <NavLink
                                                                                            to={createUrlWithParam(
                                                                                                URI_INTERNAL.BOOKDEVICE_EDIT_PATH,
                                                                                                {
                                                                                                    id: item.id
                                                                                                }
                                                                                            )}
                                                                                            exact
                                                                                            className="nav-link"
                                                                                        >
                                                                                            <i
                                                                                                title="Sửa"
                                                                                                className="nav-icon font-icon fas fa-pen"
                                                                                            />
                                                                                        </NavLink>
                                                                                    </li>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                <tr className="txt-center">
                                                                    <td colSpan="12">
                                                                        Không có
                                                                        dữ liệu
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                        <tfoot>
                                                            <tr role="row">
                                                                <th className="sorting">
                                                                    STT
                                                                </th>
                                                                <th className="sorting">
                                                                    Thiết bị
                                                                </th>
                                                                <th className="sorting">
                                                                    Người đặt
                                                                    lịch
                                                                </th>
                                                                <th className="sorting">
                                                                    Trạng thái
                                                                </th>
                                                                <th className="sorting">
                                                                    Chức năng
                                                                </th>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div
                                                        className="dataTables_paginate paging_simple_numbers"
                                                        id="example2_paginate"
                                                    >
                                                        <Pagination
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                            innerClass="pagination pagination-sm no-margin pull-right"
                                                            activePage={page}
                                                            itemsCountPerPage={Number(
                                                                pageSize
                                                            )}
                                                            totalItemsCount={
                                                                data != null
                                                                    ? data.totalRecords
                                                                    : 0
                                                            }
                                                            pageRangeDisplayed={
                                                                5
                                                            }
                                                            onChange={(
                                                                number
                                                            ) =>
                                                                changePage(
                                                                    number
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <ConfirmModal
                                        isOpen={openConfirmDeleteModal}
                                        confirmType="delete"
                                        handleAccept={() => acceptDelete()}
                                        handleCancel={() => cancelDelete()}
                                        content={confirmDelMessage}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <DeviceMaintenance
                                    // plantItemRefetch={() => plantItem.refetch()}
                                    // listGrowthProcedureRefetch={() =>
                                    //     listGrowthProcedure.refetch()
                                    // }
                                    // lstPlantGrowProcedureId={
                                    //     plantItem.data ? plantItem.data.lstGrowthId : []
                                    // }
                                    accountManageDeviceId={
                                        inforAccount.id
                                    }
                                />
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookDeviceForApprover;
