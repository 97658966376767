import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    valueSearch: null
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        changeValueSearch: (state, action) => {
            state.valueSearch = action.payload;
        }
    }
});

export const {changeValueSearch} = searchSlice.actions;

export default searchSlice.reducer;
